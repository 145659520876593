import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faEnvelope, faIdCard } from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import Swal from 'sweetalert2';
import GroupSection from './GroupSection';
import $ from 'jquery';

const GroupsListSection = ({props, schoolId, teachers}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [grupos, setGrupos] = useState([]);

    React.useEffect(() => {
        getGroups();
    },[schoolId]);

    const getGroups = () => {
        setIsLoading(true);
        auth.getGroupsBySchoolId(schoolId).then(result => {
            setGrupos(result);
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const onDeleteGroup = (grupoId) => {
      const clone = [...grupos.filter(grupo => grupo.id !== grupoId)];
      setGrupos(null);
      setGrupos(clone);
    }

    return (<div className="container mb-5">

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto mb-4">
                    <div className="section-title text-center ">
                      <h3 className="top-c-sep">Lista de Grupos</h3>
                      <p>Aquí encontrarás la lista de grupos de la escuela.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="row">
                      <div className="col-3">

                        <div className="nav flex-column nav-pills" id="groups-pills-tab" role="tablist" aria-orientation="vertical">
                          {grupos && grupos.map((grupo, i) => { return (
                            <a key={i} className="nav-link" id={`groups-pills-${grupo.id}-tab`} data-toggle="pill" href={`#groups-pills-${grupo.id}`} role="tab" aria-controls={`groups-pills-${grupo.id}`} aria-selected="false">{grupo.grado}° {grupo.label}</a>
                          )})}

                       </div>
                      </div>
                      <div className="col-9">
                        <div className="tab-content" id="groups-pills-tabContent">

                          {grupos && grupos.map((grupo, i) => { return (
                            <div key={i} className="tab-pane fade" id={`groups-pills-${grupo.id}`} role="tabpanel" aria-labelledby={`groups-pills-${grupo.id}-tab`}>
                                <GroupSection schoolId={schoolId} grupo={grupo} teachers={teachers} onDeleteGroup={onDeleteGroup}/>
                            </div>
                          )})}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

        </div> }
    </div>)
}

export default GroupsListSection;
