import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gtag } from 'ga-gtag';
import Swal from 'sweetalert2';
import auth from '../../../services/users/AuthService'
import Loading from '../../../components/Loading';
import { clickMenuOpen } from '../../../services/menu/ActionTypes';
import { resetSurvey } from '../../../services/menu/ActionTypes';
import { userLogOut } from '../../../services/users/ActionTypes';
import { userSchoolYear } from '../../../services/users/ActionTypes';
import profilePic from'../../../assets/unesco/img/icons/profile-pic.png';
import mainLogo from'../../../assets/unesco/img/logos/unesco-logo.png';
import naturaLogo from'../../../assets/unesco/img/logos/natura-logo.png';
import serviceDeskIcon from'../../../assets/unesco/img/icons/service_desk.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons';
import SupportSelectorModal from '../../../components/modals/SupportSelectorModal';


const Topbar = (props) => {

  let dispatch = useDispatch();
  let userState = useSelector(state => state.userState);

  const [isLoading, setIsLoading] = useState(false);

  const onSchoolYearSelectedChanged = async(event) => {
    event.preventDefault();
    setIsLoading(true);
    const id = Number(event.target.value);
    const selected = userState.profile.schoolYears.find(schoolYear => schoolYear.id === id);
    await setTimeout(() => {
        dispatch(userSchoolYear(selected));
        setIsLoading(false);
    }, 100);
  }

  const handleClickMenuOpen = (e) => {
    dispatch(clickMenuOpen());
  }

  const handleSignOut = (e) => {
    e.preventDefault();
    Swal.fire({
      title: '¿Seguro que desea cerrar sesión?',
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        dispatch(userLogOut());
        dispatch(resetSurvey());
        auth.logout();
      }
    });
    
  }

  const handleChangeProfile = (e) => {
    // TODO: async
    e.preventDefault();
    gtag('event', 'change-profile', {
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });
    auth.clearProfile();
  }

  return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

      <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

      <SupportSelectorModal />

      <div id="logo">
        <img  src={mainLogo} className="w-100" alt="logo"/>
      </div>

      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <a onClick={handleClickMenuOpen} className="nav-link pb-4 mb-n4 px-0 pt-0" id="navbarVerticalMenu">
        <div className="btn-close rounded py-3 px-3 mx-5 d-flex align-items-center">
          <div className="me-3 ms-1 d-flex text-white">
            {/* Icon */}
            <svg width={25} height={17} viewBox="0 0 25 17" xmlns="http://www.w3.org/2000/svg">
              <rect width={25} height={1} fill="currentColor" />
              <rect y={8} width={15} height={1} fill="currentColor" />
              <rect y={16} width={20} height={1} fill="currentColor" />
            </svg>
          </div>
        </div>
      </a>

      {/* <!-- Topbar Search --> */}
      <form className="w-80 d-none d-lg-block">
        <div className="input-group rounded">
          <div className="input-group-prepend">
          </div>
        </div>
      </form>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown dropdown-full-width">
          <div id="wa-btn-wrapper" >
          <a className="nav-link" target="_tab" rel="noopener" data-toggle="modal" data-target="#modalSupportSelector">
            <div
              id="wa_btn-content"
              style={{
                background: "#16BE45",
                padding: "10px 14px",
                borderRadius: 40,
                boxSizing: "border-box",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                cursor: "pointer"
              }}
            >
              <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path
                  d="M18.1225 14.9458C17.8183 14.7895 16.3033 14.0473 16.0215 13.9469C15.7397 13.8409 15.5332 13.7907 15.3295 14.1032C15.123 14.4129 14.5371 15.102 14.3529 15.3113C14.1744 15.5178 13.993 15.5429 13.6889 15.3894C11.8808 14.4854 10.695 13.7767 9.50361 11.7315C9.18832 11.1874 9.8189 11.2265 10.4076 10.0518C10.5081 9.84534 10.4578 9.66956 10.3797 9.51331C10.3016 9.35706 9.68776 7.84478 9.43106 7.22815C9.18274 6.62826 8.92604 6.71197 8.7391 6.70081C8.56053 6.68965 8.35684 6.68965 8.15037 6.68965C7.9439 6.68965 7.61187 6.76777 7.33006 7.0719C7.04825 7.38161 6.25305 8.12659 6.25305 9.63887C6.25305 11.1511 7.35517 12.616 7.50584 12.8225C7.66209 13.0289 9.67381 16.1316 12.7625 17.4681C14.7157 18.3107 15.4802 18.3833 16.4567 18.2382C17.051 18.1489 18.2759 17.496 18.5298 16.7734C18.7837 16.0535 18.7837 15.4369 18.7084 15.3085C18.6331 15.1718 18.4266 15.0937 18.1225 14.9458Z"
                  fill="white"
                />
                <path
                  d="M24.0292 7.65625C23.3986 6.15792 22.4946 4.81306 21.3422 3.65792C20.198 2.50948 18.8395 1.5966 17.3439 0.970982C15.8093 0.326451 14.1798 0 12.5002 0H12.4444C10.7535 0.00837054 9.11567 0.343192 7.57549 1.00167C6.09267 1.63371 4.74699 2.54821 3.61344 3.6942C2.47226 4.84654 1.57661 6.18583 0.95719 7.67857C0.315449 9.22433 -0.00821224 10.8677 0.000158294 12.5586C0.00962607 14.4963 0.468048 16.4054 1.33944 18.1362V22.3772C1.33944 22.7176 1.47467 23.0441 1.71537 23.2848C1.95607 23.5255 2.28253 23.6607 2.62293 23.6607H6.86679C8.59752 24.5321 10.5067 24.9905 12.4444 25H12.5029C14.1743 25 15.7954 24.6763 17.3216 24.043C18.8097 23.4248 20.163 22.5226 21.306 21.3867C22.4583 20.2455 23.3651 18.9118 23.9985 17.4247C24.657 15.8845 24.9918 14.2467 25.0002 12.5558C25.0085 10.8566 24.6793 9.20759 24.0292 7.65625ZM19.8132 19.8772C17.8573 21.8136 15.2624 22.8795 12.5002 22.8795H12.4527C10.7702 22.8711 9.09893 22.4526 7.62293 21.6657L7.38855 21.5402H3.45998V17.6116L3.33442 17.3772C2.54759 15.9012 2.12906 14.2299 2.12069 12.5474C2.10953 9.76562 3.17259 7.15402 5.12293 5.18694C7.07047 3.21987 9.67371 2.1317 12.4555 2.12054H12.5029C13.898 2.12054 15.2513 2.39118 16.5264 2.9269C17.7708 3.44866 18.8869 4.19922 19.8467 5.15904C20.8037 6.11607 21.5571 7.23493 22.0788 8.47935C22.6201 9.76841 22.8908 11.1356 22.8852 12.5474C22.8685 15.3265 21.7775 17.9297 19.8132 19.8772Z"
                  fill="white"
                />
              </svg>
              <p
                className="d-none d-sm-block"
                style={{
                  color: "white",
                  marginLeft: "8px!important",
                  fontStyle: "normal",
                  fontSize: 14,
                  lineHeight: 0,
                  display: "inline-block",
                  marginTop: 14,
                  marginLeft: 7,
                  minWidth: '132px'
                }}
              >
                ¿Necesitas ayuda?
              </p>
            </div>
            </a>
          </div>
        </li>

        <li className="nav-item dropdown no-arrow mx-1">
          <a className="nav-link dropdown-toggle" href="#">
            <img src={naturaLogo} className="navbar-brand-img" alt="logo"/>
          </a>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>

        {/* Nav Item - User Information */}
        <li className="nav-item dropdown no-arrow mx-1">
          <span className="nav-link dropdown-toggle" style={{paddingRight: 0}}>
            <span 
              className="mr-2 mt-2 d-none d-lg-inline text-gray-600 small"
              style={{minWidth: '225px', textAlign: 'right'}}
            >
              {userState && userState.profile && userState.profile.user?
                userState.profile.user.firstName + ' ' +
                userState.profile.user.lastName + ' ' +
                userState.profile.user.secondLastName : null
              }
              <br/>
              {userState && userState.profile && userState.profile.schoolYears && 
                <select 
                  onChange={onSchoolYearSelectedChanged} 
                  value={userState.schoolYear? userState.schoolYear.id : ''}
                  style={{
                    height: 'calc(2em + 2px)',
                    padding: '0px 0.5rem',
                    textAlign: 'right'
                  }}
                  className="form-control">
                {userState.profile.schoolYears.map((schoolYear,i) => <option key={i} value={schoolYear.id}>{schoolYear.name}</option>)}
              </select>}
            </span>
          </span>
        </li>

        <li className="nav-item dropdown no-arrow mr-5" style={{marginLeft:'-2rem'}}>
          <a className="nav-link dropdown-toggle" href="#" style={{paddingLeft: 0}} id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img className="img-profile rounded-circle" src={profilePic} />
          </a>
          {/* Dropdown - User Information */}
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            {/*
            <a className="dropdown-item" href="#">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" /> Profile
            </a>
            <a className="dropdown-item" href="#">
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" /> Activity Log
            </a>
            */}
            <a onClick={handleChangeProfile} className="dropdown-item" href="#">
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" /> Cambiar Perfil
            </a>
            <div className="dropdown-divider" />
            <a onClick={handleSignOut} className="dropdown-item" href="#">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
              Salir
            </a>
          </div>
        </li>


        {/* <!-- Nav Item - User Information 
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {userState && userState.profile && userState.profile.user?
                userState.profile.user.firstName + ' ' +
                userState.profile.user.lastName + ' ' +
                userState.profile.user.secondLastName : null
              }
              <div onClick={handleChangeProfile} className="text-right btn-change-profile mt-1">
                Cambiar Perfil <FontAwesomeIcon icon={faGear} className="ml-1" />
              </div>
            </span>
            <img src={profilePic} className="img-profile rounded-circle ml-2" style={{border: '6px solid #d3d3d363'}} />
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a onClick={handleSignOut} className="dropdown-item" href="#">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Salir
            </a>

          </div>
        </li>
        --> */}

      </ul>

    </nav>
   
  )
}

export default Topbar;