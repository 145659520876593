import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool, faUsersLine, faPersonChalkboard, faUserPlus, faUserGroup, faChalkboardUser } from '@fortawesome/free-solid-svg-icons';

import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

const SchoolSection = ({props, schoolId}) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState(null);

    React.useEffect(() => {
        getSchoolInfo();
    },[schoolId]);

    const getSchoolInfo = () => {
        setIsLoading(true);
        auth.getSchool(schoolId).then(result => {
            setSchoolInfo(result);
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    return (<CardBasic title="">
        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
        <div className='container'>
        <div className='row '>

        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

            {!schoolInfo? null : <div className="container mb-5 mt-5">
                <div className="row mb-1">
                    <div className="col-lg-6">
                        <p><span>{schoolInfo.school_cct}</span><br/><b>{schoolInfo.school_name}</b></p>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-lg-4">
                        <p><span>Estado</span><br/><b>{schoolInfo.state_name}</b></p>
                    </div>
                    <div className="col-lg-4">
                        <p><span>Municipio</span><br/><b>{schoolInfo.municipality_name}</b></p>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-lg-4">
                        <p><span>Region</span><br/><b>{schoolInfo.region_name}</b></p>
                    </div>
                    <div className="col-lg-4">
                        <p><span>Sector</span><br/><b>{schoolInfo.sector_cct} - {schoolInfo.sector_name}</b></p>
                    </div>
                    <div className="col-lg-4">
                        <p><span>Zona</span><br/><b>{schoolInfo.zone_cct} - {schoolInfo.zone_name}</b></p>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-lg-4">
                        <p><span>Grado de Marginación</span><br/><b>{schoolInfo.school_marginalizationLevel}</b></p>
                    </div>
                    <div className="col-lg-4">
                        <p><span>Nivel</span><br/><b>{schoolInfo.level_name}</b></p>
                    </div>
                    <div className="col-lg-4">
                        <p><span>Turno</span><br/><b>{schoolInfo.school_shift}</b></p>
                    </div>
                </div>
                <hr className="sidebar-divider d-none d-md-block mt-5"></hr>
                {!schoolInfo.principal? null :<div className="row mb-0 mt-5">
                    <div className="col-lg-12">
                        <p className="mb-0">Director</p>
                    </div>
                    <div className="col-lg-12">
                        <p className="lift btn"><b>{schoolInfo.principal.principals_firstName} {schoolInfo.principal.principals_lastName} {schoolInfo.principal.principals_secondLastName}</b>
                            <i className="fas fas-action fa-edit ml-2" data-toggle="tooltip" data-placement="top" title="Editar"></i>
                        </p>
                    </div>
                </div>}
                {!schoolInfo.subprincipal? null :<div className="row mb-0 mt-3">
                    <div className="col-lg-12">
                        <p className="mb-0">Subdirector</p>
                    </div>
                    <div className="col-lg-12">
                        <p><b>{schoolInfo.principal.principals_firstName} {schoolInfo.principal.principals_lastName} {schoolInfo.principal.principals_secondLastName}</b></p>
                    </div>
                </div>}

            </div>}

        </div>

        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

            {!schoolInfo? null : <div className="container mb-5 mt-5">
                <div className="row mb-1">
                    <div className="col-lg-6">
                        <p><b>Agregar</b></p>
                    </div>
                </div>
                <div className="row mb-1 mt-0">
                    <div className="col-12">
                        <Link
                            className="btn btn-block btn-link-add lift text-left"
                            to={{
                                pathname: `/schools/${schoolId}/teachers/0`,
                                schoolCct: schoolInfo.school_cct,
                            }}>
                            <FontAwesomeIcon icon={faPersonChalkboard} className="mr-3" /><b>Docente</b>
                        </Link>
                    </div>
                </div>
                <div className="row mb-1 mt-0">
                    <div className="col-12">
                        <button type="button" className="btn btn-block btn-link-add lift text-left">
                          <FontAwesomeIcon icon={faUserGroup} className="mr-3" />
                          <FontAwesomeIcon icon={faUserPlus} className="mr-3" style={{marginLeft: '-25px', height: '15px'}} /><b>Grupo</b>
                        </button>
                    </div>
                </div>
                <div className="row mb-1 mt-0">
                    <div className="col-12">
                        <button type="button" className="btn btn-block btn-link-add lift text-left">
                          <FontAwesomeIcon icon={faChalkboardUser} className="mr-3" /><b>Estudiante</b>
                        </button>
                    </div>
                </div>
            </div>}

        </div>

        </div>
        </div>

    </CardBasic>)
}

export default SchoolSection;
