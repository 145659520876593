import React, { Component } from 'react';

import PageHeading from '../../components/PageHeading';

class Charts extends Component {
    render() {
        return (<>

            {/* <!-- Page Heading --> */}
            <PageHeading title="Charts" />

        </>)
    }
}

export default Charts;