import { Type } from 'react-bootstrap-table2-editor';
import auth from '../../../services/users/AuthService';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createPrincipal(data);

const columns = [{
  dataField: 'curp',
  text: 'CURP',
  description:'CURP del Docente',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module1',
  text: 'Módulo 1',
  description:'Módulo 1',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module2',
  text: 'Módulo 2',
  description:'Módulo 2',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module3',
  text: 'Módulo 3',
  description:'Módulo 3',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module4',
  text: 'Módulo 4',
  description:'Módulo 4',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'module5',
  text: 'Módulo 5',
  description:'Módulo 5',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module6',
  text: 'Módulo 6',
  description:'Módulo 6',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module7',
  text: 'Módulo 7',
  description:'Módulo 7',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'module8',
  text: 'Módulo 8',
  description:'Módulo 8',
  editable: true,
  required: true,
  style: errorsStyle
}];

export {columns, saveInfo};
