import React from 'react';
import TableOutline from '../../../../components/Charts/TableOutline';
import Bar from '../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';
import ImgLevels from'../../../../assets/unesco/img/niveles.jpeg';
import IDAIPrimerGrado from './idai/IDAIPrimerGrado';
import IDAISegundoGrado from './idai/IDAISegundoGrado';
import IDAITercerGrado from './idai/IDAITercerGrado';

const IDAISection = ({schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        {!(schoolId && currentSchoolYear)? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p><b>4. Niveles de conceptualización de la lengua escrita</b></p>
                                <p>
                                    Los niveles asignados en el Instrumento Diagnóstico de 
                                    Alfabetización Inicial son coherentes con la perspectiva 
                                    psicogenética del desarrollo de la Lengua Escrita que ha 
                                    sido ampliamente documentado por Emilia Ferreiro desde 1979.
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p>
                                    <img src={ImgLevels} alt="..." className="w-100" />
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row justify-content-center text-center mt-5">
                        <div className="col-8">

                            <IDAIPrimerGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                            <IDAISegundoGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                            <IDAITercerGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                        </div>

                        <div className="col-4 mt-5 pt-5">
                            <p className="mt-5 pt-5" style={{color: 'black', textAlign: 'left'}}>Observa las cantidades de estudiantes por cada nivel. Considerando los niveles de conceptualización de la lengua y la meta compartida, consulta el documento <b>¿Qué actividades funcionan para impulsar el aprendizaje de la lengua escrita? </b>
                            <a target="_blank" href="https://drive.google.com/file/d/151uyVmN_cMBpTTXLdfrMi4SnZ88-tFql/view?usp=sharing">https://drive.google.com/file/d/151uyVmN_cMBpTTXLdfrMi4SnZ88-tFql/view?usp=sharing</a>
                            Pon en práctica las recomendaciones para consolidar la alfabetización de las niñas y los niños.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAISection;
