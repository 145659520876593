import React from 'react'
import {
  Redirect,
} from 'react-router-dom'
import auth from '../../services/users/AuthService'
import SignIn from './SignIn'


function SignInWithoutAuth(props) {
const isAuthenticated = auth.isAuthenticated();
  return (
       isAuthenticated
        ? (
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
        ) : (
          <SignIn />   
        )  
  );
}

export default SignInWithoutAuth
