import React, { useEffect, useState, createRef } from 'react';
import Loading from '../../Loading';
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CardBasic from '../../Cards/Basic';
import reports from '../../../services/reports/ReportsService';

Chart.defaults.global.defaultFontFamily = 'Nunito';
Chart.defaults.global.defaultFontColor = '#858796';
Chart.plugins.register(ChartDataLabels);

// Change default options for ALL charts
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  color: '#FFF'
});

const HorizontalBar3 = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const chartRef = createRef();

    useEffect(()=>{init()},[]);

    const init = async() => {
        if(isInit){
            return;
        }
        const myChartRef = chartRef.current.getContext("2d");

        setIsInit(true);
        setIsLoading(true);

        reports.getData(props.requestObj).then(result => {
            if (props.requestObj.breakdownDimension) {
                renderGraphBreakdown(myChartRef, result);
            }else{
                renderGraph(myChartRef, result);                
            }
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

    }

    const renderGraphBreakdown = (ref, data) => {
        let grupo1 = groupBy(data, props.requestObj.dimension.name);
        let grupo2 = groupBy(data, props.requestObj.breakdownDimension.name);

        const dataset = [];
        Object.keys(grupo1).forEach(key1 => {
            const total = grupo1[key1].reduce((accumulator, row) => {
                return accumulator + Number(row.recordCount);
            }, 0);

            Object.keys(grupo2).forEach(key2 => {
                const item = grupo1[key1].find(e => key2 === e[props.requestObj.breakdownDimension.name]);

                if(item){
                    dataset.push({
                        ...item,
                        total,
                    });
                }else{
                    const clone = { ...grupo1[key1][0] };
                    clone.recordCount = 0;
                    clone.total = total;
                    clone[props.requestObj.dimension.name] = key1;
                    clone[props.requestObj.breakdownDimension.name] = key2;
                    dataset.push(clone);
                }
            })
        });
        let grupo3 = groupBy(dataset, props.requestObj.breakdownDimension.name);

        new Chart(ref, {
            type: props.horizontal? 'horizontalBar': 'bar',
            options: props.porcent? props.porcentBold? optionsPorcentBold: optionsPorcent: options,
            plugins: [ChartDataLabels],
            data: {
                labels: Object.keys(grupo1),
                datasets: Object.keys(grupo3).map(key => {
                    return {
                        label: props.requestObj.breakdownDimension.label + ' ' + key,
                        data: grupo3[key]
                            .sort((a,b) => {
                            if(!props.requestObj.order){
                                return a[props.requestObj.dimension.name].localeCompare(b[props.requestObj.dimension.name])
                            }

                            if(props.requestObj.order.ascendent){
                                return a[props.requestObj.order.dimension] - b[props.requestObj.order.dimension];
                            }else{
                                return b[props.requestObj.order.dimension] - a[props.requestObj.order.dimension];
                            }

                        })
                        .map((r) => toFormat(r.recordCount, r.total, props.porcent)),
                        barPercentage: props.porcent? 0.2 : 1,
                        backgroundColor: grupo3[key].map(row => props.requestObj.colorLabels[row[props.requestObj.breakdownDimension.name]]),
                    }
                }).sort((a,b) => a.label.localeCompare(b.label))
            },
            
        });
    }

    const renderGraph = (ref, data) => {
         const total = data.reduce((accumulator, row) => {
          return accumulator + Number(row.recordCount);
        }, 0);

        new Chart(ref, {
            type: props.horizontal? 'horizontalBar': 'bar',
            options: props.porcent? optionsPorcent: options,
            data: {
                labels: data.map(item => props.requestObj.dimensions[0].label + ' ' + item[props.requestObj.dimensions[0].name]),
                datasets: [...props.requestObj.metrics, ...props.requestObj.customMetrics].map(metric => ({
                    label: metric.label,
                    data: data.map(item =>  toFormat(item[metric.name], item['recordCount'], props.porcent)),
                    backgroundColor: metric.color,
                    borderColor: metric.color,
                    borderWidth: 1,
                    barPercentage: 1,
                }))
            },
            
        });
    }


    return (<>

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <br/>

        <span style={{color: 'black', textAlign: 'center'}}>
            <p><b>{props.title}</b></p>
        </span>

        <div className="chart-area">
            <canvas id="myAreaChart" ref={chartRef}></canvas>
        </div>
        {/*
            <hr />
            Styling for the area chart can be found in the <code>/Components/Charts/Line/Index.js</code> file.
        */}

    </>)
}

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const toFormat = (value, total, porcent) => {
    if(porcent){
        return (Number(value/total)*100).toFixed(2);
    }else{
        return value;
    }
}

const randomColor = () => {
    return "hsla(" + ~~(360 * Math.random()) + ",90%,80%,1)";
}

const adjust = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const options = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.2,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return value;
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

const optionsPorcent = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
            ticks: {
                callback: function(value, index, values) {
                    return String(value) + ' %';
                }
            }
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.5,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return ''.concat(String(value), '%');
                }else{
                    value = "";
                    return value;
                }
            },
            color: 'black',
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

const optionsPorcentBold = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
            ticks: {
                callback: function(value, index, values) {
                    return String(value) + ' %';
                }
            }
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.5,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return ''.concat(String(value), '%');
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}



export default HorizontalBar3;
