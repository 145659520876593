import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
  faMugHot,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../../services/users/AuthService';
import IconCCT from '../../../../components/IconCCT';

import BaseListAdapterWithOptions from './../../core/BaseListAdapterWithOptions';

const SectorSupervisorsListSection = ({props, sectorId}) => {

  const CustomConfig = {
    singular: 'Jefe de Sector',
    plural: 'Jefes de Sector',
    filters: ['firstName', 'lastName', 'secondLastName', 'cct', 'position', 'email'],
    excludes: ['nombre', 'supervisor', 'atp', 'regional', 'jefe', ':', 'cct'],
    options: {
      loadService: auth.getSectorSupervisors,
      loadParams: sectorId,
      deleteService: auth.deleteSupervisor,
      adminTo: "/zones/{id}"
    }
  }

  const CustomViewHolder = ({item}) => {
    const supervisor = item;

    return (<>
      <div className="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
        {supervisor.firstName? supervisor.firstName.charAt(0): ''}{supervisor.lastName? supervisor.lastName.charAt(0): ''}
      </div>
      <div className="job-content">
        <h5 className="text-center text-md-left mb-0">{supervisor.firstName} {supervisor.lastName}  {supervisor.secondLastName}</h5>
        <ul className="d-md-flex ff-open-sans pl-0 text-left">
          <li className="mr-md-4">
            <IconCCT />{supervisor.cct}<br/>
            <FontAwesomeIcon icon={faEnvelope} /> {supervisor.email}<br/>
          </li>
        </ul>
      </div>
    </>);
  }

  return (
    <BaseListAdapterWithOptions 
      ItemViewHolder={CustomViewHolder} 
      config={CustomConfig} 
    />
  );

}

export default SectorSupervisorsListSection;
