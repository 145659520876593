import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import BaseListAdapter from './../core/BaseListAdapter';

const config = {
  singular: 'Estado',
  plural: 'Estado',
  filters: ['name',],
  excludes: ['estado', ':'],
}

const StatesListSection = ({props}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);

  React.useEffect(() => {
    getStates();
  },[]);

  const getStates = () => {
    setIsLoading(true);
    auth.getStates().then(result => {
        setStates(result);
        setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });   
  }

  const StateViewHolder = ({item}) => {
    const state = item;
    return (
      <div className="job-box d-md-flex align-items-center justify-content-between mb-3">
        <div className="job-left my-4 d-md-flex align-items-center flex-wrap">
          <div className={`${state.isActive? 'green-border-shadow': ''} img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex text-uppercase`}>
            {state.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}
          </div>
          <div className="job-content">
            <h5 className="text-center text-md-left mb-0">{state.name}</h5>
            {state.isActive && <h6 style={{color: '#6a7b5c'}}>participando actualmente en la política</h6>}
          </div>
        </div>
        <div className="d-flex">
          {state.isActive &&
            <div className="job-right my-4 flex-shrink-0 mr-3">
              <Link
                className="btn d-block w-100 d-sm-inline-block btn-light"
                to={`/states/${state.id}`}>
                  <FontAwesomeIcon icon={faGear} className="mr-1" /> Administrar
              </Link>
            </div>
          }
        </div>
      </div>
    );
  }

  return (
    <BaseListAdapter 
      items={states}
      ViewHolder={StateViewHolder} 
      config={config} 
    />
  );

}

export default StatesListSection;
