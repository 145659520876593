import React, { useState } from 'react';
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { gtag } from 'ga-gtag';
import Loading from '../../components/Loading';
import Swal from 'sweetalert2'
import ProfilePic from'../../assets/unesco/img/profile_pic.jpeg';
import mainLogoWhite from'../../assets/unesco/img/logos/unesco-logo-white.png';
import SurveyModal from '../../components/modals/SurveyModal';
import * as Roles from '../../auth/Roles';
import $ from 'jquery';
import {userLogin, userLogOut} from '../../services/users/ActionTypes';
import auth from '../../services/users/AuthService';

const ProfileSelection = (props) => {

  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let user = useSelector(state => state.userState.user);
  let menuState = useSelector(state => state.menuState);

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(()=>{
    document.getElementById('body').className = 'page-top';
    let isMounted = true;

    /*
    if (!menuState.surveyOpen ) {
      $('#modalSurvey').modal('toggle');
    }
    */

    return () => {
      isMounted = false;
    };

  }, []);

  const handleSelection = (profile) => {
    setIsLoading(true);
    auth.getProfile(profile.id).then(user => {
      console.log('user-result', user);
      dispatch(userLogin(user));
      gtag('event', 'login', { 
        'success': 'true',
        'username': user.username
      });

      let { from } = location.state || { from: { pathname: "/" } };
      history.push(from.pathname);
      setIsLoading(false);

    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);

      const message = error.response && error.response.data && error.response.data.message ?
        error.response.data.message : 'Perfil no disponible.';
      Swal.fire({
        icon: 'error',
        text: message,
      });
    }); 

  }

  const handleSignOut = () => {
    Swal.fire({
      title: '¿Seguro que desea cerrar sesión?',
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        dispatch(userLogOut());
        auth.logout();
      }
    });
    
  }

  const cap = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (<>

     <nav className="navbar topbar" style={{position: 'absolute', width: 200}}>
      <div id="logo">
        <img  src={mainLogoWhite} className="w-100" alt="logo"/>
      </div>
    </nav>

    <div id="profiles-container">
      <div className="container">

        <div className="row align-items-center justify-content-center text-center">
          <h1 className="display-4 fw-semi-bold mb-0 text-white">¡Bienvenid@, {cap(user.firstName)}!</h1>
        </div>
        
        <div className="row align-items-center justify-content-center text-center">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-scroll justify-content-center">
              <li className="breadcrumb-item">
                <h5 style={{color: '#ffdf97'}}>Por favor, seleccione uno de sus perfiles:</h5>
              </li>
            </ol>
          </nav>
        </div>
        
        <div className="row align-items-center justify-content-center text-center m-5 p-5">

          {user.profiles && user.profiles.map((profile,i) => 
            <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={i}>
              <a onClick={(e) => {e.preventDefault(); handleSelection(profile); }} href="#">
                <div className="user-profile">
                  <div className="picture">
                    <img className="img-fluid" src={ProfilePic} />
                  </div>
                  <div className="team-content">
                    <h3 className="name mb-0">{profile.cct}</h3>
                    <h4 className="title">{profile.role? profile.role.name : ''}</h4>
                  </div>
                  <div className="social"></div>
                </div>
              </a>
            </div>
          )}

        </div>


        <div className="row align-items-center justify-content-center text-center">
          <a onClick={handleSignOut} className="nav-link p-0 mb-5" href="#">
            Cerrar sesión
          </a>
        </div>

      </div>
    </div>
  </>)
}

export default ProfileSelection;
