import React from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import { gtag } from 'ga-gtag';
import Swal from 'sweetalert2';
import {
    FormGenerator,
    Validators,
    FieldGroup,
    FieldControl,
    FormBuilder
} from "react-reactive-form";
import { clickSurveyOpen } from '../../services/menu/ActionTypes';
import Loading from '../../components/Loading';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import $ from 'jquery';

let myForm = FormBuilder.group({});

const InstrumentFormStudent = ({ props, formId, studentSelected, onEditingAnswers, onUpdateStudentResponseId }) => {

    let dispatch  = useDispatch();
    let location  = useLocation();
    let profile      = useSelector(state => state.userState.profile);

    const [isLoading, setIsLoading] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [columns, setColumns] = React.useState([]);
    const [columnsByTag, setColumnsByTag] = React.useState(null);
    const [summary, setSummary] = React.useState(null);
    const [isEditingAnswers, setIsEditingAnswers] = React.useState(false);

    const groupBy = (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const markFormDirty = (e) => {
      e.preventDefault();
      onEditingAnswers(true);
      setIsEditingAnswers(true);
    }

    const handleClickSurveyOpen = (e) => {
      dispatch(clickSurveyOpen());
    }

    React.useEffect(()=>{
      if (studentSelected) {
        setIsLoading(true);
        myForm.reset();
        auth.getInstrumentByFormIdAndStudent(profile.user.id, formId, studentSelected.id).then(result => {
            setTitle(result.instrument.name);
            setSummary(result.summary);
            if(result.summary){
              handleClickSurveyOpen(null);
            }

            try {
              setColumnsByTag(groupBy(result.instrument.forms[0].questions, 'tag'));
              setColumns(result.instrument.forms[0].questions);
            } catch(e) {
              console.log('e', e);
            }
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });
      }

    }, [studentSelected]);

    React.useEffect(() => {
      setTimeout(() => {
          if(columns){
              columns.forEach(column => {
                  if( myForm.controls[column.id]){
                      myForm.controls[column.id].setValue(column.optionSelected);
                      myForm.controls[column.id].patchValue(column.optionSelected);
                  }
              });
          }
      }, 500);
    }, [columns]);

    const handleReset = () => {
        // myForm.reset();
    };

    const handleSubmit = async (e, value) => {
        e.preventDefault();

        const responses = Object.keys(value).map(key => {
          return {
              questionId: key,
              optionId: value[key],
              singleResponseId: columns.filter(column => Number(column.id) === Number(key))[0].singleResponsesId
          }
        });
        const obj = {
            responses,
            userId: profile.user.id,
            studentId: studentSelected.id,
            formId: formId
        };

        setIsLoading(true);
        await auth.saveInstrumentForm(obj).then(result => {
            // setColumns(result.forms[0].questions);
            // handleClickSurveyOpen(null);

            setColumnsByTag(groupBy(result.forms[0].questions, 'tag'));
            setColumns(result.forms[0].questions);

            setIsEditingAnswers(false);
            onEditingAnswers(false);
            onUpdateStudentResponseId(formId, studentSelected.id, result.summaryId);
            setIsLoading(false);

            Swal.fire({
              icon: 'success',
              title: 'Sus respuestas fueron guardadas con éxito',
            });

        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'No fue posible guardar el formulario.',
            });
        });
    };

    /*

    const setForm = form => {
        myForm = form;

        // Set the meta data
        myForm.meta = {
          handleReset: handleReset,
          handleSubmit: handleSubmit,
        };
    };
    */

  const ColHeader = ({columns}) => {
    return (
      <div className="col-12 mb-5 pb-5">
        <div className='container'>
          <div className="row align-items-center justify-content-center text-center mt-5">
            <div className="col-6">

            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

              {columns.map((column, j) => {

                const meta = {
                  label: column.questionText,
                  name: column.questionText,
                  id: column.id,
                  isDisabled: summary? true: false,
                  options: column.options || [],
                  defaultValue: column.optionSelected || '',
                };

                return (
                  <FieldControl
                    name={String(meta.id)}
                    options={{ validators: Validators.required }}
                    render={({handler, touched, hasError}) => (
                      <>
                        <label>{meta.label}</label>
                        <div className="mb-5">
                          <select {...handler()} id={meta.id} className="form-control form-control-user">
                            <option value=''> - Seleccione - </option>
                            { meta.options.map(option =>
                                <option key={option.id} value={option.id}>{option.optionText}</option> )
                            }
                          </select>
                          <span style={{color: 'red', float: 'left'}}>{touched && hasError("required") && `*Se requiere una respuesta.`}</span>
                        </div>
                      </>
                    )}
                  />
                )
              })}

            </div>
          </div>
        </div>
      </div>
    );
  }

  const ColTable = ({columns, tag}) => (
    <div className="col-md-4 col-sm-12">
      <div className="card shadow mb-4">
        <div className="card-body p-0">
          <table className="table" id="dataTable" width="100%" cellSpacing={0}>
            <thead className="bg-primary text-white">
              <tr style={{textAlign: 'center', background: '#F6F9FB', color: '#090761'}}>
                <th colSpan="2">{tag}</th>
              </tr>
              <tr>
                <th>Pregunta</th>
                <th style={{
                  textAlign: 'right', 
                  minWidth: 140,
                  paddingRight: 20
                }}>1- Correcto <br /> 0 - Incorrecto</th>
              </tr>
            </thead>
            <tbody>

              {columns.map((column, j) => (
                <FieldControl
                  key={column.id}
                  name={String(column.id)}
                  options={{ validators: Validators.required }}
                  render={({handler, touched, hasError, meta}) => (
                    <>
                      <tr >
                        <td>{meta.label}</td>
                        <td className="text-right p-2">
                          <select {...handler()} id={meta.id} className="form-control form-control-user">
                            <option value=''> - Seleccione - </option>
                            { meta.options.map(option =>
                                <option key={option.id} value={option.id}>{option.optionText}</option> )
                            }
                          </select>
                        </td>
                      </tr>
                      {touched && hasError("required")?
                        <tr>
                          <td colSpan="2" style={{
                            padding: '0px 10px 10px', 
                            border: 'none',
                            color: 'red'
                          }}>
                            <span>*Se requiere una respuesta.</span>
                          </td>
                        </tr>
                      :null }
                    </>
                  )}
                  options={{
                    validators: Validators.required
                  }}
                  meta={{
                    label: column.questionText,
                    name: column.questionText,
                    id: column.id,
                    isDisabled: summary? true: false,
                    options: column.options || [],
                    defaultValue: column.optionSelected || '',
                  }}
                />
              ))}
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  
  return (<>
    <h6 className="pl-4">
      <b>Estudiante:</b> {studentSelected.firstName} {studentSelected.lastName} {studentSelected.secondLastName}
    </h6>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {columnsByTag && <FieldGroup
      control={myForm}
      render={({ get, invalid, reset, value, touched }) => (
        <form onSubmit={e => handleSubmit(e, value)} onChange={markFormDirty}>

          <div className='container'>
            
            <div className="row">
              {columnsByTag && Object.entries(columnsByTag).map(([ key, val ]) => (
                key? <ColTable key={key} tag={key} columns={columnsByTag[key]} />: 
                  <ColHeader key={key} columns={columnsByTag[key]} />
                
              ))}
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
              <div className="col-6">
                <button type="submit" disabled={invalid || (!invalid && !isEditingAnswers)} className="btn btn-block btn-primary mb-5 lift">
                  Guardar
                </button>
              </div>
            </div>

          </div>
        </form>
      )}
    />}
  </>);
}

export default InstrumentFormStudent;