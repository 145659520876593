import React, { useState }from 'react';
import "react-dual-listbox/lib/react-dual-listbox.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Redirect, } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import swipeGif from '../../assets/unesco/img/giphy.gif';
import { gtag } from 'ga-gtag';
import auth from '../../services/users/AuthService'
import PageBreadcrumb from '../../components/PageBreadcrumb';
import NewStudentModal from '../../components/modals/NewStudentModal';
import InstrumentMathStudent from './InstrumentMathStudent';

import $ from 'jquery';

const InstrumentMath = (props) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [showSwipe, setShowSwipe] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  const isMobile = width <= 520;

  React.useEffect(()=>{
    setShowSwipe(true);
    setTimeout(() => {
      setShowSwipe(false);
    }, 10000);

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let userState = useSelector(state => state.userState);
  const instrumentId = props.match.params.instrumentId;
  const groupId = props.match.params.groupId;

  const grupo = userState.groups?
    userState.groups.find(group => {
      return Number(group.id) === Number(groupId)
    }) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [forms, setForms] = useState([]);
  const [score, setScore] = useState(null);
  const [studentSelected, setStudentSelected] = useState(null);
  const [formSelected, setFormSelected] = useState(null);
  const [key, setKey] = useState('students');
  const [isEditingAnswers, setIsEditingAnswers] = useState(false);

  const [instrument, setInstrument] = useState({});
  const [patternAnswers, setPatternAnswers] = useState("");
  const [sizeAnswers, setSizeAnswers] = useState(0);

  const [inEditMode, setInEditMode] = useState({
      status: false,
      rowKey: null,
      formKey: null,
  });

  gtag('event', 'page_view', {
      page_title: 'Captura de Instrumento',
      page_location: 'instruments',
      page_path: '/instruments/groups',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : '',
      'instrument': `${instrument.name}`,
      'group': grupo? `${grupo.grado} Grado - ${grupo.cct}`: ''
  });

  React.useEffect(()=>{
    if(!studentSelected){
      loadData();
    }
  }, [props.match.params]);

  const loadData = () => {
    setIsLoading(true);
    auth.getInstrument(instrumentId).then(instrument => {
      setInstrument(instrument);
      setPatternAnswers(instrument.patternAnswers);
      setSizeAnswers(instrument.sizeAnswers);
      return auth.getScoresByGroupAndInstrument(groupId, instrumentId);
    }).then(result => {
      setStudents(result.students || []);
      setForms(result.forms || [])
      setIsLoading(false);

    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al cargar la información',
        footer: ''
      });
    })

  }

  const updateScore = ({idStudent, form}) => {
    const responseId = form.responseId;
    const newScore = score;
    const oldScore = form.score;

    return new Promise((resolve, reject) => {
      auth.updateScore(idStudent, responseId, newScore).then(result => {
        resolve(newScore);
        gtag('event', 'edit_student', { 
          'action': 'update_score',
          'student_id': idStudent,
          'response_id': responseId,
          'old_score': oldScore,
          'new_score': newScore,
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });

      }).catch(error => {
        console.log('err', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un error al guardar la información',
          footer: ''
        });
        resolve(oldScore);
      });
    });
  }

  const saveScore = ({idStudent, form}) => {
    const formId = form.formId;
    const newScore = score;
    const oldScore = form.score;

    return new Promise((resolve, reject) => {
      auth.saveScore(idStudent, formId, newScore).then(result => {
        form.responseId = result.id;
        resolve(newScore);

        gtag('event', 'edit_student', { 
          'action': 'save_score',
          'student_id': idStudent,
          'form_id': formId,
          'old_score': oldScore,
          'new_score': newScore,
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });

      }).catch(error => {
        console.log('err', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un error al guardar la información',
          footer: ''
        });
        resolve(oldScore);
      });
    });
  }

  const onSave = async({idStudent, form}) => {
    setIsLoading(true);
    if (!form.responseId) {
      if(!score){
        // TODO: maybe hay que borrar la respuesta en 
        // el servicio en vez de mostrar el error.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Favor de introducir un valor válido.',
          footer: ''
        });
        setIsLoading(false);
        return;
      }
      form.score = await saveScore({idStudent, form});
    } else {
      form.score = await updateScore({idStudent, form});
    }
    setIsLoading(false);
    onCancel();
  }

  const onEdit = ({student, id, formId, currentScore}) => {
    setStudentSelected(student);
    setFormSelected(formId);
    setKey('responses');

    /*

      setInEditMode({
          status: true,
          rowKey: id,
          formKey: formId
      })
      setScore(currentScore);
      */
  }

  const onEditStudent = (student) => {
    setStudentSelected(student);
    $('#modalNewStudent').modal('toggle');
  }

  const onStudentEdited = ({student}) => {
    setIsLoading(true);
    studentSelected.firstName = student.firstName;
    studentSelected.lastName = student.lastName;
    studentSelected.secondLastName = student.secondLastName;
    studentSelected.sex = student.sex;
    setIsLoading(false);
  }

  const onCancel = () => {
      // reset the inEditMode state value
      setInEditMode({
          status: false,
          rowKey: null,
          formKey: null
      })
      // reset the unit price state value
      setScore(null);
  }

  const toggleFlag = ({student, status}) => {
    setIsLoading(true);
    auth.toggleStudent(student.id, status).then(result => {
      student.status = status;
      setIsLoading(false);

      gtag('event', 'edit_student', { 
          'action': 'as_duplicated',
          'student_id': student.id,
          'status': status,
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
      });

    }).catch(error => {
      console.log('err', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al guardar la información',
        footer: ''
      });
      setIsLoading(false);
    });
  }

  const downloadReport = () => {
    const ws_name = instrument.shortName || "Reporte";
    const ws_data = [];

    let titles = [
      "Nombre(s)",
      "Apellido Paterno",
      "Apellido Materno",
      "Duplicado",
    ];
    titles = titles.concat(forms.map((form, i) => form.name));
    ws_data.push(titles);

    students.forEach((student) => {
      const row = [
        student.firstName,
        student.lastName,
        student.secondLastName,
        (student.status === "duplicated") ? "Sí" : ""
      ];
      student.forms.forEach((form) => {
        row.push(form.score);
      });
      ws_data.push(row);
    });

    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: ws_name,
        Subject: ws_name,
        Author: "Siaf",
        CreatedDate: new Date()
    };
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // fix width
    ws["!cols"] = titles.map(title => ({ wch: 20 }));

    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    var wopts = { bookType:"xlsx", bookSST:false, type:"array" };
    var wbout = XLSX.write(wb, wopts);
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${ws_name}.xlsx`);

    gtag('event', 'download_capture_report', { 
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

  }

  const renderEditableRow = (student, form) => {
    return (<>
      {
        inEditMode.status && inEditMode.rowKey === student.id
        && inEditMode.formKey === form.formId ? (
          <>
            <input maxLength="4" value={score} className="w-25" onInput={(event) => {
              let value = ("" + event.target.value).toUpperCase();

              if(sizeAnswers && value.length > sizeAnswers) {
                value = value.substring(0, sizeAnswers)
              }

              if(patternAnswers){
                value = value.replace(new RegExp(patternAnswers), "");
              }

              setScore(value)
            }} />
            <React.Fragment>
              <span onClick={() => onSave({idStudent: student.id, form})}>
                <i className="fas fas-action fa-save" data-toggle="tooltip" data-placement="top" title="Guardar"></i>
              </span>

              <span onClick={() => {
                onCancel();
              }}>
                <i className="fas fas-action fa-window-close" data-toggle="tooltip" data-placement="top" title="Cancelar"></i>
              </span>
            </React.Fragment>
          </>
        ) : (
          <>
            <input value={form.score} className="w-25" disabled={true} style={{background: 'white', border: 'none', color: '#1d2125'}} />
            <span onClick={() => onEdit({student: student, id: student.id, formId: form.formId, currentScore: form.score})}>
              {form.responseId ?
                <i className="fas fas-action fa-check-square" style={{color: '#28b328'}}></i>
              : <i className="fas fas-action fa-edit" data-toggle="tooltip" data-placement="top" title="Editar"></i>
              }
            </span>
          </>
        )
      }
    </>
    );
  }

  const onEditingAnswers = (isEditing) => {
    setIsEditingAnswers(isEditing);
  }

  const onUpdateStudentResponseId = (formId, studentId, responseId) => {
    setStudents(students.map((student) => {
      if(student.id === studentId){
        student.forms.map((form) => {
          if(form.formId === formId){
            form.responseId = responseId;
          }
          return form;
        });
      }
      return student;
    }));
  }

  const onSelectTab = (k) => {
    if (isEditingAnswers) {
      Swal.fire({
        title: `¿Seguro que desea regresar?`,
        text: 'Al regresar al listado de alumnos podría perder la captura actual',
        showDenyButton: true,
        confirmButtonText: 'Sí',
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          setKey(k);
        }
      });
    }else{
      setKey(k);
    }
  }

  return (<>
    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {!grupo?
        <Redirect to={{
          pathname: '/dashboard',
          state: { from: props.location }
        }} />
      : <>


    {/* <NewStudentModal onStudentEdited={onStudentEdited} studentId={studentSelected.id} groupId={groupId} /> */}

    <div className="row mt-5">
      <div className="col-md-9 col-12">
        <h1 className="mb-2">{instrument.name}</h1>
        <PageBreadcrumb title={`${grupo.grado} Grado - ${grupo.cct}`} />
      </div>
      {/*
      <div className="col-md-3 col-12">
        <button type="button" onClick={() => {downloadReport()}} className="btn btn-block btn-primary mb-5 lift">
          Descargar Reporte
        </button>
      </div>
      */}
    </div>

    <div className="mt-5">

      <Tabs 
        defaultActiveKey="students"
        activeKey={key}
        onSelect={onSelectTab}>
        <Tab eventKey="students" title="Estudiantes">
          <div>
            <p className="mb-2 mt-5">Presione el ícono <i className="fas fas-action fa-edit"></i> para registrar los resultados de cada estudiante.
            En caso de encontrar estudiantes duplicados, debe marcar el ícono<i className="fas fas-action fa-flag"></i>.</p>
            <div className="card shadow mb-4">
              <div className="card-body p-0">
                <div className="table-responsive table-rounded">
                  {isMobile && showSwipe?
                    <div style={{
                        position: 'absolute',
                        opacity: 0.25,
                        textAlign: 'center',
                        margin: '0 auto',
                        width: '100%'
                      }}  onClick={() => {setShowSwipe(false)}}>
                      <img className="img-fluid w-80" src={swipeGif} 
                        alt="tut" />
                    </div>
                  :null }
                  <table className="table" id="dataTable" width="100%" cellSpacing={0}>
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Nombre(s)</th>
                        <th>Apellido Paterno</th>
                        <th>Apellido Materno</th>
                        {forms.map((form, i) => { return (
                          <th key={i} style={{
                            textAlign: 'center',
                            width: '150px', 
                          }}>{form.name}</th>
                        )})}
                        <th style={{
                          textAlign: 'right', 
                          minWidth: 140,
                          paddingRight: 20
                        }}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>{students.map((student) => (
                      <tr key={student.id} className={student.status === 'duplicated'? 'disabled': ''}>
                        <td>{student.firstName}</td>
                        <td>{student.lastName}</td>
                        <td>{student.secondLastName}</td>
                        {student.forms.map((form, j) => { return (
                          <td key={j}>
                            {form.editable && student.status !== 'duplicated'?
                              renderEditableRow(student, form)
                            : form.score}
                          </td>
                        )})}
                        <td className="text-right">
                          {student.status === 'duplicated'?
                            <i className="fas fas-action fa-user-alt-slash" onClick={() => toggleFlag({student, status: 'active'})}
                            data-toggle="tooltip" data-placement="top" title="Desmarcar duplicado"></i>
                          : <>
                            <FontAwesomeIcon icon={faUserPen} style={{marginRight: 10, fontSize: '1.3rem', cursor: 'pointer'}} 
                              onClick={() => onEditStudent(student)} />
                            <i className="fas fas-action fa-flag" onClick={() => toggleFlag({student, status: 'duplicated'})}
                              data-toggle="tooltip" data-placement="top" title="Marcar duplicado"></i>
                          </>
                          }
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="responses" title="Respuestas" disabled>
          {studentSelected && <InstrumentMathStudent
            formId={formSelected} 
            studentSelected={studentSelected} 
            onEditingAnswers={onEditingAnswers} 
            onUpdateStudentResponseId={onUpdateStudentResponseId} />}
        </Tab>
      </Tabs>

    

    </div>
    </>
  }

  </>)
}

export default InstrumentMath;
