import React, { useState, useRef } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import HorizontalBar2 from '../../components/Charts/HorizontalBar2';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';
import TitleSection from './sections/TitleSection';
import SummarySection from './sections/SummarySection';
import GoalsSection from './sections/GoalsSection';
import IDAISection from './sections/IDAISection';
import IDAI2Section from './sections/IDAI2Section';
import IDAIGoalsSection from './sections/IDAIGoalsSection';
import ICESection from './sections/ICESection';
import IDAILevelsSection from './sections/IDAILevelsSection';
import TrayectoSection from './sections/TrayectoSection';
import ResultadosSection from './sections/ResultadosSection';
import AplicacionPorGrupoSection from './sections/AplicacionPorGrupoSection';
import FormacionDocenteGraphSection from './sections/FormacionDocenteGraphSection';
import { Redirect, } from 'react-router-dom';

const StateSummaryReport = (props) => {

    let userState = useSelector(state => state.userState);

    let componentRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateSelectedName, setStateSelectedName] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});
    const [currentSchoolYear, setCurrentSchoolYear] = useState(null);

    gtag('event', 'page_view', {
      page_title: 'Reporte Estado',
      page_location: 'state-report',
      page_path: '/state-report',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(()=>{
        setCurrentSchoolYear(null);
        setTimeout(()=>{
            setCurrentSchoolYear(userState.schoolYear);
        }, 200);

    }, [userState.schoolYear]);

    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);
                    setStateSelectedName(userState.profile.state.state_name);

            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_state_report', {
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }

    return (<>

         {stateStatus && currentSchoolYear && currentSchoolYear.id === 3?
          <Redirect to={{pathname: '/dashboard', state: { from: props.location }}} />
        : <>

            <div className='container mb-5'>
                <div className='row '>
                    <div className="col-12 ml-auto" onClick={(e) => {downloadReport(e);}}>
                   
                        <ReactToPrint
                          trigger={() => <Button style={{
                              float: 'right',
                              marginBottom: '1rem',
                              padding: '1rem 2rem'
                          }}>Imprimir</Button>}
                          content={() => componentRef}
                        />

                    </div>
                </div>
            </div>

            <div ref={(el) => (componentRef = el)}>
                <div className='container mb-5'>

                    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                    {userState.profile && userState.profile.role &&
                        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                        
                            <div className='row '>
                                <div className="col-14 ml-auto">
                                    <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                                      <option value="">Estado (Todos)</option>
                                      {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        : null
                    }
                </div >

                {currentSchoolYear && <div>

                    <CardBasic title="">

                        <TitleSection stateId={stateSelectedId} stateName={stateSelectedName} currentSchoolYear={currentSchoolYear} />

                        <SummarySection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                    </CardBasic>

                    <div className="pagebreak" />

                    <CardBasic title="">
                    
                        <GoalsSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                        <ResultadosSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                        
                    </CardBasic>

                    <div className="pagebreak" />

                    <CardBasic title="">

                        <IDAI2Section stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                    </CardBasic>

                    <div className="pagebreak" />

                     <CardBasic title="">

                        <AplicacionPorGrupoSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                    </CardBasic>

                    <div className="pagebreak" />

                    <CardBasic title="">

                        <ICESection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                    </CardBasic>

                    <div className="pagebreak" />


                    {currentSchoolYear.id === 2 && <CardBasic title="Trayecto Formativo">

                        <TrayectoSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />

                        <FormacionDocenteGraphSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                    </CardBasic>}

                    <div className="pagebreak" />
                    
                </div>}

            </div>

        </>}

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default StateSummaryReport;
