export const CLICK_OPEN_MENU = 'CLICK_OPEN_MENU';
export const CLICK_OPEN_SURVEY = 'CLICK_OPEN_SURVEY';
export const RESET_SURVEY = 'RESET_SURVEY';


export const clickMenuOpen = value => ({
    type: CLICK_OPEN_MENU,
    newValue: value
});

export const clickSurveyOpen = value => ({
    type: CLICK_OPEN_SURVEY,
    newValue: value
});

export const resetSurvey = value => ({
    type: RESET_SURVEY,
    newValue: false
});


