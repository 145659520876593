import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from "react-fullscreen-loading";
import Swal from 'sweetalert2';
import { gtag } from 'ga-gtag';
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import Topbar from '../../components/public/Topbar';
import LevelSelectorModal from '../../components/modals/LevelSelectorModal';
import ZoneSelectorModal from '../../components/modals/ZoneSelectorModal';
import sketch4 from'../../assets/unesco/img/sketch4.png';
import auth from '../../services/users/AuthService'
import {userLogin, userAuth} from '../../services/users/ActionTypes'
import $ from 'jquery';
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";

const registerForm = FormBuilder.group({
  // role: [6, [Validators.required]],
  // grade: ['', [Validators.required]],
  firstName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  secondLastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  curp: ["", [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]],
  email: ["", [Validators.required, Validators.email]],
  position: ["", Validators.required],
  sex: ["", Validators.required],
  phonePersonal: ["", [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)]],
  phoneOffice: ["", [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)]],
  password: ["", Validators.required],
  confirm_password: ["", Validators.required],
});



const SignUp = (props) => {

  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  React.useEffect(()=>{
    setTimeout(()=>{
      $('.rdl-control').removeAttr('multiple');      
    }, 500)
    // getRoles();
  }, []);

  const handleReset = () => {
    registerForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (registerForm.value.password !== registerForm.value.confirm_password) {
      registerForm.controls.confirm_password.setErrors({ invalid: true });
    } else {
      registerForm.controls.confirm_password.setErrors(null);
    }

    if (registerForm.valid) {
      $('#modalZoneSelector').modal({show: true});
    }

  }

  const getRoles = (e) => {
    auth.getRoles().then(result => {
      setRoles(result);
    }).catch(error => {
      console.log('err', error);
    });
  }

  const someCallback = (idZone) => {
    $('#modalZoneSelector').modal('toggle');

    const data = {
      role: 6, // registerForm.value.role,
      // grade: registerForm.value.grade,
      firstName: registerForm.value.firstName.trim(),
      lastName: registerForm.value.lastName.trim(),
      secondLastName: registerForm.value.secondLastName.trim(),
      email: registerForm.value.email.trim(),
      password: registerForm.value.password.trim(),
      curp: registerForm.value.curp.trim(),
      position: registerForm.value.position.trim(),
      sex: registerForm.value.sex.trim(),
      phone: registerForm.value.phonePersonal.trim().replace(/\D/g, ''),
      phonePersonal: registerForm.value.phonePersonal.trim().replace(/\D/g, ''),
      phoneOffice: registerForm.value.phoneOffice.trim().replace(/\D/g, ''),
      idZone: idZone
    }

    setIsLoading(true);
    auth.createUser(data).then(result => {
      // setRoles(result);
      setIsLoading(false);

      dispatch(userAuth(result.user));
      gtag('event', 'login', { 
        'success': 'true',
        'username': result.user.username
      });

      console.log('result.user', result.user);

      let { from } = location.state || { from: { pathname: "/profiles" } };
      history.push(from.pathname);


    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);

      let message = "Ocurrió un error al crear el usuario.";
      try{
        message = error.response.data.message;
      }catch(e){}

      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: message,
          footer: 'Por favor revise los datos ingresados.'
      });
    });

  }

  const onInput = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/i, "");
  }

  const onInput2 = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  }

  
  const isNumericInput = event => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
      event.preventDefault();
    }
  };

  const formatToPhone = (event) => {
    if(isModifierKey(event)) {return;}

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    if(input.length > 6){target.value = `(${zip})${middle}-${last}`;}
    else if(input.length > 3){target.value = `(${zip})${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
  };

  return (<>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    <Topbar />

    <ZoneSelectorModal childToParent={someCallback} />

    <header className="py-3 py-md-4" style={{backgroundImage: 'none'}}>
    </header>
    <div className="container mb-11">
      <div className="row">
        <div className="col-lg-5 d-none d-lg-block p-5">
          <div className="h-100" style={{backgroundImage: `url(${sketch4})`, backgroundSize: 'cover'}} />
        </div>
        <div className="col-lg-7">
          <div className="p-5">
            <div className="text-center">
              <h1 className="text-gray-900 mb-4">Bienvenido</h1>
            </div>
            <FieldGroup
              control={registerForm}
              render={({ get, invalid }) => (
                <form className="user" onSubmit={handleSubmit}>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <FieldControl
                        name="firstName"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" {...handler()} 
                            onInput={onInput}
                            className="form-control" placeholder="Nombre" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Nombre(s) obligatorio(s)'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Nombre(s) inválido(s)'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Nombre(s)" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <FieldControl
                        name="sex"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} className="form-control">
                              <option value="" disabled>Sexo</option>
                              <option value="masculino">Masculino</option>
                              <option value="femenino">Femenino</option>
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Sexo obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Sexo" }}
                      />

                      {/*
                      <FieldControl
                        name="role"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} className="form-control">
                            <option value="6">Supervisor/ATP Zona</option>
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Cargo obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Cargo" }}
                      />
                      */}
                    </div>
                    {/*
                    <div className="col-sm-3 mb-3 mb-sm-0">
                      <FieldControl
                        name="grade"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} className="form-control">
                              <option value="" disabled>Grado</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Grado obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Grado" }}
                      />
                    </div>
                    */}
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <FieldControl
                        name="lastName"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Paterno" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Apellido obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Apellido inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Apellido Paterno" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <FieldControl
                        name="secondLastName"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Materno" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Apellido obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Apellido inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Apellido Materno" }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12 mb-3 mb-sm-0">
                      <FieldControl
                        name="curp"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" maxLength="18" {...handler()} onInput={onInput2} className="form-control" placeholder="CURP" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'CURP obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'CURP inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "CURP" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-12 mb-6 mb-sm-0">
                      <FieldControl
                        name="position"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" maxLength="100" {...handler()} onInput={onInput2} className="form-control" placeholder="Supervisor, ATP, otro.." />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Función obligatoria'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Función inválida'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Función" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <FieldControl
                        name="phonePersonal"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" {...handler()} maxLength="13" onKeyDown={enforceFormat} onKeyUp={formatToPhone} className="form-control" placeholder="Celular" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Teléfono obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Teléfono inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Celular" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <FieldControl
                        name="phoneOffice"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="text" {...handler()} maxLength="13" onKeyDown={enforceFormat} onKeyUp={formatToPhone} className="form-control" placeholder="Teléfono" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Teléfono obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('pattern') && 'Teléfono inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Teléfono de Oficina" }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <FieldControl
                      name="email"
                      render={({ handler, touched, hasError }) => (
                        <div>
                          <input type="email" {...handler()} 
                          onInput={e => e.target.value = ("" + e.target.value).toUpperCase()}
                          className="form-control" placeholder="Correo Institucional" />
                          <span className="errorAlert pt-3">
                            {touched && hasError('required') && 'Correo obligatorio'}
                          </span>
                          <span className="errorAlert pt-3">
                            {touched && hasError('email') && 'Correo Inválido'}
                          </span>
                        </div>
                      )}
                      meta={{ label: "Correo Institucional" }}
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <FieldControl
                        name="password"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="password" {...handler()} className="form-control" placeholder="Contraseña" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Contraseña obligatoria'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Contraseña" }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <FieldControl
                        name="confirm_password"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="password" {...handler()} className="form-control" placeholder="Confirma Contraseña" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Confirmación obligatoria'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('invalid') && 'La contraseña no coincide'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Confirma Contraseña" }}
                      />
                    </div>
                  </div>
                  <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                    REGISTRAR
                  </button>
                  {/*
                    <hr/>
                    <a href="index.html" className="btn btn-google btn-user btn-block">
                      <i className="fab fa-google fa-fw"></i> Register with Google
                    </a>
                    <a href="index.html" className="btn btn-facebook btn-user btn-block">
                      <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                    </a>
                  */}
                </form>
                )}
                />
                <hr/>
                <div className="text-center">
                  <Link className="small" to="/login">¿Ya tienes una cuenta? ¡Entra aquí!</Link>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )

}

export default SignUp;
