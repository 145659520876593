import React from 'react';
import Loading from '../../../../components/Loading';
import Table from '../../../../components/Charts/Table';
import Bar from '../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';

const ICESection = ({schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        <div className="pagebreak" />

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center mt-5">
            <div className="col-12 mt-5">

                <div className="container mt-5">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>V. Instrumento de convenciones de la escritura/Hess-Mora (ICE) (Tercer grado de primaria).</b> Es un instrumento que apoya a
                                    los docentes a reconocer, en las y los niños alfabetizados, qué es lo que saben sobre las convenciones básicas de la lengua
                                    escrita. Se aplica a todos los estudiantes en tres momentos: <b>inicial, intermedio y final</b>, con el propósito de identificar el avance de
                                    su grupo.
                                </p>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Aplicación del Instrumento de convenciones de la escritura/Hess-Mora (ICE) por momento de aplicación</b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: 1 },
                                        {grado: 3}
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado', priority: 1, minWidth: '10px'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formA', label: 'Forma A', minWidth: '10px', priority: 4, aggregation: 'sum', custom: "IF(formId = 22, 1, 0)"},
                                        {name: 'formB', label: 'Forma B', minWidth: '10px', priority: 5, aggregation: 'sum', custom: "IF(formId = 23, 1, 0)"},
                                        {name: 'formC', label: 'Forma C', minWidth: '10px', priority: 6, aggregation: 'sum', custom: "IF(formId = 24, 1, 0)"},
                                        {name: 'total', label: 'Total estudiantes por grado', aggregation: 'count', custom: 'DISTINCT(id)', priority: 2},
                                    ],
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de estudiantes, por momento, con resultados del ICE</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'convenciones'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: 1 },
                                        {grado: 3},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: ''}
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Forma A', aggregation: 'porc', priority: 3, minWidth: '150px', color: '#1665C0'},
                                        {name: 'intermedio', label: 'Forma B', aggregation: 'porc', priority: 4, color: '#F28E3C'},
                                        {name: 'final', label: 'Forma C', aggregation: 'porc', priority: 5, color: '#999999'},
                                    ],
                                    customMetrics: [],                                    
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del ICE</b></p>
                            </span>

                            <HorizontalBar2
                                title="Tercer Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id },
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 16},
                                            {formId: 17},
                                            {formId: 18},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#0e72f1', 
                                        '2': '#4cb7cd', 
                                        '3': '#ec4497', 
                                        '4': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="pagebreak" />
                    
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>** Niveles de adquisición de convencionalidades de la lengua escrita</b> según el instrumento Hess-Mora.<br></br>
                                    El instrumento valora el nivel de dominio en las siguientes convencionalidades de la escritura: uso de rr, qu, gue y gui, ñ, ch, ll, 
                                    escritura de sílabas trabadas y diptongos, segmentación de palabras, uso pertinente de mayúsculas y minúsculas y rasgos básicos de segmentación 
                                    como la coma y el punto. De acuerdo al número de aciertos que presentan las y los estudiantes en su producción escrita es posible identificar 4 niveles de desempeño. 
                                    Cada nivel da cuenta del avance progresivo que tienen las y los estudiantes en el dominio general de convencionalidades.
                                </p>
                                <br></br>
                                <p>
                                    <b>Nivel 1</b> (puntuación 0-10). La persona tiene experiencia <b>inicial</b> con el uso de convencionalidades en la escritura.<br></br>
                                    <b>Nivel 2</b> (puntuación 11-22). La persona tiene experiencia <b>básica</b> con el uso de convencionalidades en la escritura.<br></br>
                                    <b>Nivel 3</b> (puntuación 23-33). La persona tiene experiencia <b>avanzada</b> con el uso de convencionalidades en la escritura.<br></br>
                                    <b>Nivel 4</b> (puntuación 34-44). La persona tiene experiencia <b>profesional</b> con el uso de convencionalidades en la escritura.<br></br>
                                </p>
                                <br></br>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados de la aplicación del ICE. Estudiantes por docente y grado</b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: 1 },
                                        {grado: 3},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado', minWidth: '60px', priority: 1},
                                        {name: 'teacherName', label: 'Nombre Profesor', minWidth: '200px', priority: 2},
                                        {name: 'studentName', label: 'Nombre del Estudiante', minWidth: '200px', priority: 3},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formA', label: 'Forma A', minWidth: '10px', priority: 4, aggregation: 'sum', custom: "IF(formId = 22, customScore, 0)"},
                                        {name: 'formB', label: 'Forma B', minWidth: '10px', priority: 5, aggregation: 'sum', custom: "IF(formId = 23, customScore, 0)"},
                                        {name: 'formC', label: 'Forma C', minWidth: '10px', priority: 6, aggregation: 'sum', custom: "IF(formId = 24, customScore, 0)"},
                                    ]
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div> }
    </div>)
}

export default ICESection;
