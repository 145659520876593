import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import SimpleTable from '../../../components/Charts/SimpleTable';
import Bar from '../../../components/Charts/Bar';
import auth from '../../../services/users/AuthService';

const PHASE = 4;

const TrayectoSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});
    const [data, setData] = useState([]);
    const [totalTeachers, setTotalTeachers] = useState([]);
    const [totalTeachersWA, setTotalTeachersWA] = useState([]);

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            getMoodleProgress(stateId);
        }, 100);
    };

    const getMoodleProgress = async(stateId) => {
        auth.getTrayectoMoodle(stateId, currentSchoolYear.id, PHASE).then(result => {

            const result1 = groupBy(result, 'stateName');
            console.log('result1', result1);

            let totals = 0;
            let totalsWA = 0;
            const theData = Object.keys(result1).map((key) => {

                console.log('result1[key]', result1[key]);


                const obj = { "Estado": key };
                let average = 0;

                result1[key].forEach((value, index) => {
                    obj["Módulo " + value.moduleId] = value.avance;
                    average += Number(value.avance);
                });

                obj["Promedio Final"] = Number(average/result1[key].length).toFixed(2);

                totals += Number(result1[key][0]['teachers']);
                totalsWA += Number(result1[key][0]['teachersExistsInWhatsapp']);

                return obj;
            });

            console.log('theData', theData);

            setData(theData);
            setTotalTeachers(totals);
            setTotalTeachersWA(totalsWA);

            setIsLoading(false);

        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });
    }

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && <div className="row align-items-center justify-content-center text-center">
            
            <div className="col-12">

                <div className="container">

                    <div className="table-responsive">

                        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                        {stateStatus && data && data[0] && <SimpleTable
                            title='Trayecto'
                            columns={Object.keys(data[0]).map(key => {
                                return {
                                    name: key,
                                    selector: key
                                }
                            })}
                            data={data}
                        />}

                    </div>

                    {/*
                    <div>
                        <p>Para calcular el porcentaje del módulo 1 se consideran {totalTeachersWA} docentes 
                        que están en Moodle y  que se identificaron en la base de WA con base en
                        su RFC. Del módulo 2 en adelante, se consideran {totalTeachers} docentes 
                        que se identificaron en  la base de Moodle con base en correo electrónico. 
                        </p>
                    </div>

                    */}

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default TrayectoSection;
