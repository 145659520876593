import React from 'react';
import { gtag } from 'ga-gtag';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Loading from '../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';
import {userLogin} from '../../services/users/ActionTypes';

const ListSections = (props) => {

    let profile = useSelector(state => state.userState.profile);
    let dispatch = useDispatch();

    gtag('event', 'page_view', {
      page_title: 'Avance por Sección',
      page_location: 'avance-section',
      page_path: '/avance-section',
      'username': profile && profile.user? profile.user.username : ''
    });

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [showSubHeader, setShowSubHeader] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [perPage, setPerPage] = React.useState(20);

    React.useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
    }, []);

    const filteredItems = data.filter(
        item => item.sectors_name && item.sectors_name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <input id="search" autoFocus type="text" placeholder="Filtrar por Sector" value={filterText} onChange={onFilter} />
        <button type="button" onClick={onClear} className="btn-primary">X</button>
      </>
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
            Roles.ROLE_REGIONAL_SUPERVISOR,
            Roles.ROLE_SECTOR_SUPERVISOR,
            Roles.ROLE_ZONE_SUPERVISOR,
        ].includes(profile.role.id) ){
            return (
                <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            );
        }

        return null;

        
    }, [filterText, resetPaginationToggle]);

    const fetchUsers = async (page) => {
        setLoading(true);

        auth.getSectorsByState(profile.state.state_id, page, perPage).then(response => {
            setData(response.data);
            setTotalRows(Number(response.total));
            setLoading(false);
            setIsLoading(false);

        }).catch(error => {
            console.log('err', error);
            setLoading(false);
            setIsLoading(false);
        });
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        auth.getSectorsByState(profile.state.state_id, page, newPerPage).then(response => {
            setData(response.data);
            setPerPage(newPerPage);
            setLoading(false);
            setIsLoading(false);

        }).catch(error => {
            console.log('err', error);
            setLoading(false);
            setIsLoading(false);
        });

    };

    React.useEffect(() => {
        if (profile.user) {
            if([Roles.ROLE_SUPER_ADMIN, 
                Roles.ROLE_ADMIN,
                Roles.ROLE_ESTATAL,
                Roles.ROLE_REGIONAL_SUPERVISOR,
                Roles.ROLE_SECTOR_SUPERVISOR,
                Roles.ROLE_ZONE_SUPERVISOR,
            ].includes(profile.role.id) ){
                setShowSubHeader(true);
            }
        }

    }, []);

    const LinkFicha = (row) => (
        <div style={{ 
            textAlign: "left",
            width: "100%" 
          }}>
            <Link to={{ pathname: `/avance-sections/${row.sectors_id}` }}> Ver Ficha
            </Link>
        </div>
    );

    return (<>

        {profile && profile.state &&

            <div className='container mb-5 mt-5'>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Estado: </b>
                            {profile.state.state_name}
                        </p>
                    </div>
                </div>
            </div>
        }

        <CardBasic title={props.title}>

            <div className="table-responsive">

                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                <DataTable
                  title=""
                  fixedHeader
                  fixedHeaderScrollHeight="500px"
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  columns={[{
                    name: 'Región',
                    selector: 'region_name',
                  },{
                    name: 'Sector',
                    selector: 'sectors_name',
                  },{
                    name: '# Zonas',
                    selector: 'zones',
                  },{
                    name: "Ficha",
                    selector: row => row["sectors_id"],
                    sortable: true,
                    width: "200px",
                    cell: row => <LinkFicha {...row} />,
                  }]}
                  data={filteredItems}
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader={showSubHeader}
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
            </div>
        </CardBasic>
    </>)
}

const paginationOptions = {
    rowsPerPageText: 'Filas Por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true, 
    selectAllRowsItemText: "Todos"
}
const customStyles = {
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
            backgroundColor: '#E0E0E0',
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
    headRow: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
};


export default ListSections;
