import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import SimpleTable from '../../components/Charts/SimpleTable';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import Loading from '../../components/Loading';

const Indice = (props) => {

    let userState = useSelector(state => state.userState);
    const schoolId = props.match.params.id;

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState(null);

    gtag('event', 'page_view', {
      page_title: 'Índice Escuela',
      page_location: 'school-indice',
      page_path: '/school-indice',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(()=>{
        if(schoolId){
            setIsLoading(true);
            auth.getImplementationIndexDetails(schoolId).then(result => {
                setData(result);
                setIsLoading(false);
            }).catch(error => {
                console.log('err', error);
                setIsLoading(false);
            });
        }
    }, [props.match.params.id]);

    

    return (<div className="container mb-5">

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-11">

                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                <div className="container">
                    <div className="row align-items-center text-center mb-3 mt-3">
                        <div className="col-12">
                            <h4>
                                <b>Cálculo del Índice de Implementación por Escuela</b><br/>
                            </h4>
                            {data && data.school && <h6>
                                <p className="mb-0 mt-0">{data.school.school_cct} - {data.school.school_name}</p>
                                <p className="mb-0 mt-0">IEI: <b>{Number(data.value)*100}%</b></p>
                            </h6>}
                        </div>
                    </div>
                </div>

                {data && data.trayecto && data.evaluacion && <div className="container">
                    <div className="row align-items-center justify-content-center text-center mb-3 mt-3">
                        <div className="col-8">

                            <table className="table responsive-table">
                                <tbody>
                                    <tr>
                                        <th style={styles.th}>Componente</th>
                                        <th style={styles.th}>Porcentaje</th>
                                        <th style={styles.th}>Obtenido</th>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Puntaje por componente de formación docente</td>
                                        <td style={styles.td}>{30}%</td>
                                        <td style={styles.td}><b>{Number(data.trayecto.porc) * 100}%</b></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Puntaje de componente de registro de evaluaciones</td>
                                        <td style={styles.td}>{30}%</td>
                                        <td style={styles.td}><b>{Number(data.evaluacion.porc) * 100}%</b></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Puntaje por cuestionario de implementación</td>
                                        <td style={styles.td}>{30}%</td>
                                        <td style={styles.td}><b>{data.cuestionario * 100}%</b></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Asistencia director a su trayecto formativo</td>
                                        <td style={styles.td}>{10}%</td>
                                        <td style={styles.td}><b>{data.asistencia * 100}%</b></td>
                                    </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>}

                {data && data.trayecto && <CardBasic title="">
                    <SimpleTable
                        title=''
                        fulltitle='Detalle - Trayecto Formativo'
                        columns={[{
                            name: 'Docente',
                            selector: 'teacherName',
                        },{
                            name: 'Grado',
                            selector: 'teacherGrade',
                        },{
                            name: '% Avance',
                            selector: 'avance', 
                        }]}
                        data={data.trayecto.teachers}
                    />
                </CardBasic>}

                <div className="mb-3 mt-3">
                    {data && data.evaluacion && <CardBasic title="">
                        <SimpleTable
                            fulltitle='Detalle - Evaluaciones IDAI/ICE'
                            columns={[{
                                name: 'Docente',
                                selector: 'teacherName',
                            },{
                                name: 'Grado',
                                selector: 'teacherGrade',
                            },{
                                name: 'Num. Estudiantes',
                                selector: 'students', 
                            },{
                                name: 'Num. Capturas',
                                selector: 'respuestas', 
                            }]}
                            data={data.evaluacion.teachers}
                        />
                    </CardBasic>}
                </div>

                <div className="mb-3 mt-3">
                    {data && data.evaluacion && <CardBasic title="">
                        <SimpleTable
                            fulltitle='Detalle - Cuestionario'
                            columns={[{
                                name: 'Pregunta',
                                selector: 'questionText',
                            },{
                                name: 'Respuesta',
                                selector: 'optionText', 
                            },{
                                name: 'Puntos',
                                selector: 'value',
                            }]}
                            data={data.cuestionarioDetalle}
                        />
                    </CardBasic>}
                </div>

            </div>
        </div>
    </div>)
}

const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '1rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '1rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}


export default Indice;
