import React from 'react';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import {columns, saveInfo} from '../../components/CsvImporter/configs/schools.columns.js';

const SchoolsImporter = (props) => {

  return (<>
    <CardBasic title="Importar Escuelas">
      <CsvImporter columns={columns} saveInfo={saveInfo} />
    </CardBasic>
  </>);

}

export default SchoolsImporter;