import { CLICK_OPEN_MENU } from "./ActionTypes";
import { CLICK_OPEN_SURVEY } from "./ActionTypes";
import { RESET_SURVEY } from "./ActionTypes";

const initialState = {
    menuOpen: false,
    surveyOpen: false,
}

export const MenuOpenReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case CLICK_OPEN_MENU:
            return {
                ...state,
                menuOpen: !state.menuOpen
            };
        case CLICK_OPEN_SURVEY:
            return {
                ...state,
                surveyOpen: true
            };
        case RESET_SURVEY:
            return {
                ...state,
                surveyOpen: false
            }; 
        default:
            return state;
    }
};