import React from 'react';
import Loading from '../../Loading';
import {
  Validators,
  FormGenerator
} from "react-reactive-form";
import SelectBox from "../render-components/SelectBox";

let myForm;
let oldValues = {};

const Second = (props) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [rawFields, setRawFields] = React.useState([]);

    React.useEffect(()=>{
        setIsLoading(true);
        setRawFields((props.raw && props.raw.meta && props.raw.meta.fields && 
            props.raw.meta.fields.map(field => ({
                value: field,
                label: field.toUpperCase(),
                disabled: false,  
            }))) || []
        );
        setIsLoading(false);
    }, [props.raw]);

    React.useEffect(()=>{
        setIsLoading(true);

        props.columns.filter(column => column.editable === true).forEach(column => {
            const index = rawFields.map(field => field.label).indexOf(column.text.toUpperCase());
            if(index > -1){

                console.log('column.dataField', column.dataField);
                console.log('myForm', myForm)

                const control = myForm.get(column.dataField);

                console.log('control', control);

                console.log('rawFields[index]', rawFields[index]);


                control.setValue(rawFields[index].value);
                rawFields[index].disabled = true;
                control.stateChanges.next();
            }
        });

        setIsLoading(false);

    }, [rawFields, props.columns]);

    const setForm = form => {
        myForm = form;

        // Set the meta data
        myForm.meta = {
          handleReset: handleReset,
          handleSubmit: handleSubmit,
        };

        Object.entries(myForm.controls).forEach(value => {
          const [key, control] = value;
          control.valueChanges.subscribe(
            (value) => {
              if(oldValues[key] !== value){
                  disableNewValue(oldValues[key], value)
                  oldValues[key] = value;                  
              }
            },
          );
        });
    };

    const disableNewValue = (oldValue, newValue) => {
        if(oldValue){
            const index1 = rawFields.map(field => field.label).indexOf(oldValue.toUpperCase());
            if(index1 > -1){
                rawFields[index1].disabled = false;
            }
        }

        if(newValue){
            const index2 = rawFields.map(field => field.label).indexOf(newValue.toUpperCase());
            if(index2 > -1){
                rawFields[index2].disabled = true;
            }
        }
    }

    const handleReset = () => {
        myForm.reset();
    };

    const handleSubmit = e => {
        e.preventDefault();
        const theRows = props.raw && props.raw.data && 
        props.raw.data.map(row => {
            return Object.fromEntries(
                Object.entries(myForm.value)
                .map(([ key, val ]) => [ key, row[val]? row[val].trim() : '' ])
            )
        });
        props.setRows(theRows.map((row, index) => { return {
            ...row,
            KEY: index,
            errors: {},
            actions: '',
            messages: '',
        }}));
        props.nextStep();
    };

    

    return (<>
        <div className='container mb-5 mt-5'>
            <div className='row justify-content-center pb-5'>
                <div className="col-12">
                    <h3 className='mb-3'>Emparejar Columnas</h3>
                    <h5>Favor de seleccionar a que columna corresponden sus datos</h5>
                </div>
            </div>
        </div>
        <div className='row justify-content-center pb-5'>
            <div className="col-10">
                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
                
                <table className="table table-match">
                    <tbody>
                    <FormGenerator onMount={setForm} fieldConfig={{
                        controls: {
                            ...Object.fromEntries( new Map(
                                props.columns.filter(column => column.editable === true)
                                .map(column => {
                                    return [column.dataField, {
                                        render: SelectBox,
                                        options: {
                                            validators: Validators.required
                                        },
                                        meta: {
                                            label: column.text,
                                            name: column.dataField,
                                            options: rawFields || [],
                                            value: '',
                                        },
                                    }];
                                }),
                            )),
                            $field_0: {
                                isStatic: false,
                                render: ({ invalid, meta: { handleReset, handleSubmit } }) => (
                                    <tr>
                                        <td colSpan="4">
                                            <button type="submit" disabled={invalid} onClick={handleSubmit} className="btn btn-block btn-primary mb-5 lift">
                                              Siguiente
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }
                        }
                    }} />
                    </tbody>
                </table>

            </div>
        </div>     
    </>);
};

export default Second;