import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import Loading from '../../components/Loading';
import MoodleCsvImporter from '../../components/MoodleCsvImporter';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import NumberTypeRender from '../../components/CsvImporter/render-components/NumberTypeRender';
import DecimalTypeRender from '../../components/CsvImporter/render-components/DecimalTypeRender';

const MoodleImporter = (props) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    auth.getMoodleModules().then(modules => {
      createColumns(modules);

    }).catch(error => {
        console.log('err', error);
        setIsLoading(false);
    });
  }, []);

  const createColumns = (modules) => {
    const errorsStyle = (cell, row, rowIndex, colIndex) => {
      if(row.errors[cols[colIndex].dataField] && cols[colIndex].customType !== 'number'){
        return {
          backgroundColor: '#ED3833'
        };
      }
    }

    const cols = [{
      dataField: 'state',
      text: 'Estado',
      description:'Estado',
      editable: true,
      required: true,
      editor: {
        type: Type.SELECT,
        async: true,
        options: []
      },
      loadOptions: () => {
        return new Promise((resolve, reject) => {
          auth.getStates().then(states => {
            resolve(states.map(state => {
              return {
                  id: state.id,
                  value: state.name,
                  label: state.name
              }
            }))
          }).catch(error => {
            resolve([]);
          });
        })
      },
      style: errorsStyle
    },{
      dataField: 'grade',
      text: 'Grado',
      description:'Grado',
      editable: true,
      required: true,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle,
      order: 2,
    },{
      dataField: 'name',
      text: 'Nombre',
      description:'Nombre del Docente',
      editable: true,
      required: true,
      style: errorsStyle,
      order: 3,
    },{
      dataField: 'curprfc',
      text: 'CURP/RFC',
      description:'CURP o RFC del Docente',
      editable: true,
      required: false,
      style: errorsStyle,
      order: 4,
    },{
      dataField: 'email',
      text: 'Dirección Email',
      description:'Dirección Email',
      editable: true,
      required: true,
      style: errorsStyle,
      order: 5,
    }/*

    ,{
      dataField: 'existsInWhatsapp',
      text: 'Whatsapp.',
      description:'¿Existe en Whatsapp?',
      editable: true,
      required: true,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle,
      order: 6,
    },{
      dataField: 'existsInLSE',
      text: 'LSE.',
      description:'¿Existe en LSE?',
      editable: true,
      required: true,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle,
      order: 6,
    }*/];
    
    modules.forEach((m, i) => {
      cols.push({
        dataField: "_"+String(m.id),
        text: m.name,
        description: m.name,
        editable: true,
        required: true,
        customType: 'number',
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <DecimalTypeRender { ...editorProps } value={ value } />
        ),
        style: errorsStyle,
        order: (6+i),
      });
    });

    cols.push({
      dataField: 'actions',
      text: 'Acciones',
      editable: false,
      required: false,
      actions: true,
    },{
      dataField: 'messages',
      text: 'Mensajes',
      editable: false,
      required: false,
      actions: true,
    });

    setColumns(cols);
    setIsLoading(false);
  }

  const saveInfo = (data) => auth.registerProgressMoodle(data);

  return (<>
    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {columns && columns.length !== 0 ?
      <CardBasic title="Importar Moodle">
        <MoodleCsvImporter columns={columns} saveInfo={saveInfo} />
      </CardBasic>
    : null}
  </>);

}



export default MoodleImporter;