import React from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { gtag } from 'ga-gtag';
import {
    FormGenerator,
    Validators,
} from "react-reactive-form";
import { clickSurveyOpen } from '../../../services/menu/ActionTypes';
import Loading from '../../Loading';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';
import $ from 'jquery';

let myForm;
const formId = 36;

const SurveyModal = ({ props }) => {
  let dispatch = useDispatch();

  let user = useSelector(state => state.userState.profile);
  let location = useLocation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [columns, setColumns] = React.useState([]);
  const [summary, setSummary] = React.useState(null);

  const handleClickSurveyOpen = (e) => {
    dispatch(clickSurveyOpen());
  }

  React.useEffect(()=>{
        setIsLoading(true);
        auth.getInstrumentByFormId(user.id, formId, '').then(result => {
            setTitle(result.instrument.name);
            setSummary(result.summary);
            if(result.summary){
              handleClickSurveyOpen(null);
            }

            try {
                setColumns(result.instrument.forms[0].questions);
            } catch(e) {
                console.log('e', e);
            }
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            if(columns){
                columns.forEach(column => {
                    if( myForm.controls[column.id]){
                        myForm.controls[column.id].setValue(column.optionSelected);
                        myForm.controls[column.id].patchValue(column.optionSelected);
                    }
                });
            }
        }, 500);

    }, [columns]);

    const handleReset = () => {
        // myForm.reset();
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        const responses = Object.keys(myForm.value).map(key => {
            return {
                questionId: key,
                optionId: myForm.value[key],
                singleResponseId: columns.filter(column => column.id === Number(key))[0].singleResponsesId
            }
        });

        const obj = {
            responses,
            userId: user.id,
            formId: formId
        }

        await auth.saveImplementationInstrumentForm(obj).then(result => {
            setColumns(result.forms[0].questions);
            handleClickSurveyOpen(null);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });
    };

    const setForm = form => {
        myForm = form;

        // Set the meta data
        myForm.meta = {
          handleReset: handleReset,
          handleSubmit: handleSubmit,
        };
    };

  const OptionsInput = ({ handler, touched, hasError, meta }) => (
    <div className="form-group row">
        <div className="col-12 mb-5 mt-5 mb-sm-0">
            <label>{meta.label}</label>
            <select {...handler()} id={meta.id}  disabled={meta.isDisabled}  className="form-control form-control-user mt-3">
                <option value=''>Seleccione una respuesta</option>
                { meta.options.map(option =>
                    <option key={option.id} value={option.id}>{option.optionText}</option> )
                }
            </select>
            <span >
                {touched
                && hasError("required")
                && `Se requiere una respuesta.`}
            </span>
        </div>
    </div>
  ); 

  return (
    <div className="modal fade" id="modalSurvey" tabIndex={-1} role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="modalExampleTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">

            {summary &&
              <button onClick={handleClickSurveyOpen} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            }

            {summary &&
                <div>
                    <h6 style={{color: "gray", fontWeight: 300}}><b style={{fontWeight: "bold"}}>Respondió: </b> {summary.respondio}</h6>
                    <h6 style={{color: "gray", fontWeight: 300}}><b style={{fontWeight: "bold"}}>Fecha: </b> {summary.fecha}</h6>
                </div>
            }

            {columns && <FormGenerator onMount={setForm} fieldConfig={{
                controls: {
                    ...Object.fromEntries( new Map(
                        columns.map(column => {
                            return [column.id, {
                                render: OptionsInput,
                                options: {
                                    validators: Validators.required
                                },
                                meta: {
                                    label: column.questionText,
                                    name: column.questionText,
                                    id: column.id,
                                    isDisabled: summary? true: false,
                                    options: column.options || [],
                                    defaultValue: column.optionSelected || '',
                                },
                            }];
                        }),
                    )),
                    $field_0: {
                        isStatic: false,
                        render: ({ invalid, meta: { handleReset, handleSubmit } }) => (
                            <button type="submit" disabled={(summary? true: false) || invalid} onClick={handleSubmit} className="btn btn-block btn-primary mb-5 lift" data-dismiss="modal">
                              Guardar
                            </button>
                        )
                    }
                }
             }} /> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurveyModal;
