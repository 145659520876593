import React from 'react';
import imgGoals1 from'../../../assets/unesco/img/goals1.jpg';

const GoalsSection = ({props, stateId}) => {

    return (<div className="container mb-5">

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>Metas para el final de ciclo escolar</b>
                                </p>
                                <p>
                                    <b>Estrategia de lengua</b>
                                </p>
                            </span>
                            <div className="container">
                                <div className="row align-items-center justify-content-center text-center">
                                    <div className="col-10">
                                        <img src={imgGoals1} className="img-fluid w-100" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-left mt-5">
                        <div className="col-12">
                            <span><b>* Medido con el Instrumento diagnóstico de alfabetización inicial (IDAI)</b></span><br/>
                            <span><b>** Medido con el Instrumento de convenciones de la escritura/Hess-Mora (ICE)</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default GoalsSection;
