import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import AuthRoute from './AuthRoute';
import PrivateRoutes from './PrivateRoutes';
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpTeacher from "../pages/SignUpTeacher";
import Home from "../pages/Home";
import PrivacyNotice from "../pages/PrivacyNotice";
import ProfileSelection   from "../pages/ProfileSelection";

function Main() {

  return (<>

    <div id="root-scroll">
      <Router>
          <Switch>
            <Route exact path="/" component={Home}/> 
            <Route exact path="/website-privacy-notice" component={PrivacyNotice}/>
            <Route exact path="/login" component={SignIn}/>
            <Route exact path="/signin" component={SignIn}/>

            {/*
            <Route exact path="/supervisors/signup" component={SignUp}/>
            <Route exact path="/teachers/signup" component={SignUpTeacher}/>

            <Route exact path="/registro/supervisores" component={SignUp}/>
            <Route exact path="/registro/docentes" component={SignUpTeacher}/>
            */}
            
            <Route exact path="/profiles" component={ProfileSelection} />
            <AuthRoute path="*" component={PrivateRoutes}/>             
          </Switch>
      </Router>
    </div>
    </>);
}
export default Main

// function Login(){
//     let history = useHistory();
//     let location = useLocation();

//     let { from } = location.state || { from: { pathname: "/" } };
//     let login = () => {
//         auth.authenticate(() => {
//           history.replace(from);
//         });
//       };

//     return (
//         <div>
//             <p>Login form goes here</p>
//             <button onClick={login}>Login</button>
//         </div>
//     );
// }