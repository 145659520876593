import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Export from "react-data-table-component";
import Loading from '../../Loading';
import reports from '../../../services/reports/ReportsService';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

const CohortGraphPerc = ({props, data, title = '', plural = ''}) => {

    return (<>
        <div className="table-responsive" style={{display:'grid'}}>

            <table className="table table-bordered">
              <thead>
                <tr>
                    <th style={styles.th}></th>
                    {data.schoolYears.map((schoolYear, index) => <th style={styles.th} key={index}>{schoolYear.name}</th>)}
                </tr>
                <tr>
                    <th style={{textAlign: 'left'}}>
                        <span>Total {title}</span>
                        <br/> 
                        <span style={{color: '#666666'}}>{data.cycles.map(a => Number(a.total)).reduce((a,b) => a + b, 0)} docentes</span> 
                    </th>
                    {data.schoolYears.map((schoolYear, index) => <th style={styles.th2} key={index}>
                        {data.cycles.map(a => Number(a[('ciclo'+ schoolYear.id)])).reduce((a,b) => a + b, 0)}
                    </th>)}
                </tr>
              </thead>
              <tbody>
                {data.cycles.map((item, i) =>
                    <tr key={i}>
                        <td style={{...styles.td, ...{textAlign: 'left'}}}>
                            <span>{item.schoolYearName}</span>
                            <br/> 
                            <span style={{color: '#666666'}}>+{item.total} {plural}</span> 
                        </td>
                        {data.schoolYears.map((schoolYear, j) => 
                            <td 
                                key={j}
                                className='contentNext'
                                style={{...styles.td, ...{ 
                                    verticalAlign: 'middle', 
                                    background: `rgba(255, 153, 0, ${Number(item[('ciclo'+ schoolYear.id)])/Number(item.total)})`,
                                }}}
                            >{ (Number(item[('ciclo'+ schoolYear.id)])/Number(item.total) * 100).toFixed(2) }%</td>
                        )}
                    </tr>
                )}
              </tbody>
            </table>

        </div>
    </>)
}

const styles = {
    th: {
        padding: '0.25rem',
        background: '#EFEFEF',
        color: '#656665',
        fontWeight: 600,
        verticalAlign: 'middle'
    },
    th2: {
        fontWeight: 'bold',
        verticalAlign: 'middle',
        padding: '1rem',
    },
    td: {
        padding: '1rem',
    },

}

export default CohortGraphPerc;
