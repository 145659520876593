import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { gtag } from 'ga-gtag';

import Topbar from '../../components/public/Topbar';
import Footer from '../../components/public/Footer';

import layer1 from'../../assets/skola/img/parallax/layer-01.svg';
import layer2 from'../../assets/skola/img/parallax/layer-02.svg';
import layer3 from'../../assets/skola/img/parallax/layer-03.svg';
import layer4 from'../../assets/skola/img/parallax/layer-04.svg';
import bgRegister from'../../assets/unesco/img/bg-register-8.png';

const showMesssage = true;

class Landing extends Component {

  componentWillMount() {
    document.getElementById('body').className = 'page-top';

    gtag('event', 'page_view', {
      page_title: 'Landing',
      page_location: 'landing',
      page_path: '/'
    });
  }

  render() {

    return (<>

      <Topbar />


      {/* HERO
    ================================================== */}
        <section className="py-2 py-md-8 h-100 position-relative bg-white">
          {/* Cursor position parallax */}
          <div className="position-absolute right-0 left-0 top-0 bottom-0">
            <div className="cs-parallax">
              <div className="cs-parallax-layer" data-depth="0.1">
                <img className="img-fluid" src={layer1} alt="Layer" />
              </div>
              <div className="cs-parallax-layer" data-depth="0.2">
                <img className="img-fluid" src={layer2} alt="Layer" />
              </div>
              <div className="cs-parallax-layer" data-depth="0.2">
                <img className="img-fluid" src={layer3} alt="Layer" />
              </div>
              <div className="cs-parallax-layer" data-depth="0.4">
                <img className="img-fluid" src={layer4} alt="Layer" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-5 col-lg-7 order-md-2" data-aos="fade-in" data-aos-delay={50}>
                {/* Image */}
                <img src={bgRegister} className={"img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 " + (showMesssage? "w-100": "w-80")} alt="..." />
              </div>
              <div className="col-12 col-md-7 col-lg-5 order-md-1 px-md-0">
                <p style={{color: '#2F2D51'}} className="lead pe-md-8" data-aos="fade-up" data-aos-duration={200}>
                  Sistema de Información de
                </p>
                <h1 className="display-2 pt-6" data-aos="fade-left" data-aos-duration={150}>
                 Aprendizajes <span className="text-orange fw-bold">Fundamentales</span>
                </h1>
                {/* Buttons */}
                {/*<a href="/register" className="btn btn-wide btn-slide slide-primary shadow mb-4 mb-md-0 mr-md-5" data-aos-duration={10} data-aos="fade-up">REGISTRARSE</a>*/}
                <a href="/login" className="btn btn-primary btn-wide lift d-lg-inline-block w-80" data-aos-duration={10} data-aos="fade-up">ENTRAR</a>
                <div style={{
                  textAlign: 'center',
                  marginTop: '5rem',
                  width: '80%',
                  marginBottom: '5rem',
                  textDecoration: 'underline',
                  fontSize: '1.25rem',
                }}>
                  <a onClick={() => {gtag('event', 'download_guide', { 'guide_type': 'ICE' })}} href="/Guia_Resultados_ICE_octubre2022_VF.pdf" target="_blank">Descargar Guia Resultados ICE</a>
                  <br/>
                  <a onClick={() => {gtag('event', 'download_guide', { 'guide_type': 'IDAI' })}} href="/Guia_Resultados_IDAI_octubre2022_VF.pdf" target="_blank">Descargar Guia Resultados IDAI</a>
                </div>
              </div>
            </div> {/* / .row */}
          </div> {/* / .container */}
        </section>

        <div style={{ position: "fixed", zIndex: 999999, bottom: 20, right: 20, cursor: 'pointer' }} >
          <Link to="/website-privacy-notice" style={{color: '#77838F'}}>Aviso de privacidad</Link>
        </div>

    </>)
  }
}

export default Landing;
