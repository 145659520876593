import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

import ZonesListSection             from './../lists/ZonesListSection';
import SectorSupervisorsListSection from './../lists/figuras/SectorSupervisorsListSection';
import NewRegionModal               from '../modals/NewRegionModal';


const form = FormBuilder.group({
  name: ["", [Validators.required]],
  cct: ["", [Validators.required, Validators.pattern(/^[0-9a-zA-Z]*$/)]],
  officeName: ["", null],
  officeAddress: ["", null],
  colonia: ["", null],
  localidad: ["", null],
  region: [0, [Validators.required]],
  municipality: [0, [Validators.required]],
});

const SectorPage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const sectorId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);
    const [currentSector, setCurrentSector] = useState(null);
    const [regions, setRegions] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [regionSelected, setRegionSelected] = useState(null);


    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(() => {
        if (sectorId) {
            getSector(sectorId);
        }
    },[sectorId]);

    const getSector = (id) => {
        setIsLoading(true);
        auth.getSector(id).then(result => {
            setCurrentSector(result);

            form.controls.name.setValue(result.name);
            form.controls.cct.setValue(result.cct);
            form.controls.officeName.setValue(result.officeName);
            form.controls.officeAddress.setValue(result.officeAddress);
            form.controls.colonia.setValue(result.colonia);
            form.controls.localidad.setValue(result.localidad);

            if(result.region && result.region.state){
                setRegionSelected(result.region.id);
                getRegions(result.region.state.id, result.region? result.region.id: "");
                getMunicipios(result.region.state.id, result.municipality? result.municipality.id: "");
            }

            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getRegions = (idState, regionId) => {
        setIsLoading(true);
        auth.getRegions(idState).then(result => {
            console.log('states', result);
            setRegions(result);
            form.controls.region.setValue(regionId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getMunicipios = (stateId, municipalityId) => {
        setIsLoading(true);
        auth.getMunicipios(stateId).then(result => {
            setMunicipios(result);
            form.controls.municipality.setValue(municipalityId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const onInput = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/i, "");
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
          text: '¿Seguro que desea actualizar el sector?',
          showDenyButton: true,
          confirmButtonText: 'Sí',
          denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                save();
            }
        });
    }

    const save = () => {
        const data = {
            id: sectorId,
            name: form.value.name? form.value.name.trim() : '',
            cct: form.value.cct? form.value.cct.trim() : '',
            officeName: form.value.officeName? form.value.officeName.trim() : '',
            officeAddress: form.value.officeAddress? form.value.officeAddress.trim() : '',
            colonia: form.value.colonia? form.value.colonia.trim() : '',
            localidad: form.value.localidad? form.value.localidad.trim() : '',
            region: form.value.region,
            municipality: form.value.municipality,
        }

        setIsLoading(true);
        auth.updateSector(sectorId, data).then(result => {
          // setRoles(result);
          setIsLoading(false);
          Swal.fire('Sector actualizado con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al actualizar el sector.'
          });
        });

    }


    const EditSector = ({sector}) => {return (
        <div className="container mb-5 mt-5">
            <FieldGroup
                control={form}
                strict={false}
                render={({ get, invalid }) => (
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row mb-3">

                            <FieldControl
                                name="name"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-8">
                                        <span>Nombre</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nombre obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="cct"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>CCT</span><br/>
                                        <input type="text"  onInput={onInput} {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'CCT obligatorio'}
                                        </span>
                                        <span className="errorAlertSmall pt-3">
                                            {touched && hasError('pattern') && 'CCT inválido'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="region"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Región</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {regions && regions.map((region,i) => <option key={i} value={region.id}>{region.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Región obligatoria'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="municipality"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Municipio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {municipios && municipios.map((municipio,i) => <option key={i} value={municipio.id}>{municipio.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Municipio obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="colonia"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Colonia</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>
                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="officeName"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nombre de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="officeAddress"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Dirección de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="localidad"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Localidad</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                                GUARDAR
                            </button>
                        </div>

                    </form>
                )}
            />
        </div>
    )}

    return (<div>
        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Sector</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="sectors-tab" data-toggle="tab" href="#sectors" role="tab" aria-controls="sectors" aria-selected="false">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Zonas</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="sectors-supervisors-tab" data-toggle="tab" href="#sectors-supervisors" role="tab" aria-controls="sectors-supervisors" aria-selected="false">
                <FontAwesomeIcon icon={faPersonChalkboard} className="mr-2 tab-icon" /><span>Jefes</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">

            <CardBasic title="">
                <div className='container'>
                    <div className='row '>

                        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

                            {currentSector && <EditSector sector={currentSector} />}

                        </div>

                        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

                            <div className="container mb-5 mt-5">
                                <div className="row mb-1">
                                    <div className="col-lg-6">
                                        <p><b>Agregar</b></p>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        {currentSector && <NewRegionModal stateId={currentSector.id} />}


                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalNewRegion">
                                            <FontAwesomeIcon icon={faEarthAmericas} className="mr-3" /><b>Zona</b>
                                        </button>
                                        
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalChangePassword">
                                            <FontAwesomeIcon icon={faPersonChalkboard} className="mr-3" /><b>Jefe de Sector</b>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </CardBasic>
            </div>
          
            <div className="tab-pane fade" id="sectors" role="tabpanel" aria-labelledby="sectors-tab">
                <ZonesListSection sectorId={sectorId} />
            </div>

            <div className="tab-pane fade" id="sectors-supervisors" role="tabpanel" aria-labelledby="sectors-supervisors-tab">
                <SectorSupervisorsListSection sectorId={sectorId} />
            </div>

        </div>
      </div>)
}

export default SectorPage;
