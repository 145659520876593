import { Type } from 'react-bootstrap-table2-editor';
import auth from '../../../services/users/AuthService';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createSupervisorSector(data);          

const columns = [{
  dataField: 'state',
  text: 'Estado',
  description:'Estado',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    async: true,
    options: []
  },
  loadOptions: () => {
    return new Promise((resolve, reject) => {
      auth.getStates().then(states => {
        resolve(states.map(state => {
          return {
              id: state.id,
              value: state.name,
              label: state.name
          }
        }))
      }).catch(error => {
        resolve([]);
      });
    })
  },
  style: errorsStyle
},{
  dataField: 'region',
  text: 'Region',
  description:'Region',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'sectorName',
  text: 'Sector',
  description:'Nombre del Sector',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'sectorCCT',
  text: 'CCT del Sector',
  description:'CCT del Sector',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'position',
  text: 'Función (Supervisor, ATP, Jefe de sector, Otro)',
  description:'Función (Supervisor, ATP, Jefe de sector, Otro)',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'officeName',
  text: 'Nombre del Centro',
  description:'Nombre del Centro',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'firstName',
  text: 'Nombre',
  description:'Nombre del Supervisor',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'lastName',
  text: 'Primer Apellido',
  description:'Primer Apellido del Supervisor',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'secondLastName',
  text: 'Segundo Apellido',
  description:'Segundo Apellido del Supervisor',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'email',
  text: 'Correo Electrónico',
  description:'Correo Electrónico del Supervisor',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'curp',
  text: 'CURP',
  description:'CURP del Supervisor',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'phone',
  text: 'Teléfono Móvil',
  description:'Teléfono Móvil del Supervisor',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'actions',
  text: 'Acciones',
  editable: false,
  required: false,
  actions: true,
},{
  dataField: 'messages',
  text: 'Mensajes',
  editable: false,
  required: false,
  actions: true,
}];

export {columns, saveInfo};
