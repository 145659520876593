import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';

const ICESection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de aplicación de instrumento ICE por grado</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formaA', label: 'Forma A %', color: '#fbd54e', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 22, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaB', label: 'Forma B %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 23, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaC', label: 'Forma C %', color: '#e64b32', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 24, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de aplicación de instrumento ICE por grado y nivel</b></p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-5 mt-5">
                            <Bar
                                title="ESTATALES"
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {levelName: 'ESTATAL'},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formaA', label: 'Forma A %', color: '#fbd54e', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 22, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaB', label: 'Forma B %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 23, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaC', label: 'Forma C %', color: '#e64b32', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 24, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>

                        <div className="col-5 mt-5">
                            <Bar
                                title="FEDERALES"
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'FEDERALIZADA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formaA', label: 'Forma A %', color: '#fbd54e', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 22, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaB', label: 'Forma B %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 23, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaC', label: 'Forma C %', color: '#e64b32', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 24, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-5 mt-5">
                            <Bar
                                title="INDÍGENAS"
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {levelName: 'INDÍGENA'},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formaA', label: 'Forma A %', color: '#fbd54e', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 22, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaB', label: 'Forma B %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 23, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'formaC', label: 'Forma C %', color: '#e64b32', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 24, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>

                        <div className="col-5 mt-5">
                            <Table
                                title="Primer grado"
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'levelName', label: 'Nivel'},
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'respuestas', label: 'Respuestas', aggregation: 'none', custom: 'SUM(IF(formId= 22, 1, 0))', priority: 2},
                                        {name: 'alumnos', label: 'Total Alumnos', aggregation: 'none', custom: 'count(distinct(id))', priority: 2},
                                    ],
                                }}
                            />

                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default ICESection;
