import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtag } from 'ga-gtag';
import Loading from '../../components/Loading';
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import Swal from 'sweetalert2'
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
 } from "react-reactive-form";

import Topbar from '../../components/public/Topbar';
import auth from '../../services/users/AuthService'
import {userLogin} from '../../services/users/ActionTypes';

const loginForm = FormBuilder.group({
    username: ["", [Validators.required]],
    password: ["", Validators.required],
    rememberMe: false
});

const PrivacyNotice = (props) => {

  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(()=>{

  }, []);

  const handleReset = () => {
    loginForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loginForm.valid) {
      setIsLoading(true);
      auth.login(loginForm.value).then(result => {
        setIsLoading(false);
        dispatch(userLogin(result.user));
        gtag('event', 'login', { 
          'success': 'true',
          'username': loginForm.value.username
        });

        let { from } = location.state || { from: { pathname: "/" } };
        history.push(from.pathname);
        

      }).catch(error => {
        console.log('err', error);

        gtag('event', 'login_incorrect', { 
          'success': 'false',
          'username': loginForm.value.username,
          'code': error.response? error.response.status: '',
        });

        setIsLoading(false);

        if(error.response && error.response.status === 404){

          Swal.fire({
            title: 'Usuario no encontrado',
            text: 'Si eres supervisor o ATP ¿Deseas registrarte?',
            showCancelButton: true,
            confirmButtonText: '¡Sí! Registrar',
            cancelButtonText: `Regresar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Swal.fire('Saved!', '', 'success')
            } else if (result.isDenied) {
              // Swal.fire('Changes are not saved', '', 'info')
            }

            if (result.isConfirmed) {
              let { from } = location.state || { from: { pathname: "/supervisors/signup" } };
              history.push(from.pathname);
            }


          })

        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Contraseña incorrecta',
          });

        }
        
      });

    }
  }

  return(<>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    <Topbar />

    <div className='container mb-5 mt-5'>
      <div className='row justify-content-center pb-5'>
          <div className="col-10">

            <header className="py-4 py-md-6" style={{backgroundImage: 'none'}}>
              <div className="container text-center py-xl-2">
                <h1 className="display-4 fw-semi-bold mb-0">Aviso de privacidad del Sistema de Información de Aprendizajes Fundamentales</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-scroll justify-content-center">
                    <li className="breadcrumb-item">
                      <div className="text-gray-800">https://siaprendizajesf.org/</div>
                    </li>
                  </ol>
                </nav>
              </div>
              {/* Img */}
              <img className="d-none img-fluid" src="..." alt="..." />
            </header>

            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                El equipo de Educación de UNESCO México se compromete a proteger los datos
                personales del personal educativo y de los menores de edad participantes
                en la Política de Aprendizajes Fundamentales tratados en el Sistema de
                Información de Aprendizajes Fundamentales (SIAF), de conformidad con
                sus&nbsp;
              </span>
              <a href="https://www.unesco.org/en/privacy-policy" target="_blank">
                <strong>
                  <span style={{ color: "#212121", fontSize: "11pt" }}>
                    Principios sobre protección de datos personales y privacidad.
                  </span>
                </strong>
              </a>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Este Aviso de privacidad explica cómo el equipo de Educación de UNESCO
                México utiliza los datos personales del personal educativo y de los
                menores de edad participantes en la Política de Aprendizajes
                Fundamentales.
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  ¿Qué datos tratamos en el SIAF?
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                El equipo de Educación de UNESCO México recibe por parte de otros actores
                de la&nbsp;
              </span>
              <span style={{ fontSize: "11pt" }}>
                Política de Aprendizajes Fundamentales y trata los siguientes datos:
              </span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Datos de las niñas y niños participantes en la Política de
                    Aprendizajes Fundamentales, tales como el nombre, CURP, edad, sexo,
                    entidad y grado escolar.
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Datos del personal educativo que participa en la Política de
                    Aprendizajes Fundamentales, tales como el nombre, CURP, edad, sexo,
                    correo electrónico, número telefónico, entidad y grado escolar.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                El objetivo del SIAF es recopilar, sistematizar y gestionar datos e
                información derivada de la implementación de los componentes de la
                Política de Aprendizajes Fundamentales, mediante un sistema informático en
                línea que permita el acceso diferenciado por nivel de usuario a bases de
                datos y reportes periódicos que apoyen el monitoreo continuo de la
                Política, ofreciendo información confiable, pertinente y oportuna para la
                toma de decisiones, así como la protección de los datos personales
                recibidos por parte de los demás actores de la Política y el debido
                tratamiento de los mismos.
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  ¿Cómo utilizaremos los datos?
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Los datos que el equipo de Educación de UNESCO México recibe de los demás
                actores de la Política de Aprendizajes Fundamentales, se utilizan con la
                finalidad de monitorear y evaluar la implementación de la Política de
                Aprendizajes Fundamentales en la que participan figuras educativas de
                educación básica y las y los estudiantes de 1°, 2° y 3° de primaria de
                ciertas escuelas públicas de los estados participantes.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  ¿Cómo almacenamos sus datos?
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                El equipo de Educación de UNESCO México una vez que recibe los datos
                personales de todas las figuras participantes en la Política de
                Aprendizajes Fundamentales por parte de otros actores de la misma, los
                almacena de forma segura en la base datos del SIAF y/o en los servicios en
                la nube utilizados para tal fin. Los datos no serán publicados para acceso
                general, ni divulgados, ni vendidos, y únicamente podrán acceder a ellos
                los terceros que participen en la Política de Aprendizajes Fundamentales.
                Sin embargo, el equipo de educación de UNESCO México no asume ninguna
                responsabilidad por la seguridad de esta información. &nbsp;
              </span>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Los datos personales que trata el equipo de Educación de UNESCO México se
                conservan únicamente por el tiempo requerido para los trabajos de la
                Política de Aprendizajes Fundamentales.
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>Enlaces a otros sitios web</span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Nuestro Aviso de privacidad se aplica únicamente al sitio web del SIAF. No
                somos responsables de las políticas de privacidad ni del contenido de los
                sitios web de terceros y le recomendamos que lea los avisos de privacidad
                de otros sitios web que visite.
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  Cambios a nuestro Aviso de Privacidad
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Revisaremos o actualizaremos este Aviso si nuestras prácticas cambian, o
                si desarrollamos mejores formas de mantenerlo informado sobre
                ellas.&nbsp;Consulte esta página con frecuencia para obtener la
                información más reciente y la fecha de vigencia de cualquier
                cambio.&nbsp;Si decidimos cambiar este Aviso, publicaremos un nuevo Aviso
                en nuestro sitio web y cambiaremos la fecha en la parte inferior.&nbsp;Los
                cambios al Aviso no se aplicarán retroactivamente.
              </span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  Cómo contactarnos
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                En caso de ser participante de la Política de Aprendizajes Fundamentales
                puede solicitar acceso a sus datos personales para verificar su exactitud
                y puede solicitar su corrección. Para hacerlo en relación con su uso de
                este sitio web, póngase en contacto con&nbsp;
              </span>
              <a href="mailto:privacy@siaprendizajesf.org" target="_blank">
                <u>
                  <span style={{ color: "#0000ff", fontSize: "11pt" }}>
                    privacy@siaprendizajesf.org
                  </span>
                </u>
              </a>
              <span style={{ color: "#212121", fontSize: "11pt" }}>.</span>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Para cualquier otra pregunta relacionada con el procesamiento de sus datos
                personales, póngase en contacto al mismo correo electrónico
              </span>
              <span style={{ color: "#ff0000", fontSize: "11pt" }}>.&nbsp;</span>
            </p>
            <p>
              <strong>
                <span style={{ color: "#212121", fontSize: "18pt" }}>
                  Actualizaciones a este Aviso de Privacidad
                </span>
              </strong>
            </p>
            <p>
              <span style={{ color: "#212121", fontSize: "11pt" }}>
                Fecha: 03 de octubre de 2023.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <a href="https://www.unesco.org/en/privacy-policy" target="_blank">
                <strong>
                  <em>
                    <span style={{ color: "#212121", fontSize: "11pt" }}>
                      Principios de la UNESCO sobre protección de datos personales y
                      privacidad
                    </span>
                  </em>
                </strong>
                <span style={{ color: "#212121", fontSize: "11pt" }}>.&nbsp;</span>
              </a>
            </p>
        </div>
      </div>
    </div>

  </>)
}

export default PrivacyNotice;
