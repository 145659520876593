import React from 'react';
import { useSelector } from 'react-redux';

const PrincipalProfile = (props) => {

    let profile = useSelector(state => state.userState.profile);

    console.log('profile', profile);

    return (<>

        {profile && profile.principal && profile.principal.school &&

            <div className='container mb-5 mt-5'>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Estado: </b>
                            {profile.principal.school.state_name}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Región: </b>
                            {profile.principal.school.region_name}
                            - {profile.principal.school.region_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Sector: </b>
                            {profile.principal.school.sector_name}
                            - {profile.principal.school.sector_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Zona: </b>
                            {profile.principal.school.zone_name}
                            - {profile.principal.school.zone_cct}
                        </p>
                    </div>
                </div>

                {profile.principal && profile.principal.principalsSchool ?
                    <div className='row mb-1'>
                        <div className="col-lg-6">
                            
                        </div>
                    </div>

                : profile.principal && profile.principal.school ?

                    <div className='row mb-1'>
                        <div className="col-lg-6">
                            <p>
                                <b>Escuela: </b>
                                {profile.principal.school.school_name}
                                - {profile.principal.school.school_cct}
                            </p>
                        </div>
                    </div>

                : null}

                
            </div>
        }

    </>)
}

export default PrincipalProfile;
