import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'
import auth from '../services/users/AuthService'

const AuthRoute = ({ component: Component, ...rest }) => (
  
    <Route {...rest} render={(props) => (
      auth.isAuthenticated() ?
        auth.isProfileSelected() ? 
          <Component {...props} />
        : <Redirect to={{
            pathname: '/profiles',
            state: { from: props.location }
          }} />
      : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
    )} />
  )

export default AuthRoute;
