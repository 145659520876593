import React from 'react';
import auth from '../../../../services/users/AuthService';
import Table from '../../../../components/Charts/Table';

const SummarySection = ({props, schoolId, currentSchoolYear}) => {

    const [school, setSchool] = React.useState(null);
    const [statistics, setStatistics] = React.useState(null);

    React.useEffect(()=>{
        auth.getSchool(schoolId).then(school => {
            setSchool(school);
        }).catch(error => {
            console.log('err', error);
        });

        auth.getSchoolStatistics(schoolId, currentSchoolYear.id).then(school => {
            setStatistics(school);
        }).catch(error => {
            console.log('err', error);
        });
    }, []);


    return (<div className="container mb-5">

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-12">
                            <h6>
                                <p className="m-0">Política Escuela de Aprendizajes Fundamentales</p>
                                <p className="m-0">{currentSchoolYear? currentSchoolYear.name: ''}</p>
                                <b>Reporte Escuela</b><br/>
                                <b>Momento 2</b><br/>
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="container">

                    {!(school && statistics)? null : <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <p style={{backgroundColor:'#4371C4', color: 'white', padding: '10px 10%'}}><b>Entidad:</b> {school.state_name} <b>Escuela:</b> {school.school_name}  <b>CCT:</b> {school.school_cct}  <b>Zona escolar:</b> {school.zone_name} - {school.zone_cct} <b>Sector:</b> {school.sector_name} - {school.sector_cct}  <b>Región:</b> {school.region_name} - {school.region_cct} </p>
                        </div>
                    </div> }

                </div>

            </div>
        </div>
    </div>)
}

export default SummarySection;

const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    },
    td2: {
        padding: '0.25rem',
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: '14px',
        border: '1px solid gray'
    }
}
