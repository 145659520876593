import React from 'react';
import imgGoals1 from'../../../../assets/unesco/img/goals1.jpg';

const GoalsSection = ({props, schoolId}) => {

    return (<div className="container mb-5">

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>2. Meta compartida para el cierre del ciclo escolar</b>
                                </p>
                                <ul style={{listStyle: 'disc'}}>
                                    <li>Nuestra meta es que al menos el 80% de las y los <b>estudiantes de 1°</b> alcancen el nivel C y que el 100% de las y los <b>estudiantes de 2° y 3°</b> logren el nivel D en el Instrumento Diagnóstico de Alfabetización Inicial (IDAI).</li>
                                    <li><b>Para tercer grado</b>, los estudiantes con nivel de conceptualización D, la meta es que el 80% de las y los estudiantes alcancen el nivel 3 en el Instrumento de Convencionalidades de la Escritura (ICE)</li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div> }
    </div>)
}

export default GoalsSection;


const styles = {
    td2: {
        padding: '0.25rem',
        textAlign: 'center',
        fontSize: '14px',
        border: '1px solid gray'
    }
}

