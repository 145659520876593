import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import SimpleTable from '../../components/Charts/SimpleTable';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import Loading from '../../components/Loading';
import * as Roles from '../../auth/Roles';

const FichaState = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateSelectedName, setStateSelectedName] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState(null);

    gtag('event', 'page_view', {
      page_title: 'Ficha de Estado',
      page_location: 'ficha-state',
      page_path: '/avance-state',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    
    React.useEffect(() => {
        if (userState.profile && 
            userState.profile.role && 
            userState.profile.state && 
            userState.profile.role.id === Roles.ROLE_ESTATAL) {
                onStateIdChanged(userState.profile.state.state_id);

        } else {
            getStates();
        }       
    },[userState.schoolYear]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);
            setIsLoading(false);
            //getSummary(null);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        getSummary({
            state: stateId
        });

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const getSummary = (filters) => {
        setIsLoading(true);

        filters = {
            ...filters,
            schoolYearId: userState.schoolYear.id
        }

        auth.getFichaByState(filters.state).then(result => {
            setData(result.summary);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });       
    }

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_ficha_report', {
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }
    

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className='container mb-5'>
            <div className='row '>
                <div className="col-12 ml-auto" onClick={(e) => {downloadReport(e);}}>
               
                    <ReactToPrint
                      trigger={() => <Button style={{
                          float: 'right',
                          marginBottom: '1rem',
                          padding: '1rem 2rem'
                      }}>Imprimir</Button>}
                      content={() => componentRef}
                    />

                </div>
            </div>
        </div>

        <div ref={(el) => (componentRef = el)}>

            <div className='container mb-5'>
                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                {userState.profile && userState.profile.role &&
                    [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                    
                        <div className='row '>
                            <div className="col-14 ml-auto">
                                <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                                  <option value="">Estado (Todos)</option>
                                  {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                                </select>
                            </div>
                        </div>

                    : null
                }

            </div >

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">

                    {data && <div className="container">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <h4 className="mb-0 mt-0"><b>Sector:</b> {stateSelectedName}<br/></h4>
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>Formación de docentes, directores y figuras de acompañamiento</b></p>
                            </div>
                        </div>
                    </div>}

                    {data && <div className="container">
                        <div className="row align-items-center justify-content-center text-center mb-3 mt-3">
                            <div className="col-12">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>Sector</p></th>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Docentes</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Fig. directiva</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Fig. acompañamiento</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td key={i} style={styles.td}>{zone.sectorName}</td>
                                                <td key={i} style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td key={i} style={styles.td}>{zone.escuelasTotales}</td>

                                                <td key={i} style={styles.td}>{zone.docentesTotales}</td>
                                                <td key={i} style={styles.td}>{zone.docentesFormados}</td>
                                                <td key={i} style={styles.td}>{zone.docentesEnFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.docentesPorcFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.docentesNoFormados}</td>

                                                <td key={i} style={styles.td}>{zone.directoresTotal}</td>
                                                <td key={i} style={styles.td}>{zone.directoresFormados}</td>
                                                <td key={i} style={styles.td}>{zone.directoresEnFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.directoresPorcFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.directoresNoFormados}</td>

                                                <td key={i} style={styles.td}>{zone.figurasTotal}</td>
                                                <td key={i} style={styles.td}>{zone.figurasFormados}</td>
                                                <td key={i} style={styles.td}>{zone.figurasEnFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.figurasPorcFormacion}</td>
                                                <td key={i} style={styles.td}>{zone.figurasNoFormados}</td>
                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    <div className="container mt-5">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>Alfabetización incial. Estudiantes de primero, segundo y tercer grado. Segundo momento de captura</b></p>
                            </div>
                        </div>
                    </div>

                    {data && <div className="container">
                        <div className="row align-items-center justify-content-center text-center mb-3 mt-3">
                            <div className="col-12">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Primer Grado</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Segundo Grado</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Tercer Grado</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td key={i} style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td key={i} style={styles.td}>{zone.escuelasTotales}</td>

                                                <td key={i} style={styles.td}>{zone.estudiantesPrimeroTotal}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesPrimeroAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesPrimeroNoAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesPrimeroSinDatos}</td>

                                                <td key={i} style={styles.td}>{zone.estudiantesSegundoTotal}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesSegundoAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesSegundoNoAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesSegundoSinDatos}</td>

                                                <td key={i} style={styles.td}>{zone.estudiantesTerceroTotal}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesTerceroAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesTerceroNoAlfabetizados}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesTerceroSinDatos}</td>
                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    <div className="container mt-5">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>ICE  Estudiantes de tercer grado. Segundo momento de captura</b></p>
                            </div>
                        </div>
                    </div>

                    {data && <div className="container">
                        <div className="row align-items-center text-center mb-3 mt-3">
                            <div className="col-6">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes por niveles del Instrumento de convenciones de la escritura</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>1</p></th>
                                            <th style={styles.th}><p style={styles.thp}>2</p></th>
                                            <th style={styles.th}><p style={styles.thp}>3</p></th>
                                            <th style={styles.th}><p style={styles.thp}>4</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td key={i} style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td key={i} style={styles.td}>{zone.escuelasTotales}</td>

                                                <td key={i} style={styles.td}>{zone.estudiantesNivelUno}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesNivelDos}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesNivelTres}</td>
                                                <td key={i} style={styles.td}>{zone.estudiantesNivelCuatro}</td>

                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>)
}

const styles = {
    th: {
        padding: '0px',
        border: '1px solid #044684'
    },
    thp: {
        backgroundColor: '#1D6AB3',
        color: 'white',
        padding: '2px',
        fontWeight: 'bold',
        fontSize: '11px',
        textTransform: 'capitalize',
        margin: '0px',
        height: '100%'

    },
    td: {
        padding: '2px',
        fontSize: '12px',
        textTransform: 'uppercase',
        border: '1px solid gray'
    }
}


export default FichaState;
