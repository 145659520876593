import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../services/loading/ActionTypes';

const Loading = (props) => {

  let dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGlobalLoading(props.loading));
  },[props.loading]);

  return (<></>);
}

export default Loading;
