import React from 'react';
import { useSelector } from 'react-redux';

const AdminProfile = (props) => {

    let profile = useSelector(state => state.userState.profile);

    return (<>

        {profile && profile.state &&

            <div className='container mb-5 mt-5'>
                <div className='row mb-1'>
                    <div className="col-lg-6"></div>
                </div>
            </div>
        }

    </>)
}

export default AdminProfile;
