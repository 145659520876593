import React from "react";
import Loading from "react-fullscreen-loading";
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//Navigation
import Sidebar from '../components/Navigation/Sidebar';
import Topbar from '../components/Navigation/Topbar';

//Pages
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Logout from "../pages/Logout";
import Cards from "../pages/Cards";
import Charts from "../pages/Charts";
import Group from "../pages/Group";
import Instrument from "../pages/Instrument";

import InstrumentMath from "../pages/InstrumentMath";
import InstrumentForm from "../pages/InstrumentForm";

import SchoolsImporter from "../pages/Importer/SchoolsImporter";
import PrincipalsImporter from "../pages/Importer/PrincipalsImporter";
import TeachersImporter from "../pages/Importer/TeachersImporter";
import StudentsImporter from "../pages/Importer/StudentsImporter";
import SupervisorsZoneImporter from "../pages/Importer/SupervisorsZoneImporter";
import SupervisorsSectorImporter from "../pages/Importer/SupervisorsSectorImporter";
import SupervisorsRegionImporter from "../pages/Importer/SupervisorsRegionImporter";
import SupervisorsStateImporter from "../pages/Importer/SupervisorsStateImporter";
import Moodle2Importer from "../pages/Importer/Moodle2Importer";

import CaptureIDAIImporter from "../pages/Importer/CaptureIDAIImporter";
import CaptureICEImporter from "../pages/Importer/CaptureICEImporter";

import FdaPrimerAnioImporter from "../pages/Importer/FdaPrimerAnioImporter";
import FdaSegundoAnioImporter from "../pages/Importer/FdaSegundoAnioImporter";
import FormacionDocenteImporter from "../pages/Importer/FormacionDocenteImporter";

import MoodleImporter from "../pages/Importer/MoodleImporter";
import TrayectoMoodle from "../pages/TrayectoMoodle";
import TrayectoFormativo from "../pages/TrayectoFormativo";
import TrayectoFormativoResumen from "../pages/TrayectoFormativoResumen";
import IMEI from "../pages/IMEI";

import Downloadables from "../pages/Downloadables";

import Summary from "../pages/Summary";
import Evaluation from "../pages/Evaluation";
import Matematicas from "../pages/Matematicas";
import Monitoring from "../pages/Monitoring";
import AvanceSections from "../pages/AvanceSections";
import ListSections from "../pages/AvanceSections/ListSections";
import FichaState from "../pages/AvanceSections/FichaState";

import ImplementationIndex from "../pages/ImplementationIndex";
import SchoolReport from "../pages/SchoolReport";

import PrincipalCapture from "../pages/PrincipalCapture";


import MathReport from "../pages/MathReport";
import StateReport from "../pages/StateReport";
import StateSummaryReport from "../pages/StateSummaryReport";
import Indice from "../pages/Indice";
import CohortTeachers from "../pages/Cohort/CohortTeachers";
import CohortSchools from "../pages/Cohort/CohortSchools";
import CohortDownloadables from "../pages/Cohort/CohortDownloadables";

import Administration from "../pages/Administration";

import GroupStudentsSection from "../pages/Administration/sections/GroupStudentsSection";
import RegisterSupervisorSection from "../pages/Administration/sections/RegisterSupervisorSection";
import RegisterDocenteSection from "../pages/Administration/sections/RegisterDocenteSection";


import AdminPage    from "../pages/Administration/pages/figuras/AdminPage";
import RegionalPage from "../pages/Administration/pages/figuras/RegionalPage";

import StatePage    from "../pages/Administration/pages/StatePage";
import RegionPage   from "../pages/Administration/pages/RegionPage";
import SectorPage   from "../pages/Administration/pages/SectorPage";
import ZonePage     from "../pages/Administration/pages/ZonePage";
import SchoolPage   from "../pages/Administration/pages/SchoolPage";

const PrivateRoutes = () => {
    const isGlobalLoading = useSelector(state => state.loadingState.isGlobalLoading);

    return (
      <BrowserRouter>
        <>
            {/* <!-- Page Wrapper --> */}

            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            <div id="wrapper">

              <Loading loading={isGlobalLoading} background="#00000029" loaderColor="#3498dbAA" />

              {/* <!-- Sidebar --> */}
              <Sidebar />
              {/* <!-- End of Sidebar --> */}

              {/* <!-- Content Wrapper --> */}
              <div id="content-wrapper" className="d-flex flex-column">

                {/* <!-- Main Content --> */}
                <div id="content">

                  {/* <!-- Begin Page Content --> */}
                  <div className="container-fluid">

                      <Switch>
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/logout" component={Logout} />

                        <Route path="/cards" component={Cards} />
                        <Route path="/charts" component={Charts} />

                        <Route path="/downloadables" component={Downloadables} />

                        <Route path="/summary" component={Summary} />
                        <Route path="/evaluation" component={Evaluation} />
                        <Route path="/matematicas" component={Matematicas} />
                        <Route path="/indice-iei" component={IMEI} />
                        <Route path="/school-indice/:id" component={Indice} />

                        <Route path="/cohort/teachers" component={CohortTeachers} />
                        <Route path="/cohort/schools" component={CohortSchools} />
                        <Route path="/cohort/downloadables" component={CohortDownloadables} />

                        <Route path="/monitoring" component={Monitoring} />

                        <Route path="/avance-sections/:id" component={AvanceSections} />
                        <Route path="/avance-sections" component={ListSections} />
                        <Route path="/avance-states" component={FichaState} />

                        <Route path="/implementation-index" component={ImplementationIndex} />
                        <Route path="/school-report/:id" component={SchoolReport} />

                        <Route path="/school-capture/:id/grade/:grade/instruments/:instrumentId" component={PrincipalCapture} />

                        <Route path="/math-report/:id" component={MathReport} />
                        <Route path="/state-report" component={StateReport} />
                        <Route path="/state-summary-report" component={StateSummaryReport} />

                        <Route path="/schools/:schoolId/teachers/:teacherId" component={RegisterDocenteSection} />
                        <Route path="/schools/:schoolId/groups/:groupId" component={GroupStudentsSection} />

                        <Route path="/administrators/:id" component={AdminPage} />                        
                        <Route path="/states/:id" component={StatePage} /> 
                        <Route path="/sectors/:id" component={SectorPage} /> 
                        <Route path="/zones/:id" component={ZonePage} /> 
                        <Route path="/schools/:id" component={SchoolPage} />


                        <Route path="/regions/:id/supervisors/:userId" component={RegionPage} /> 
                        <Route path="/regions/:id" component={RegionPage} /> 


                        <Route path="/states/:stateId/regions" component={RegionalPage} /> 
                        <Route path="/states/:id/supervisors/:userId" component={RegionalPage} /> 


                        <Route path="/importer/schools" component={SchoolsImporter} />
                        <Route path="/importer/principals" component={PrincipalsImporter} />
                        <Route path="/importer/teachers" component={TeachersImporter} />
                        <Route path="/importer/students" component={StudentsImporter} />
                        <Route path="/importer/supervisors-zone" component={SupervisorsZoneImporter} />
                        <Route path="/importer/supervisors-sector" component={SupervisorsSectorImporter} />
                        <Route path="/importer/supervisors-region" component={SupervisorsRegionImporter} />
                        <Route path="/importer/supervisors-state" component={SupervisorsStateImporter} />
                        <Route path="/importer/moodle" component={MoodleImporter} />

                        <Route path="/importer/capture-idai" component={CaptureIDAIImporter} />
                        <Route path="/importer/capture-ice" component={CaptureICEImporter} />

                        <Route path="/importer/fda-1" component={FdaPrimerAnioImporter} />
                        <Route path="/importer/fda-2" component={FdaSegundoAnioImporter} />
                        <Route path="/importer/formacion-docente" component={FormacionDocenteImporter} />

                        <Route path="/importer/moodle-2" component={Moodle2Importer} />

                        {/*

                        <Route path="/trayecto-moodle" component={TrayectoMoodle} />
                        <Route path="/trayecto-formativo" component={TrayectoFormativoResumen} />

                        */}

                        <Route path="/groups/:id/" component={Group} />
                        <Route path="/instruments/:instrumentId/groups/:groupId" component={Instrument} />
                        <Route path="/instruments/:id" component={Instrument} />

                        <Route path="/instrument-math/:instrumentId/groups/:groupId" component={InstrumentMath} />
                        <Route path="/instrument-math/:instrumentId/groups/:groupId/students/:studentId" component={InstrumentMath} />

                        <Route path="/instrument-form/:instrumentId/groups/:groupId" component={InstrumentForm} />
                        <Route path="/instrument-form/:instrumentId/groups/:groupId/students/:studentId" component={InstrumentForm} />

                        <Route path="*" component={NotFound} />
                      </Switch>

                      {/* <!-- Footer --> */}
                      <footer className="sticky-footer">
                        <div className="container my-auto">
                          <div className="copyright text-center my-auto">
                            <span>Copyright &copy; Unesco 2023 - <a href="_blank" href="/website-privacy-notice">Aviso de privacidad</a></span>
                          </div>
                        </div>
                      </footer>
                      {/* <!-- End of Footer --> */}

                  </div>
                  {/* <!-- /.container-fluid --> */}
                  

                </div>
                {/* <!-- End of Main Content --> */}
             
              </div>
              {/* <!-- End of Content Wrapper --> */}

            </div>
            {/* <!-- End of Page Wrapper --> */}

            {/* <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up"></i>
            </a>
        </>

      </BrowserRouter>
  );
}

export default PrivateRoutes;
