import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

import SchoolsListSection           from './../lists/SchoolsListSection';
import ZoneSupervisorsListSection   from './../lists/figuras/ZoneSupervisorsListSection';

import NewSchoolModal from '../modals/NewSchoolModal';

const form = FormBuilder.group({
  name: ["", [Validators.required]],
  cct: ["", [Validators.required, Validators.pattern(/^[0-9a-zA-Z]*$/)]],
  officeName: ["", null],
  officeAddress: ["", null],
  colonia: ["", null],
  localidad: ["", null],
  sector: [0, [Validators.required]],
  municipality: [0, [Validators.required]],
});
const ZonePage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const zoneId = props.match.params.id;

    console.log('state', zoneId);

    const [isLoading, setIsLoading] = useState(false);
    const [currentZone, setCurrentZone] = useState(null);
    const [states, setStates] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [sectorSelected, setSectorSelected] = useState(null);

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    useEffect(() => {
        if (zoneId) {
            getZone(zoneId);
        }
    },[zoneId]);

    const getZone = (id) => {
        setIsLoading(true);
        auth.getZone(id).then(result => {
            console.log('zone', result);
            setCurrentZone(result);

            form.controls.name.setValue(result.name);
            form.controls.cct.setValue(result.cct);
            form.controls.officeName.setValue(result.officeName);
            form.controls.officeAddress.setValue(result.officeAddress);
            form.controls.colonia.setValue(result.colonia);
            form.controls.localidad.setValue(result.localidad);

            if(result.sector && result.sector.region && result.sector.region.state){
                setSectorSelected(result.sector.id);
                getSectors(result.sector.region.id, result.sector? result.sector.id: "");
                getMunicipios(result.sector.region.state.id, result.municipality? result.municipality.id: "");
            }

            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getSectors = (regionId, sectorId) => {
        setIsLoading(true);
        auth.getSectors(regionId).then(result => {
            setSectors(result);
            form.controls.sector.setValue(sectorId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getMunicipios = (stateId, municipalityId) => {
        setIsLoading(true);
        auth.getMunicipios(stateId).then(result => {
            setMunicipios(result);
            form.controls.municipality.setValue(municipalityId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const onInput = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/i, "");
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
          text: '¿Seguro que desea actualizar la zona?',
          showDenyButton: true,
          confirmButtonText: 'Sí',
          denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                save();
            }
        });
    }

    const save = () => {
        const data = {
            id: zoneId,
            name: form.value.name? form.value.name.trim() : '',
            cct: form.value.cct? form.value.cct.trim() : '',
            officeName: form.value.officeName? form.value.officeName.trim() : '',
            officeAddress: form.value.officeAddress? form.value.officeAddress.trim() : '',
            colonia: form.value.colonia? form.value.colonia.trim() : '',
            localidad: form.value.localidad? form.value.localidad.trim() : '',
            sector: form.value.sector,
            municipality: form.value.municipality,
        }

        setIsLoading(true);
        auth.updateZone(zoneId, data).then(result => {
          // setRoles(result);
          setIsLoading(false);
          Swal.fire('Zona actualizada con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al actualizar la zona.'
          });
        });

    }

    const OnNewSchoolCreated = (newSchool) => {
        //
    }


    const EditZone = ({zone}) => {return (
        <div className="container mb-5 mt-5">
            <FieldGroup
                control={form}
                strict={false}
                render={({ get, invalid }) => (
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row mb-3">

                            <FieldControl
                                name="name"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-8">
                                        <span>Nombre</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nombre obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="cct"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>CCT</span><br/>
                                        <input type="text"  onInput={onInput} {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'CCT obligatorio'}
                                        </span>
                                        <span className="errorAlertSmall pt-3">
                                            {touched && hasError('pattern') && 'CCT inválido'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="sector"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Sector</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {sectors && sectors.map((sector,i) => <option key={i} value={sector.id}>{sector.name} - {sector.cct}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Sector obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="municipality"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Municipio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {municipios && municipios.map((municipio,i) => <option key={i} value={municipio.id}>{municipio.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Municipio obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="colonia"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Colonia</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>
                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="officeName"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nombre de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="officeAddress"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Dirección de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="localidad"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Localidad</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                                GUARDAR
                            </button>
                        </div>

                    </form>
                )}
            />
        </div>
    )}


    const EditRegion = ({region}) => {return (
        <div className="container mb-5 mt-5">
            <FieldGroup
                control={form}
                strict={false}
                render={({ get, invalid }) => (
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row mb-3">

                            <FieldControl
                                name="name"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-8">
                                        <span>Nombre</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nombre obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="cct"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>CCT</span><br/>
                                        <input type="text"  onInput={onInput} {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'CCT obligatorio'}
                                        </span>
                                        <span className="errorAlertSmall pt-3">
                                            {touched && hasError('pattern') && 'CCT inválido'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="state"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Estado</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {states && states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Estado obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="municipality"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Municipio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {municipios && municipios.map((municipio,i) => <option key={i} value={municipio.id}>{municipio.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Municipio obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="colonia"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Colonia</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>
                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="officeName"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nombre de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="officeAddress"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Dirección de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="localidad"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Localidad</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                                GUARDAR
                            </button>
                        </div>

                    </form>
                )}
            />
        </div>
    )}

    return (<div>
        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Zona</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="schools-tab" data-toggle="tab" href="#schools" role="tab" aria-controls="schools" aria-selected="false">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Escuelas</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="zones-supervisors-tab" data-toggle="tab" href="#zones-supervisors" role="tab" aria-controls="zones-supervisors" aria-selected="false">
                <FontAwesomeIcon icon={faPersonChalkboard} className="mr-2 tab-icon" /><span>Supervisores</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">

            <CardBasic title="">
                <div className='container'>
                    <div className='row '>

                        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

                            {currentZone && <EditZone zone={currentZone} />}

                        </div>

                        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

                            <div className="container mb-5 mt-5">
                                <div className="row mb-1">
                                    <div className="col-lg-6">
                                        <p><b>Agregar</b></p>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        {currentZone && <NewSchoolModal zoneId={currentZone.id} OnNewSchoolCreated={OnNewSchoolCreated} />}


                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalNewSchool">
                                            <FontAwesomeIcon icon={faSchool} className="mr-3" /><b>Escuela</b>
                                        </button>
                                        
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalNewSchool">
                                            <FontAwesomeIcon icon={faPersonChalkboard} className="mr-3" /><b>Supervisor de Zona</b>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </CardBasic>
            </div>
          
            <div className="tab-pane fade" id="schools" role="tabpanel" aria-labelledby="schools-tab">
                <SchoolsListSection zoneId={zoneId} />
            </div>

            <div className="tab-pane fade" id="zones-supervisors" role="tabpanel" aria-labelledby="regions-supervisors-tab">
                <ZoneSupervisorsListSection zoneId={zoneId} />
            </div>

        </div>
      </div>)
}

export default ZonePage;
