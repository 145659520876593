import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';
import ChartDonut2 from '../../../components/Charts/Donut2';
import ChartSpeed from '../../../components/Charts/Speed';
import ChartEvaluado from '../../../components/Charts/Evaluado';
import auth from '../../../services/users/AuthService';
import HorizontalBar2 from '../../../components/Charts/HorizontalBar2';
import HorizontalBarCapturados from '../../../components/Charts/HorizontalBarCapturados';

const ICESection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});
    const [goals, setGoals] = useState([]);
    const [evaluadosNivelEscuela, setEvaluadosNivelEscuela] = useState(0);

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };


    React.useEffect(()=>{
        setIsLoading(true);
        auth.getStateGoals(stateId, currentSchoolYear.id).then(result => {
            setGoals(result);

            const value = result.filter(({grade}) => [1,2,3].includes(Number(grade)))
                .reduce((sum, record) => sum + Number(record.evaluadosTotalICE), 0);

            const total =  result.filter(({grade}) => [1,2,3].includes(Number(grade)))
                .reduce((sum, record) => sum + Number(record.total), 0);

            setEvaluadosNivelEscuela(Number(value/total * 100).toFixed(2));

            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

    }, [stateId, currentSchoolYear]);

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-10">

                <div className="container">

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>Registro de resultados del Instrumento de Convencionalidades de la Escritura. </b><br></br>
                                    <b>Aplicación final</b>
                                </p>
                            </span>
                        </div>

                    </div>


                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-6">
                            {goals.filter(goal => goal.grade == 3).map(goal =>
                                <div>
                                    <p>Logro en tercer grado</p>
                                    <ChartSpeed
                                        title="80% estudiantes nivel 4 - avanzado"
                                        subtitle="Tercer Grado"
                                        progress={Number(goal.progressICE*100).toFixed(2)}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            {goals.filter(goal => goal.grade == 3).map(goal =>
                                <div>
                                    <p>Porcentaje de estudiantes con resultados registrados en la aplicación final</p>
                                    <ChartEvaluado
                                        title="Tercer grado"
                                        progress={Number(goal.evaluadosICE*100).toFixed(2)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del Instrumento de Convencionalidades de la Escritura (ICE)</b></p>
                            </span>

                            {stateStatus && <HorizontalBar2
                                title="Tercer Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCaptureEnrol'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {schoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {instrumentId: 2},
                                        {'$or': [
                                            {customScore: '1'},
                                            {customScore: '2'},
                                            {customScore: '3'},
                                            {customScore: '4'},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#ED7D32', 
                                        '2': '#FFBF00', 
                                        '3': '#92D051', 
                                        '4': '#06B050',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            /> }
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span><b>ICE (SOLO TERCER GRADO) (final)</b></span>

                            {stateStatus && <HorizontalBarCapturados
                                title="IDAI (final)"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCaptureEnrol'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {schoolYearId: currentSchoolYear.id},
                                        {instrumentId: 2},
                                        {formPhase: 3},
                                        {'$or': [
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimension: {name: 'estado', label: ''},
                                    dimensions: [{name: 'stateId', label: ''}],
                                    breakdownDimension: {name: 'grado', label: 'Grado'},
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'capturados', label: 'Capturados', aggregation: 'none', custom: "SUM(IF(customScore = 'No Capturado', 0, 1))"},
                                    ],
                                    colorLabels: {
                                        '1': '#71AD47', 
                                        '2': '#5B9CD5', 
                                        '3': '#FFBF00',
                                    },
                                    order: {
                                        dimension: 'grado',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY grado ASC',
                                }}
                            />}

                        </div>
                    </div>


                </div>
            </div>
        </div> }
    </div>)
}

export default ICESection;
