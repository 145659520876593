import React from 'react';
import { Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import Swal from 'sweetalert2';
import {
    FormGenerator,
    Validators,
} from "react-reactive-form";
import Loading from '../../components/Loading';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import $ from 'jquery';

let myForm;

const ImplementationIndex = (props) => {

    let user = useSelector(state => state.userState.profile.user);
    let location = useLocation();
    let history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [formId, setFormId] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [summary, setSummary] = React.useState(null);

    gtag('event', 'page_view', {
      page_title: 'Índice de Implementación',
      page_location: 'implementation-index',
      page_path: '/implementation-index',
      'username': user? user.username : '',
    });

    React.useEffect(()=>{
        setIsLoading(true);
        if(!(location.formId && location.schoolId)){
            return;
        }

        auth.getInstrumentByFormId(user.id, location.formId, location.schoolId).then(result => {
            setTitle(result.instrument.name);
            setSummary(result.summary);

            try {
                setFormId(result.instrument.forms[0].id);
                setColumns(result.instrument.forms[0].questions);
            } catch(e) {
                console.log('e', e);
            }
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

    }, [location.schoolId, location.formId]);

    React.useEffect(() => {
        setTimeout(() => {
            columns.forEach(column => {
                myForm.controls[column.id].setValue(column.optionSelected);
                myForm.controls[column.id].patchValue(column.optionSelected);
            });
        }, 500);

    }, [columns]);

    const handleReset = () => {
        // myForm.reset();
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        const responses = Object.keys(myForm.value).map(key => {
            return {
                questionId: key,
                optionId: myForm.value[key],
                singleResponseId: columns.filter(column => column.id === Number(key))[0].singleResponsesId
            }
        });

        const obj = {
            responses,
            userId: user.id,
            schoolId: Number(location.schoolId),
            formId: formId
        }

        await auth.saveImplementationInstrumentForm(obj).then(result => {
            setColumns(result.forms[0].questions);
            setIsLoading(false);

            Swal.fire({
                title: '',
                text: 'Encuesta guardada exitosamente',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  let { from } = location.state || { from: { pathname: "/monitoring" } };
                  history.push(from.pathname);
                } else if (result.isDenied) {
                  // Swal.fire('Changes are not saved', '', 'info')
                }
            });

        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });
    };

    const setForm = form => {
        myForm = form;

        // Set the meta data
        myForm.meta = {
          handleReset: handleReset,
          handleSubmit: handleSubmit,
        };
    };

    const OptionsInput = ({ handler, touched, hasError, meta }) => (
        <CardBasic>
            <div>
                <div className="form-group row">
                    <div className="col-12 mb-3 mb-sm-0">
                        <label>{meta.label}</label>
                        <select {...handler()} id={meta.id}  disabled={meta.isDisabled}  className="form-control form-control-user mt-3">
                            <option value=''>Seleccione una respuesta</option>
                            { meta.options.map(option =>
                                <option key={option.id} value={option.id}>{option.optionText}</option> )
                            }
                        </select>
                    </div>
                </div>
                <span>
                    {touched
                    && hasError("required")
                    && `Se requiere una respuesta.`}
                </span>
            </div>
        </CardBasic>
    );  

    return (<>

        {!(location.schoolId && location.formId)?
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
        :
        <div className='container mb-5 mt-5'>
            <div className='row justify-content-center pb-5'>
                <div className="col-lg-8 col-md-12">
                    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                    <h1 className="h4 text-gray-900 mb-4">{title}</h1>

                    <h4>CCT: {`${location.schoolCct}`}</h4>

                    {summary &&
                        <div>
                            <h6 style={{color: "gray", fontWeight: 300}}><b style={{fontWeight: "bold"}}>Respondió: </b> {summary.respondio}</h6>
                            <h6 style={{color: "gray", fontWeight: 300}}><b style={{fontWeight: "bold"}}>Fecha: </b> {summary.fecha}</h6>
                        </div>
                    }

                    <FormGenerator onMount={setForm} fieldConfig={{
                        controls: {
                            ...Object.fromEntries( new Map(
                                columns.map(column => {
                                    return [column.id, {
                                        render: OptionsInput,
                                        options: {
                                            validators: Validators.required
                                        },
                                        meta: {
                                            label: column.questionText,
                                            name: column.questionText,
                                            id: column.id,
                                            isDisabled: summary? true: false,
                                            options: column.options || [],
                                            defaultValue: column.optionSelected || '',
                                        },
                                    }];
                                }),
                            )),
                            $field_0: {
                                isStatic: false,
                                render: ({ invalid, meta: { handleReset, handleSubmit } }) => (
                                    <button type="submit" disabled={(summary? true: false) || invalid} onClick={handleSubmit} className="btn btn-block btn-primary mb-5 lift">
                                      Guardar
                                    </button>
                                )
                            }
                        }
                    }} />
                </div>
            </div>
        </div>}
    </>)
}

export default ImplementationIndex;
