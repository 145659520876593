import React from 'react';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import {columns, saveInfo} from '../../components/CsvImporter/configs/students.columns.js';

const StudentsImporter = (props) => {

  return (<>
    <CardBasic title="Importar Estudiantes">
      <CsvImporter columns={columns} saveInfo={saveInfo} />
    </CardBasic>
  </>);

}

export default StudentsImporter;