import React from 'react';
import Table from '../../../../components/Charts/Table';
import Bar from '../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';

const IDAISection = ({schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        {!(schoolId && currentSchoolYear)? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>IV. Instrumento diagnóstico para primero, segundo y tercer grados de primaria</b>
                                </p>
                                <br></br>
                                <p>
                                    <b>Instrumento diagnóstico de alfabetización inicial (IDAI)</b>
                                </p>
                                <p>
                                    El IDAI apoya a las y los docentes a identificar el nivel de comprensión del sistema de escritura de las y los estudiantes a
                                    partir de los hallazgos de Ferreiro (1979, 1997 y 2003 ). Considera la escritura de 6 sustantivos y la interpretación que
                                    hacen las niñas y niños de las palabras. Con esta información es posible tomar decisiones sobre qué intervención resulta
                                    óptima para incidir en el aprendizaje de cada integrante del grupo.

                                </p>
                                <br></br>
                                <p>
                                    Se aplica en tres momentos: al inicio, a mediados y al final del ciclo escolar. La primera aplicación es para <b>todas y todos
                                    los estudiantes de primero y segundo grado</b>, y para aquellos de <b>tercer grado en rezago</b>. La segunda y tercera
                                    aplicación solo se aplica a los estudiantes que no hayan alcanzado el nivel D en la aplicación previa.
                                </p>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Aplicación del instrumento diagnóstico por momento y grado</b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado', priority: 1, minWidth: '10px'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Inicial', aggregation: 'count', priority: 3, minWidth: '150px'},
                                        {name: 'intermedio', label: 'Intermedio', aggregation: 'count', priority: 4},
                                        {name: 'final', label: 'Final', aggregation: 'count', priority: 5},
                                    ],
                                    customMetrics: [
                                        {name: 'recordCount', label: 'Total estudiantes por grado', aggregation: 'count', custom: '*', priority: 2},
                                    ],
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de estudiantes con resultados del IDAI , por grado y momento</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id}
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Inicial %', aggregation: 'porc', color: '#1665C0'},
                                        {name: 'intermedio', label: 'Intermedio %', aggregation: 'porc', color: '#F28E3C'},
                                        {name: 'final', label: 'Final %', aggregation: 'porc', color: '#999999'},
                                    ],
                                    customMetrics: []
                                }}
                            />

                        </div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Lista de grupos y número de estudiantes evaluados por aplicación</b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    fontSize: 'small',
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id}
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado', minWidth: '20px', priority: 1},
                                        {name: 'label', label: 'Grupo', minWidth: '20px', priority: 2},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Total', header: 'Inicial', aggregation: 'count', minWidth: '10px', priority: 7},
                                        {name: 'intermedio', label: 'Total', header: 'Intermedio', aggregation: 'count', minWidth: '10px', priority: 12},
                                        {name: 'final', label: 'Total', header: 'Final', aggregation: 'count', minWidth: '10px', priority: 17},
                                    ],
                                    customMetrics: [
                                        {name: 'inicialA', label: 'A', header: 'Inicial', minWidth: '10px', priority: 3, aggregation: 'sum', custom: 'IF(inicial="A", 1, 0)'},
                                        {name: 'inicialB', label: 'B', header: 'Inicial', minWidth: '10px', priority: 4, aggregation: 'sum', custom: 'IF(inicial="B", 1, 0)'},
                                        {name: 'inicialC', label: 'C', header: 'Inicial', minWidth: '10px', priority: 5, aggregation: 'sum', custom: 'IF(inicial="C", 1, 0)'},
                                        {name: 'inicialD', label: 'D', header: 'Inicial', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(inicial="D", 1, 0)'},
                                        {name: 'intermedioA', label: 'A', header: 'Intermedio', minWidth: '10px', priority: 8, aggregation: 'sum', custom: 'IF(intermedio="A", 1, 0)'},
                                        {name: 'intermedioB', label: 'B', header: 'Intermedio', minWidth: '10px', priority: 9, aggregation: 'sum', custom: 'IF(intermedio="B", 1, 0)'},
                                        {name: 'intermedioC', label: 'C', header: 'Intermedio', minWidth: '10px', priority: 10, aggregation: 'sum', custom: 'IF(intermedio="C", 1, 0)'},
                                        {name: 'intermedioD', label: 'D', header: 'Intermedio', minWidth: '10px', priority: 11, aggregation: 'sum', custom: 'IF(intermedio="D", 1, 0)'},
                                        {name: 'finalA', label: 'A', header: 'Final', minWidth: '10px', priority: 13, aggregation: 'sum', custom: 'IF(final="A", 1, 0)'},
                                        {name: 'finalB', label: 'B', header: 'Final', minWidth: '10px', priority: 14, aggregation: 'sum', custom: 'IF(final="B", 1, 0)'},
                                        {name: 'finalC', label: 'C', header: 'Final', minWidth: '10px', priority: 15, aggregation: 'sum', custom: 'IF(final="C", 1, 0)'},
                                        {name: 'finalD', label: 'D', header: 'Final', minWidth: '10px', priority: 16, aggregation: 'sum', custom: 'IF(final="D", 1, 0)'},
                                        {name: 'recordCount', label: 'Total estudiantes por grado', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                                    ],
                                }}
                            />
                        </div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del IDAI</b></p>
                            </span>

                            <HorizontalBar2
                                title="Primer grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 1},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />

                            <HorizontalBar2
                                title="Segundo Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 2},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />

                            <HorizontalBar2
                                title="Tercer Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="pagebreak" />
                    
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>Niveles de conceptualización de la lengua.</b><br></br>
                                    Los niveles asignados en el Instrumento Diagnóstico de Alfabetización Inicial son coherentes con la perspectiva psicogenética del
                                    desarrollo de la Lengua Escrita que ha sido ampliamente documentado por Emilia Ferreiro desde 1979.
                                </p>
                                <br></br>
                                <p>
                                    <b>A. Presilábico:</b><br></br>
                                    - Reconoce la diferencia entre dos sistemas de representación: el dibujo y la escritura.
                                    - Presenta descontrol en la cantidad o la longitud de marcas gráficas que utiliza no se cuestiona cuál utilizar.
                                    - Puede emplear “garabatos” o pseudoletras.
                                    - Construye de manera paulatina los criterios de legibilidad: variabilidad interna, variabilidad externa y cantidad mínima de marcas gráficas.
                                    - Justifica su producción escrita de forma global, es decir, hace una lectura sin detención donde no relaciona la escritura con la pauta sonora del habla.
                                </p>
                                <br></br>
                                <p>
                                    <b>B. Silábico:</b><br></br>
                                    - Inicia la fonetización de la escritura, es decir, asigna sonidos a cada letra y empieza a autodictarse al momento de escribir.
                                    - Busca representar cada sílaba sonora con una letra. Al inicio elige la letra de forma arbitraria sin que necesariamente tenga correspondencia fonética. Sin embargo, eventualmente la elección de letra pasa a ser pertinente. 
                                    - Justifica su producción escrita de manera silábica, es decir, ajusta las sílabas al número de letras que escribió.                               
                                </p>
                                <br></br>
                                <p>
                                    <b>C. Silábico alfabético:</b><br></br>
                                    - Deja de considerar a la sílaba como unidad principal de análisis y empieza a identificar letras intermedias en las palabras.
                                    - Para representar cada sílaba utiliza más de una letra, aunque en algunos casos mantiene la hipótesis silábica.
                                </p>
                                <br></br>
                                <p>
                                    <b>D. Alfabético:</b><br></br>
                                    - Escribe de forma convencional. Sin embargo, puede seguir presentando faltas de ortografía y algunas omisiones en la escritura de sílabas compuestas por grupos consonánticos hetero y homosilátivos.
                                    - En la lectura, ajusta los fonemas al número de letras que hayan usado para escribir una palabra.
                                    - Comienza la reflexión sobre las convencionalidades de la lengua escrita: normas ortográficas, segmentación de palabras, uso de mayúsculas, entre otras.
                                </p>                          
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Lista de estudiantes que cuentan evaluación agrupados por docente</b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id}
                                    ]},
                                    dimensions: [
                                        {name: 'teacherName', label: 'Nombre Profesor', minWidth: '200px', priority: 1},
                                        {name: 'grado', label: 'Grado', minWidth: '60px', priority: 2},
                                        {name: 'studentName', label: 'Nombre del Estudiante', minWidth: '200px', priority: 3},
                                        {name: 'inicial', label: 'Inicial', minWidth: '20px', priority: 3},
                                        {name: 'intermedio', label: 'Intermedio', minWidth: '20px', priority: 3},
                                        {name: 'final', label: 'Final', minWidth: '20px', priority: 3},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                }}
                            />
                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAISection;
