import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { gtag } from 'ga-gtag';
import uploadIcon from'../../../assets/unesco/img/icons/upload_icon2.svg';
import archiveIcon from'../../../assets/unesco/img/icons/archive_icon.svg';
import graphUpIcon from'../../../assets/unesco/img/icons/graph_up_icon.svg';
import mathIcon from'../../../assets/unesco/img/icons/ic_math_white.svg';
import surveyIcon from'../../../assets/unesco/img/icons/ic_survey.svg';
import auth from '../../../services/users/AuthService'
import { userGroups, userLogOut } from '../../../services/users/ActionTypes';
import { clickMenuOpen } from '../../../services/menu/ActionTypes';
import { resetSurvey } from '../../../services/menu/ActionTypes';

import ArchiveSchoolYearOptions from './ArchiveSchoolYearOptions';
import CurrentSchoolYearOptions from './CurrentSchoolYearOptions';

import * as Roles from '../../../auth/Roles';

const Sidebar = (props) => {

  let dispatch = useDispatch();

  let userState = useSelector(state => state.userState);
  let menuState = useSelector(state => state.menuState);

  return (
    <ul className={menuState.menuOpen ? 'navbar-nav bg-gradient-white sidebar accordion toggled no-print' : 'navbar-nav bg-gradient-white sidebar accordion no-print'} id="accordionSidebar">
      
      {/* Super Admin Options */}

      {(userState.profile && userState.profile.id_cargo === Roles.ROLE_SUPER_ADMIN) ||
        (userState.profile && userState.profile.role && userState.profile.role.id === Roles.ROLE_SUPER_ADMIN) ?
        <>
          <div className="sidebar-heading">Importaciones</div>
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseImports" aria-expanded="true" aria-controls="collapseImports">
              <img src={uploadIcon} className="" style={{width: 32, margin: "0 0.64rem"}}/>
              <span>Importar</span>
            </a>
            <div id="collapseImports" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/importer/supervisors-state">Importar Estatales</Link>
                <Link className="collapse-item" to="/importer/supervisors-region">Importar Regionales</Link>
                <Link className="collapse-item" to="/importer/supervisors-sector">Importar Jefes de Sector</Link>
                <Link className="collapse-item" to="/importer/supervisors-zone">Importar Supervisores de Zona / ATP's</Link>
                <Link className="collapse-item" to="/importer/schools">Importar Escuelas</Link>
                <Link className="collapse-item" to="/importer/principals">Importar Directores</Link>
                <Link className="collapse-item" to="/importer/teachers/">Importar Docentes</Link>
                <Link className="collapse-item" to="/importer/students">Importar Alumnos</Link>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseImportsMoodle" aria-expanded="true" aria-controls="collapseImportsMoodle">
              <img src={uploadIcon} className="" style={{width: 32, margin: "0 0.64rem"}}/>
              <span>Importar Moodle</span>
            </a>
            <div id="collapseImportsMoodle" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/importer/moodle">Importar Moodle</Link>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseImportsFormacion" aria-expanded="true" aria-controls="collapseImportsFormacion">
              <img src={uploadIcon} className="" style={{width: 32, margin: "0 0.64rem"}}/>
              <span>Importar Formación</span>
            </a>
            <div id="collapseImportsFormacion" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/importer/fda-1">Año 1 (Figuras)</Link>
                <Link className="collapse-item" to="/importer/fda-2">Año 2 (Figuras)</Link>
                <Link className="collapse-item" to="/importer/formacion-docente">Ciclo Formación Docentes</Link>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseImportsResults" aria-expanded="true" aria-controls="collapseImportsResults">
              <img src={uploadIcon} className="" style={{width: 32, margin: "0 0.64rem"}}/>
              <span>Importar Captura</span>
            </a>
            <div id="collapseImportsResults" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/importer/capture-idai">Importar Captura IDAI</Link>
                <Link className="collapse-item" to="/importer/capture-ice">Importar Captura ICE</Link>
              </div>
            </div>
          </li>

        </> : null
      }

      {userState.profile && userState.profile.role && userState.schoolYear.isCurrentSchoolYear ?
        <CurrentSchoolYearOptions /> : 
        <ArchiveSchoolYearOptions />
      }

      </ul>)
}

export default Sidebar;
