import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';
import HorizontalBar2 from '../../../components/Charts/HorizontalBar2';

const IDAI2Section = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="col-12 mt-5">
                    <span><b>Resultados por nivel de conceptualización de la escritura (IDAI)</b></span>
                </div>

                <div className="row align-items-center justify-content-center text-center mt-5">
                    <div className="col-12 mt-5">
                        <span style={{color: 'black', textAlign: 'center'}}>
                            <p><b>Resultados del IDAI</b></p>
                        </span>

                        {stateStatus && <HorizontalBar2
                            title="Primer grado"
                            horizontal={true}
                            porcent={true}
                            requestObj = {{
                                configParams: { // DataSource (custom, table)
                                    custom: 'responsesWithNoCaptureEnrol'
                                },
                                dateRange: null,
                                filters: {'$and': [
                                    filters, 
                                    {schoolYearId: currentSchoolYear.id},
                                    {grado: 1},
                                    {instrumentId: 1},
                                    {'$or': [
                                        {customScore: 'A'},
                                        {customScore: 'B'},
                                        {customScore: 'C'},
                                        {customScore: 'D'},
                                    ]},
                                ]},
                                dimension: {name: 'formName', label: ''},
                                dimensions: [{name: 'formId', label: ''}],
                                breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                metrics: [],
                                customMetrics: [],
                                colorLabels: {
                                    'A': '#ED7D32', 
                                    'B': '#FFBF00', 
                                    'C': '#92D051', 
                                    'D': '#06B050',
                                },
                                order: {
                                    dimension: 'formId',
                                    ascendent: true,
                                },
                                orderBy: 'ORDER BY formId ASC',
                            }}
                        />}

                        {stateStatus && <HorizontalBar2
                            title="Segundo Grado"
                            horizontal={true}
                            porcent={true}
                            requestObj = {{
                                configParams: { // DataSource (custom, table)
                                    custom: 'responsesWithNoCaptureEnrol'
                                },
                                dateRange: null,
                                filters: {'$and': [
                                    filters, 
                                    {schoolYearId: currentSchoolYear.id},
                                    {grado: 2},
                                    {instrumentId: 1},
                                    {'$or': [
                                        {customScore: 'A'},
                                        {customScore: 'B'},
                                        {customScore: 'C'},
                                        {customScore: 'D'},
                                    ]},
                                ]},
                                dimension: {name: 'formName', label: ''},
                                dimensions: [{name: 'formId', label: ''}],
                                breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                metrics: [],
                                customMetrics: [],
                                colorLabels: {
                                    'A': '#ED7D32', 
                                    'B': '#FFBF00', 
                                    'C': '#92D051', 
                                    'D': '#06B050',
                                },
                                order: {
                                    dimension: 'formId',
                                    ascendent: true,
                                },
                                orderBy: 'ORDER BY formId ASC',
                            }}
                        /> }

                        <div className="pagebreak" />

                        {stateStatus && <HorizontalBar2
                            title="Tercer Grado"
                            horizontal={true}
                            porcent={true}
                            requestObj = {{
                                configParams: { // DataSource (custom, table)
                                    custom: 'responsesWithNoCaptureEnrol'
                                },
                                dateRange: null,
                                filters: {'$and': [
                                    filters, 
                                    {schoolYearId: currentSchoolYear.id},
                                    {grado: 3},
                                    {instrumentId: 1},
                                    {'$or': [
                                        {customScore: 'A'},
                                        {customScore: 'B'},
                                        {customScore: 'C'},
                                        {customScore: 'D'},
                                    ]},
                                ]},
                                dimension: {name: 'formName', label: ''},
                                dimensions: [{name: 'formId', label: ''}],
                                breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                metrics: [],
                                customMetrics: [],
                                colorLabels: {
                                    'A': '#ED7D32', 
                                    'B': '#FFBF00', 
                                    'C': '#92D051', 
                                    'D': '#06B050',
                                },
                                order: {
                                    dimension: 'formId',
                                    ascendent: true,
                                },
                                orderBy: 'ORDER BY formId ASC',
                            }}
                        /> }

                    </div>
                </div>
            </div>
        </div> }
    </div>)
}

export default IDAI2Section;
