import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Loading from '../../Loading';

const Third = (props) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);

     React.useEffect(()=>{
        setIsLoading(true);
        loadOptions();
        validateInitialData();
        setIsLoading(false);
    }, [props.rows, props.columns]);

    const loadOptions = async() => {
        await props.columns.forEach(async(column) => {
            if(column.editor && column.editor.async === true){
                column.editor.options = await column.loadOptions();
            }
        });
    }

    const validateInitialData = () => {
        const _rows = props.rows.map((row, index) => {
            return validateRow(row);

        });
        setRows(_rows);
    }

    const validateRow = (row) => {
        row.messages = '';
        props.columns.forEach((column) => {
            if(column.actions){
                return;
            }

            if (column.required){
                if((!row[column.dataField] || !String(row[column.dataField]).trim()) &&
                    column.customType !== 'number'){
                    row.errors[column.dataField] = true;
                    return;
                }else{
                    row.errors[column.dataField] = false;
                }
            }

            // NEW
            if (column.editor && column.editor.type === 'select' ){
                const value = row[column.dataField];
                const option = column.editor.options.find((option) => {
                    if (option.label === value) { return option; }
                });

                if(option){
                    row[column.dataField] = option.value
                }
            }

            if (column.editor && column.editor.type === 'select' ){
                if(!column.editor.options.map(option => String(option.value).toUpperCase()).includes(row[column.dataField]? String(row[column.dataField]).trim().toUpperCase(): '')){
                    row.errors[column.dataField] = true;
                }else{
                    row.errors[column.dataField] = false;
                }
            }

            if (column.editor && column.editor.type === 'checkbox' ){
                const value = row[column.dataField];

                if (!value) {
                    row[column.dataField] = false;
                    row.errors[column.dataField] = false;

                }else if(typeof value === 'string') {
                    if (['1','t','true','y','yes','s','si','sí'].includes(value.toLowerCase())) {
                        row[column.dataField] = true;
                        row.errors[column.dataField] = false;

                    } else if (['0','f','false','n','no'].includes(value.toLowerCase())) {
                        row[column.dataField] = false;
                        row.errors[column.dataField] = false;

                    } else {
                        row.errors[column.dataField] = true;
                    }
                }else if(typeof value === 'number') {
                    if (value === 1) {
                        row[column.dataField] = true;
                        row.errors[column.dataField] = false;

                    } else if (value === 0) {
                        row[column.dataField] = false;
                        row.errors[column.dataField] = false;

                    } else {
                        row.errors[column.dataField] = true;
                    }
                }else if(typeof value === 'boolean') {
                    row.errors[column.dataField] = false;
                }else{
                    row.errors[column.dataField] = true;
                }
            }


            if (column.customType && column.customType === 'number' ){
                let value2 = row[column.dataField];
                try { value2 = Number(value2) } catch(e) { }

                if(!value2){
                    row[column.dataField] = 0;
                    row.errors[column.dataField] = false;

                }else if(typeof value2 === 'number'){
                    row.errors[column.dataField] = false;

                }else if(typeof value2 === 'string') {
                    if (['1', 't','true','y','yes','s','si','sí'].includes(value2.toLowerCase())) {
                        row[column.dataField] = 1;
                        row.errors[column.dataField] = false;

                    } else if (['0','f','false','n','no'].includes(value2.toLowerCase())) {
                        row[column.dataField] = 0;
                        row.errors[column.dataField] = false;

                    } else {
                        row.errors[column.dataField] = true;
                    }

                }else{
                    row.errors[column.dataField] = true;
                }
            }

        });

        row.messages = formatMessage(row);

        return row;

    }

    const formatMessage = (row) => {
        if(row.messages === 'ready'){
            return '¡Listo!';
        }
        const result = [];
        Object.keys(row.errors).forEach((key) => {
            if(row.errors[key] === true){
                result.push(key);
            }
        });
        return JSON.stringify(result);

    };

    const hasErrors = () => {
        if(!rows || rows.length === 0){
            return true;
        }

        const result = [];
        rows.map(row => row.errors).forEach((errs) => {
            Object.keys(errs).forEach((key) => {
                if(errs[key] === true){
                    result.push(key);
                }
            });
        })
        
        return result.length !== 0;

    };

    const rowStyle = (row, rowIndex) => {
        const style = {};

        Object.keys(row.errors).forEach((key) => {
            if(row.errors[key] === true){
                style.backgroundColor = '#ffaba8';
            }
        });

        if(row.messages === 'ready'){
            style.backgroundColor = '#a8ffb3';
        }

        return style;
    };

    const afterSaveCell = (oldValue, newValue, row, column) => {
        const newState = rows.map(obj => {
            if(obj.KEY === row.KEY){
                return validateRow(row);
            }
            return obj;
        });
        setRows(newState);
    }

    const _toggleKey = (oldKey) => {
        if(typeof oldKey === 'number'){
            return String(oldKey).concat('-refresh');
        }

        if(typeof oldKey === 'string'){
            return Number(oldKey.replace('-refresh', ''));
        }
    }

    const loadInfo = async() => {
        setIsLoading(true);

        for (const row of rows) {
            const newRow = {...row};
            delete newRow['KEY'];
            delete newRow['actions'];
            delete newRow['errors'];
            delete newRow['errmessagesors'];
            delete newRow['messages'];

            const answers = Object.keys(newRow)
                .filter(key => key.startsWith("_"))
                .map(key => {
                    const newKey = Number(key.replace("_", ""));
                    const newValue = newRow[key];
                    delete newRow[key];
                    return {
                        moduleId: newKey,
                        progress: newValue
                    }
                });

            newRow.answers = answers;

            await props.saveInfo(newRow)
                .then(result => {
                    row.messages = 'ready';

                }).catch(error => {
                    console.log('error', error);
                    if (error.code === "ERR_NETWORK") {
                        retryOnce(row, newRow).then(result => {
                            row.errors['messages'] = result.errors;
                            row.messages = result.messages;
                        });

                    }else{
                        row.errors['messages'] = true;
                        row.messages = error.response && error.response.data? error.response.data.message : '';                        
                    }
                });
            row.KEY = _toggleKey(row.KEY);
        }

        setRows(rows);
        setIsLoading(false);

    }

    const retryOnce = async(row, newRow) => {
        const result = {};

        if (alert('La conexión ha sido interrumpida, desea continuar?')) {
            // eslint-disable-line
        }

        await props.saveInfo(newRow)
        .then(result => {
            result.messages = 'ready';
            result.errors = false;

        }).catch(error => {
            console.log('error', error);
            result.errors = true;

            if (error.code === "ERR_NETWORK") {
                result.messages = "ERR_NETWORK";

            } else {
                result.messages = error.response && error.response.data? error.response.data.message : '';
            }
        });

        return result;
    };

    const validate = () => {
        if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
            props.previousStep();
        }
    };

    return (<>
        <div className='container mb-5 mt-5'>
            <div className='row justify-content-center pb-5'>
                <div className="col-9">
                    <h3 className='mb-3'>Validar Datos</h3>
                    <h5>Favor de corregir las celdas resaltadas en rojo.</h5>
                </div>
                <div className="col-3">
                    <button type="button" disabled={hasErrors()} onClick={() => {loadInfo()}} className="btn btn-block btn-primary mt-3 lift">
                      Cargar Datos
                    </button>
                </div>
            </div>
        </div>
        <div className='row justify-content-center pb-5'>
            <div className="col-12">
                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
                <BootstrapTable 
                    keyField='KEY'
                    wrapperClasses="table-responsive" 
                    data={ rows } 
                    columns={ props.columns }
                    rowStyle={ rowStyle }
                    cellEdit={ cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell
                    }) }
                />
            </div>
        </div>     
    </>);
};

export default Third;