import React, { useEffect, useState, createRef } from 'react';
import Loading from '../../Loading';
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CardBasic from '../../Cards/Basic';
import reports from '../../../services/reports/ReportsService';

Chart.defaults.global.defaultFontFamily = 'Nunito';
Chart.defaults.global.defaultFontColor = '#858796';
Chart.plugins.register(ChartDataLabels);

// Change default options for ALL charts
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  color: '#FFF'
});

const ChartDonut2 = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const chartRef = createRef();

    useEffect(()=>{init()},[]);

    const init = async() => {
        if(isInit){
            return;
        }
        const myChartRef = chartRef.current.getContext("2d");

        setIsInit(true);
        setIsLoading(true);

        reports.getData(props.requestObj).then(result => {
            renderGraph(myChartRef, result);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

    }

    const renderGraph = (ref, data) => {
        const total = data.reduce((accumulator, row) => {
          return accumulator + Number(row.recordCount);
        }, 0);


        new Chart(ref, {
            type: 'doughnut',
            options: {
                cutoutPercentage: 75,
                plugins: {
                    datalabels: {
                        color: 'black',
                    }
                },
            },
            plugins: [ChartDataLabels],
            data: {
                labels: data.map(item => item[props.requestObj.dimensions[0].name]),
                datasets: [...props.requestObj.metrics, ...props.requestObj.customMetrics].map(metric => ({
                    data: data.map(item => toFormat(item[metric.name], total, props.porcent)),
                    backgroundColor: data.map(item => colorLabels[item[props.requestObj.dimensions[0].name]]),
                }))
            },
        });
    }


    return (<>

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <br/>

        <span style={{color: 'black', textAlign: 'center'}}>
            <p><b>{props.title}</b></p>
        </span>

        <div className="chart-area">
            <canvas id="myAreaChart" ref={chartRef}></canvas>
        </div>
        {/*
            <hr />
            Styling for the area chart can be found in the <code>/Components/Charts/Line/Index.js</code> file.
        */}

    </>)
}

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const toFormat = (value, total, porcent) => {
    if(porcent){
        return (Number(value/total)*100).toFixed(2);
    }else{
        return value;
    }
}

const randomColor = () => {
    return "hsla(" + ~~(360 * Math.random()) + ",90%,80%,1)";
}

const adjust = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const colorLabels = {
    'Evaluado': 'rgb(52, 168, 83)', 
    'No Evaluado': 'rgb(234, 67, 54)', 
}

const options = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.2,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return value;
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

const optionsPorcent = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
            ticks: {
                callback: function(value, index, values) {
                    return String(value) + ' %';
                }
            }
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.2,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return ''.concat(String(value), '%');
                }else{
                    value = "";
                    return value;
                }
            },
            color: 'black',

            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

const optionsPorcentBold = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
            ticks: {
                callback: function(value, index, values) {
                    return String(value) + ' %';
                }
            }
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.5,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return ''.concat(String(value), '%');
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}



export default ChartDonut2;
