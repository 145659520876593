import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import NumberTypeRender from '../render-components/NumberTypeRender';
import auth from '../../../services/users/AuthService';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createPrincipal(data);

const columns = [{
  dataField: 'cct',
  text: 'CCT Escuela',
  description:'CCT de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'firstName',
  text: 'Nombre',
  description:'Nombre del Director',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'lastName',
  text: 'Primer Apellido',
  description:'Primer Apellido del Director',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'secondLastName',
  text: 'Segundo Apellido',
  description:'Segundo Apellido del Director',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'email',
  text: 'Correo Electrónico',
  description:'Correo Electrónico del Director',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'curp',
  text: 'CURP',
  description:'CURP del Director',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'phone',
  text: 'Teléfono Móvil',
  description:'Teléfono Móvil del Director',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'position',
  text: 'Función (Director, Subdirector, Docente con función de director)',
  description:'Función (Director, Subdirector, Docente con función de director)',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'serviveYears',
  text: 'Años de Servicio',
  description: 'Años de Servicio',
  editable: true,
  required: false,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},{
  dataField: 'age',
  text: 'Edad',
  description: 'Edad',
  editable: true,
  required: false,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},
{
  dataField: 'actions',
  text: 'Acciones',
  editable: false,
  required: false,
  actions: true,
},{
  dataField: 'messages',
  text: 'Mensajes',
  editable: false,
  required: false,
  actions: true,
}];

export {columns, saveInfo};
