import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import HorizontalBar from '../../components/Charts/HorizontalBar';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';

const IMEI = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    gtag('event', 'page_view', {
      page_title: 'Índice de Implementación por Escuela (IEI)',
      page_location: 'indice-iei',
      page_path: '/indice-iei',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });
    
    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);

            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const downloadReport = () => {
        setIsLoading(true);
        auth.downloadAnswersEmei(stateSelectedId, 25).then(result => {
            gtag('event', 'download_imei_answers', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadReportSurvey = () => {
        setIsLoading(true);
        auth.downloadAnswersSurvey(stateSelectedId, 34).then(result => {
            gtag('event', 'download_survey_answers', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    return (<>

        <CardBasic title="">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }

            <div className='container'>
                <div className="row mt-5">
                    <div className="col-3 ml-auto">
                        <button type="button" onClick={() => {downloadReport()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Respuestas EMEI
                        </button>
                    </div>
                    <div className="col-3">
                        <button type="button" onClick={() => {downloadReportSurvey()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Respuestas Encuesta
                        </button>
                    </div>
                </div>
            </div>

        </CardBasic>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default IMEI;
