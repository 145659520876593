import React from 'react';
import { useSelector } from 'react-redux';

const TeacherProfile = (props) => {

    let profile = useSelector(state => state.userState.profile);

    return (<>

        {profile && profile.docente && profile.docente.school &&

            <div className='container mb-5 mt-5'>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Estado: </b>
                            {profile.docente.school.state_name}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Región: </b>
                            {profile.docente.school.region_name}
                            - {profile.docente.school.region_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Sector: </b>
                            {profile.docente.school.sector_name}
                            - {profile.docente.school.sector_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Zona: </b>
                            {profile.docente.school.zone_name}
                            - {profile.docente.school.zone_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Escuela: </b>
                            {profile.docente.school.school_name}
                            - {profile.docente.school.school_cct}
                        </p>
                    </div>
                </div>
            </div>
        }

    </>)
}

export default TeacherProfile;
