import React from 'react';
import auth from '../../../../services/users/AuthService';
import Table from '../../../../components/Charts/Table';
import mathImg from'../../../../assets/unesco/img/math-questions.png';
import BarTagsChart from '../../../../components/Charts/BarTags';


const SummarySection = ({props, schoolId, currentSchoolYear}) => {

    const [school, setSchool] = React.useState(null);
    const [statistics, setStatistics] = React.useState(null);
    const [risk, setRisk] = React.useState(0);

    React.useEffect(()=>{
        auth.getSchool(schoolId).then(school => {
            setSchool(school);
        }).catch(error => {
            console.log('err', error);
        });

        auth.getSchoolMathStatistics(schoolId, currentSchoolYear.id).then(statistics => {
            setStatistics(statistics);
        }).catch(error => {
            console.log('err', error);
        });

         auth.getSchoolMathRisk(schoolId, currentSchoolYear.id).then(result => {
            setRisk(result);
        }).catch(error => {
            console.log('err', error);
        });
    }, []);


    return (<>

        {school && statistics && 
          <div className="container mb-5">
            
            <div className="row align-items-center text-center">
              <div className="col-12">
                <h6>
                  <span>POLÍTICA DE APRENDIZAJES FUNDAMENTALES</span><br/>
                  <span>{currentSchoolYear.name}</span><br/>
                  <b>REPORTE MATEMÁTICAS</b><br/>
                  <b>Resultados de Diagnóstico</b><br/>
                </h6>
              </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
              <div className="col-12">
                  <p style={{backgroundColor:'#4371C4', color: 'white', padding: '10px 10%'}}><b>Entidad:</b> {school.state_name} <b>Escuela:</b> {school.school_name}  <b>CCT:</b> {school.school_cct}  <b>Zona escolar:</b> {school.zone_name} - {school.zone_cct} <b>Sector:</b> {school.sector_name} - {school.sector_cct}  <b>Región:</b> {school.region_name} - {school.region_cct} </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
              <div className="col-12">
                  <h4>1. Descripción</h4>
                  <p>
                    La prueba utilizada se ha diseñado teniendo en cuenta los aprendizajes básicos que se esperan de todo estudiante al final del grado.
                    Es de <b>esperarse que al aplicar la prueba al comienzo del primer grado los resultados no sean altos</b>. Sin embargo, en algunos
                    aspectos, como el sentido numérico, se espera que los resultados no sean demasiado bajos, pues ello refleja una débil preparación
                    del estudiante para asumir los aprendizajes de primer grado, aspecto que se detallará más adelante en este informe.
                  </p>
              </div>
            </div>

            <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
              <div className="col-12">
                <h4>Resultados de la institución</h4>

                <Table
                    title="Docentes Formados por Estado"
                    style = {{
                        pagination: false
                    }}
                    requestObj = {{
                        configParams: { // DataSource (custom, table)
                            custom: 'mathResponses2',
                        },
                        filters: {'$and': [
                            {schoolId},
                            {schoolYearId: currentSchoolYear.id},
                        ]},
                        dateRange: null,
                        dimensions: [
                            {name: 'schoolName', label: ''},
                        ],
                        breakdownDimension: null,
                        metrics: [
                          {name: 'evaluadoInicial', label: 'Estudiantes evaluados (Inicial)', aggregation: 'sum', priority: 3, minWidth: '150px'},
                          {name: 'evaluadoFinal', label: 'Estudiantes evaluados (Final)', aggregation: 'sum', priority: 5},
                        ],
                        customMetrics: [
                            {name: 'total', label: 'Estudiantes totales', color: '#1665c0', aggregation: 'count', custom: "*"},
                        ]

                    }}
                />



                {/*

                <Table
                    title=""
                    totals={false}
                    style = {{
                        pagination: false
                    }}
                    requestObj = {{
                        configParams: { // DataSource (custom, table)
                            custom: 'mathResponses',
                        },
                        filters: {'$and': [
                            {schoolId}, 
                            {schoolYearId: currentSchoolYear.id},
                            {'$or': [
                                {formId: 38},
                                {formId: 39},
                            ]},
                        ]},
                        dateRange: null,
                        dimensions: [{name: 'formName', label: 'Momento'}],
                        breakdownDimension: null,
                        metrics: [
                          {name: 'calificacionByTag', label: 'Calificacion por Tag', aggregation: 'sum', priority: 3, minWidth: '150px'},
                          {name: 'calificacion', label: 'Calificacion', aggregation: 'sum', priority: 4},
                        ],
                        customMetrics: [
                          {name: 'recordCount', label: 'Total estudiantes por grado', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                        ],
                        colorLabels: {
                            'Sentido Numérico': '#4472C4', 
                            'Operaciones': '#ED7D32', 
                            'Problemas': '#A5A5A5',
                        },
                        order: {
                            dimension: 'formName',
                            ascendent: true,
                        },
                        orderBy: 'ORDER BY formName ASC',
                    }}
                /> */}
              </div>
            </div>

            <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
              
              <div className="col-6">
                <BarTagsChart
                  title=""
                  porcent={true}
                  requestObj = {{
                    configParams: { // DataSource (custom, table)
                        custom: 'mathResponses'
                    },
                    dateRange: null,
                    filters: {'$and': [
                        {schoolId}, 
                        {schoolYearId: currentSchoolYear.id},
                        {'$or': [
                            {tag: 'Sentido Numérico'},
                            {tag: 'Operaciones'},
                            {tag: 'Problemas'},
                        ]},
                    ]},
                    dimension: {name: 'formName', label: ''},
                    dimensions: [{name: 'formId', label: ''}],
                    breakdownDimension: {name: 'tag', label: 'Grupo'},
                    metrics: [
                      {name: 'calificacionByTag', label: 'Calificacion por Tag', aggregation: 'sum', priority: 3, minWidth: '150px'},
                      {name: 'calificacion', label: 'Calificacion', aggregation: 'sum', priority: 4},
                    ],
                    customMetrics: [
                      {name: 'porcentaje', label: 'Record', color: '#fbd54e', aggregation: 'none', custom: "FORMAT(SUM(calificacionByTag)/SUM(calificacion), 2)"},
                    ],
                    colorLabels: {
                        'Sentido Numérico': '#4472C4', 
                        'Operaciones': '#ED7D32', 
                        'Problemas': '#A5A5A5',
                    },
                    order: {
                        dimension: 'formId',
                        ascendent: true,
                    },
                    orderBy: 'ORDER BY formId ASC',
                  }}
                />
              </div>

              <div className="col-6">
                <p className="m-0"><b>Aspectos que se valoran:</b></p>
                <p className="m-0"><i>Sentido numérico</i></p>
                
                <p className="m-0">Da cuenta de la capacidad de los estudiantes para:</p>
                <ul type="disc" style={{listStyle: "disc"}}>
                  <li>Contar de forma estable y confiable</li>
                  <li>Identificar cantidades más grandes y más pequeñas</li>
                  <li>Representar cantidades de diferentes formas</li>
                  <li>Identificar patrones en secuencias y completarlas</li>
                </ul>

                <p className="m-0">Operaciones</p>
                <p className="m-0">Aborda dos habilidades:</p>
                <ul type="disc" style={{listStyle: "disc"}}>
                  <li>Poder sumar y restar pequeñas cantidades, no necesariamente con métodos
                      convencionales.</li>
                  <li>Poder encontrar términos faltantes en una expresión matemática</li>
                </ul>

                <p className="m-0">Problemas</p>
                <p className="m-0">Aborda la capacidad para resolver pequeños ejercicios en palabras, así como
                    resolver problemas de lateralidad y ubicación.
                </p>
              </div>

              <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
                <div className="col-12">
                  <table className="table table-responsive table-bordered">
                    <tbody>
                      <tr>
                        <td style={{padding: '0.25rem', backgroundColor: '#E0E0E0' }}><b>Estudiantes en riesgo: {risk}</b></td>
                      </tr>
                      <tr>
                        <td style={{padding: '0.25rem' }}>Estudiantes que lograron 2 o menos de las tareas de sentido numérico. Estos estudiantes muy probablemente no tienen los
                        conocimientos mínimos indispensables para abordar los aprendizajes de primer grado y en consecuencia requieren de un
                        refiuerzo. Para empezar el grado 1 el estudiante debe tener la habilidad de contar unos 10 a 15 objetos sin equivocarse.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
                <div className="col-12">
                  <h4>3. Recomendaciones y evolución prevista</h4>
                  <p>En general, el material de MATH-PREST aborda todos los aprendizajes, de modo que los estudiantes tendrán la posibilidad de
                  aprender lo que requieren a lo largo del ciclo escolar, para lo cual se debe dar una implementación rigurosa de la propuesta y
                  avanzar a una velocidad tal que permita <b>hacer al menos 4 unidades completas</b>. El único aspecto que requiere de una atención
                  especial es el de los estudiantes que lograron un <b>resultado muy bajo en sentido numérico</b>: 2 o menos respuestas correctas. Para
                  ello se propone verificar con todo el grupo de estudiantes y, particularmente, con quienes obtuvieron bajos resultados en la prueba,
                  si tienen la capacidad para un conteo estable, lo cual implica:</p>
                  <ul type="disc">
                    <li>- Conocen la secuencia de palabras para contar hasta 20 de forma estable, sin saltarse números.</li>
                    <li>- Mientras cuenta puede asociar a cada palabra uno de los objetos de la colección.</li>
                    <li>- Al contar tiene estrategias para no olvidar o repetir un objeto en el conteo.</li>
                    <li>- Sabe que la última palabra representa la cantidad y puede asociar el símbolo respectivo.</li>
                  </ul>
                </div>
              </div>

              <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
                <div className="col-12">
                  A lo largo del año escolar será necesario, igualmente, identificar estudiantes que tienen dificultades para apoyarlos utilizando una
                  estrategia de diferenciación apropiada en el aula. Las estrategias de remediación deberían ser el último recurso a utilizar
                </div>
              </div>

              <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
                <div className="col-12">
                  Las preguntas del instrumento diagnóstico se agrupan por tarea de la siguiente forma:
                </div>
              </div>
              <div className="row align-items-center justify-content-center text-left mt-5" style={{color: "black"}}>
                <div className="col-10">
                  <img  src={mathImg} className="w-100" alt="Tabla Mateméticas"/>
                </div>
              </div>
          </div>
        </div>}
      </>)
}

export default SummarySection;

const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    },
    td2: {
        padding: '0.25rem',
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: '14px',
        border: '1px solid gray'
    }
}
