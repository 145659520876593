import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2'
import auth from '../../../services/users/AuthService'
import { gtag } from 'ga-gtag';
import $ from 'jquery';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";

const passwordForm = FormBuilder.group({
  password: ["", Validators.required],
  confirm_password: ["", Validators.required],
});

const ChangePasswordModal = ({ props, onPasswordChanged, userId }) => {

  let userState = useSelector(state => state.userState);
  const [isLoading, setIsLoading] = useState(false);


  React.useEffect(() => {
    $('#modalChangePassword').on('hidden.bs.modal', (e) => {
      // do something...
      passwordForm.reset();
    });
  },[]);

 

  const handleReset = () => {
    passwordForm.reset();
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if (passwordForm.value.password !== passwordForm.value.confirm_password) {
      passwordForm.controls.confirm_password.setErrors({ invalid: true });
      return;
    } else {
      passwordForm.controls.confirm_password.setErrors(null);
    }

    const data = {
      id: userId,
      password: passwordForm.value.password.trim(),
    }

    setIsLoading(true);
    auth.updateUser(userId, data).then(result => {
      // setRoles(result);
      setIsLoading(false);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: 'Contraseña actualizada',
        text: '',
      }).then((result) => {
          passwordForm.reset();
          $("#modalChangePassword").modal("hide");
      });

    }).catch(err => {
      console.log('err', err);
      setIsLoading(false);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: 'Oops...',
        text: err.error.message,
        footer: 'Ocurrió un error al actualizar la contraseña.'
      });
    });

  }

  const onInput = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
    e.target.value = e.target.value.replace(/[^a-zA-ZáÁéÉíÓóÚúÜü ]/i, "");
  }

  return (
    <div className="modal fade" id="modalChangePassword" tabIndex={-1} role="dialog" aria-labelledby="modalChangePasswordTitle" aria-hidden="true">
      <div className="modal-dialog extended modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
            {/* Close */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <div className="container mb-11 mt-5">
              <div className="row">
                <div className="col-lg-11">
                  <h3>ACTUALIZAR CONTRASEÑA</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <FieldGroup
                      control={passwordForm}
                      render={({ get, invalid }) => (
                        <form className="user" onSubmit={handlePasswordSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                              <FieldControl
                                name="password"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="password" {...handler()} className="form-control" placeholder="Contraseña" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Contraseña obligatoria'}
                                    </span>
                                  </div>
                                )}
                                meta={{ label: "Contraseña" }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <FieldControl
                                name="confirm_password"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="password" {...handler()} className="form-control" placeholder="Confirma Contraseña" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Confirmación obligatoria'}
                                    </span>
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('invalid') && 'La contraseña no coincide'}
                                    </span>
                                  </div>
                                )}
                                meta={{ label: "Confirma Contraseña" }}
                              />
                            </div>
                          </div>
                          <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                            CAMBIAR CONTRASEÑA
                          </button>
                        </form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordModal;
