import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableOutline from '../../../../components/Charts/TableOutline';
import TableGroups from '../../../../components/Charts/TableGroups';
import Bar3 from '../../../../components/Charts/Bar3';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';

const ICESection = ({schoolId, currentSchoolYear}) => {

    let userState = useSelector(state => state.userState);

    return (<>

        {!(schoolId && currentSchoolYear)? null : <> 
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>8. Resultados del ICE, tercer grado</b>
                        </p>
                    </span>
                </div>
            </div>
            
            <div className="row justify-content-center text-center mb-5">
                <div className="col-8">
                    <Bar3
                        title="Tercer grado"
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023Enrol'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {schoolYearId: currentSchoolYear.id},
                                {grado: 3},
                                {'$or': [
                                    {formId: 43},
                                    {formId: 44},
                                    {formId: 45},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                '1': '#ff0000', 
                                '2': '#feff00', 
                                '3': '#bfe1b0', 
                                '4': '#02b342',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />
                </div>
                <div className="col-4">
                    <p style={{color: 'black', textAlign: 'left'}}>Con la aplicación del ICE te será posible identificar los conocimientos ―nivel y uso― que tiene cada estudiante sobre las <b>convencionalidades de la escritura</b>, por ejemplo: el empleo de mayúsculas, minúsculas y signos de puntuación.</p>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            El nivel obtenido por cada estudiante te facilitará la elección de las secuencias didácticas que se proponen en el documento ¿Qué actividades funcionan para impulsar el aprendizaje de la lengua
                             escrita? (consultar a partir de la página 8 ): <a target="_blank" href="https://drive.google.com/file/d/151uyVmN_cMBpTTXLdfrMi4SnZ88-tFql/view?usp=sharing">https://drive.google.com/file/d/151uyVmN_cMBpTTXLdfrMi4SnZ88-tFql/view?usp=sharing</a>
                        </p>
                    </span>
                </div>
            </div>

             <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{color: '#003866', paddingBottom: '2rem', fontSize: '16px', fontWeight:'bold'}}>
                        Recuerda que la mejora de aprendizajes basada en evidencias es un proceso continuo. Con el siguiente Reporte Escuela podrás contrastar estos resultados y el avance del proceso de alfabetización de las y los estudiantes. 
                        <br/>
                        <br/>
                        ¡Todas y todos contribuimos a que las niñas y los niños de nuestra escuela logren los aprendizajes fundamentales en lengua!
                    </p>
                </div>
            </div>
        </>}
    </>);
}

export default ICESection;
