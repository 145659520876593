import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../../services/users/AuthService';
import CardBasic from '../../../../components/Cards/Basic';

import RegionsListSection from './../../sections/RegionsListSection';
import RegisterDocenteSection from './../../sections/RegisterDocenteSection';


const RegionalPage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const userId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        // getStates();
    },[]);

    /*

    const getStates = () => {
        setIsLoading(true);

        auth.getStates().then(result => {
            setStates(result);
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
        
    }
    */

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile)? userState.profile.username : ''
    });

    return (<div>
        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faUser} className="mr-2 tab-icon" /><span>Usuario</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="teachers-tab" data-toggle="tab" href="#teachers" role="tab" aria-controls="teachers" aria-selected="false">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Regiones</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                {/* <RegisterDocenteSection /> */}
            </div>
          
            <div className="tab-pane fade" id="teachers" role="tabpanel" aria-labelledby="teachers-tab">
                <RegionsListSection />
            </div>

        </div>
      </div>)
}

export default RegionalPage;
