import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import CohortGraph from '../../components/Charts/CohortGraph';
import CohortGraphPerc from '../../components/Charts/CohortGraphPerc';
import Table from '../../components/Charts/Table';
import SimpleTable from '../../components/Charts/SimpleTable';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';

const CohortTeachers = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState(null);
    const [summary, setSummary] = useState([]);
    const [data, setData] = React.useState(null);

    const [onlyFormados, setOnlyFormados] = useState(false);
    const [onlyUnicos, setOnlyUnicos] = useState(false);

    gtag('event', 'page_view', {
      page_title: 'Retención',
      page_location: 'cohort',
      page_path: '/cohort',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(() => {
        getStates();
    },[]);

    React.useEffect(() => {
        getTeachersCohort(stateSelectedId);
    },[onlyFormados, onlyUnicos]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);

            }else{
                getTeachersCohort('', onlyFormados, onlyUnicos);
            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
            getTeachersCohort(stateId, onlyFormados, onlyUnicos);
        }, 100);
    };
    
    const getTeachersCohort = async(stateId) => {
        setIsLoading(true);
        auth.getTeachersCohort(stateId, onlyFormados, onlyUnicos).then(result => {
            console.log('cohort', result);
            setData(result);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });
    }


    const Checkbox = (props) => {
        return  <div className="form-check form-check-inline">
            <input
                type="checkbox"
                name={props.name}
                checked={props.value} 
                onChange={() => {props.setValue(!props.value)}}
                className="btn-check"
                id={`btn-${props.name}`}
                autoComplete="off"
            /><label className="btn btn-outline-primary" htmlFor={`btn-${props.name}`}>
                {props.label}
            </label>
          </div>
    }

    return (<>

        <CardBasic title="">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }

            <div className='container'>

                <div className="row align-items-center justify-content-center text-left">
                    <div className="col-10">

                        <Checkbox value={onlyUnicos} setValue={setOnlyUnicos} label="Únicos" name="uniques"></Checkbox>

                        <Checkbox value={onlyFormados} setValue={setOnlyFormados} label="Formados" name="formados"></Checkbox>
                        
                    </div>
                </div>
                
                <div className="row align-items-center justify-content-center text-center">
                    <div className="col-10">

                        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                        <div className="container">
                            <div className="row align-items-center text-center mb-3 mt-3">
                                <div className="col-12">
                                    <h4>
                                        <b>Retención de Docentes</b><br/>
                                    </h4>
                                    {data && data.school && <h6>
                                        <p className="mb-0 mt-0">{data.school.school_cct} - {data.school.school_name}</p>
                                        <p className="mb-0 mt-0">IEI: <b>{Number(data.value)*100}%</b></p>
                                    </h6>}
                                </div>
                            </div>
                        </div>

                        {data && <div className="container">
                            <div className="row align-items-center justify-content-center text-center mb-5 mt-3 pb-5">
                                <div className="col-12">

                                  <CohortGraph data={data} title='Docentes' plural='docentes'  />

                                </div>
                            </div>

                            <div className="row align-items-center justify-content-center text-center mb-3 mt-5">
                                <div className="col-12">

                                  <CohortGraphPerc data={data} title='Docentes' plural='docentes' />

                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>

        </CardBasic>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '1rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '1rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default CohortTeachers;
