import React from 'react';
import imgGoals1 from'../../../../assets/unesco/img/goals1.jpg';

const GoalsSection = ({props, schoolId}) => {

    return (<div className="container mb-5">

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>II. Metas para el final de ciclo escolar</b>
                                </p>
                                <p>
                                    <b>Estrategia de lengua</b>
                                </p>
                            </span>
                            <div className="container">
                                <div className="row align-items-center justify-content-center text-center">
                                    <div className="col-10">
                                        <img src={imgGoals1} className="img-fluid w-100" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div> }
    </div>)
}

export default GoalsSection;
