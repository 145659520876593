import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import SimpleTable from '../../components/Charts/SimpleTable';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';

const Summary = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState(null);
    const [summary, setSummary] = useState([]);

    gtag('event', 'page_view', {
      page_title: 'Resumen',
      page_location: 'summary',
      page_path: '/summary',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });
    
    React.useEffect(() => {
        if (userState.profile && 
            userState.profile.role && 
            userState.profile.state && 
            userState.profile.role.id === Roles.ROLE_ESTATAL) {
                onStateIdChanged(userState.profile.state.state_id);

        } else {
            getStates();
        }       
    },[userState.schoolYear]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);
            getSummary(null);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const getSummary = (filters) => {
        setIsLoading(true);

        filters = {
            ...filters,
            schoolYearId: userState.schoolYear.id
        }

        auth.getSchoolSummary(filters).then(result => {
            setSummary(result);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        getSummary({
            state: stateId
        });

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const getTotals = (data, key) => {
      let total = 0;
      data.forEach(item => {
        total += Number(item[key]);
      });
      return total;
    };

    const downloadSchoolsReport = () => {
        setIsLoading(true);
        auth.downloadSchools(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_schools_list', {
                'username': (userState && userState.profile)? userState.profile.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    return (<>

        <CardBasic title="">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }


            <div className='container'>
                <div className="row mt-5">
                    <div className="col-3 ml-auto">
                        <button type="button" onClick={() => {downloadSchoolsReport()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Reporte (Escuelas)
                        </button>
                    </div>
                </div>
            </div>


            {stateStatus && summary && summary.schools &&
                <SimpleTable
                    title='Escuelas'
                    fulltitle='Resumen de Registros'
                    columns={[{
                        name: 'Nivel',
                        selector: 'rubro',
                    }, {
                        name: 'Escuelas',
                        selector: 'nopersonas', 
                    }]}
                    data={[{
                            'rubro': 'Total',
                            'nopersonas': getTotals(summary.schools, 'escuelas')
                        },...summary.schools.map((school) => {
                        return {
                            'rubro': school.level_name,
                            'nopersonas': school.escuelas
                        }
                    })]}
                />
            }

            {stateStatus && summary && summary.teachers &&
                <SimpleTable
                    title='Docentes'
                    columns={[{
                        name: 'Grado',
                        selector: 'rubro',
                    }, {
                        name: 'Docentes',
                        selector: 'nopersonas', 
                    }]}
                    data={[{
                            'rubro': 'Total',
                            'nopersonas': getTotals(summary.teachers, 'maestros')
                        },...summary.teachers.map((teacher) => {
                        return {
                            'rubro': teacher.grado? ('Grado: '+teacher.grado): 'Sin grupos',
                            'nopersonas': teacher.maestros
                        }
                    })]}
                />
            }

            {stateStatus && summary && summary.students &&
                <SimpleTable
                    title='Estudiantes'
                    columns={[{
                        name: 'Grado',
                        selector: 'rubro',
                    }, {
                        name: 'Estudiantes',
                        selector: 'nopersonas', 
                    }]}
                    data={[{
                            'rubro': 'Total',
                            'nopersonas': getTotals(summary.students, 'estudiantes')
                        },...summary.students.map((student) => {
                        return {
                            'rubro': student.grado? ('Grado: '+student.grado): 'Sin grupo',
                            'nopersonas': student.estudiantes
                        }
                    })]}
                />
            }

            {/*

            {stateStatus && <Table
                title="Resumen de Registros"
                totals={true}
                style = {{
                    pagination: false
                }}
                requestObj = {{
                    configParams: { // DataSource (custom, table)
                        custom: 'registersSummary'
                    },
                    filters,
                    dateRange: null,
                    dimensions: [
                        {name: 'estado', label: 'Estado'},
                    ],
                    breakdownDimension: null,
                    metrics: [
                        {name: 'escuelas', label: 'Escuelas', aggregation: 'sum', addTotal: true},
                        {name: 'docentes', label: 'Docentes', aggregation: 'sum', addTotal: true},
                        {name: 'estudiantes', label: 'Estudiantes', aggregation: 'sum', addTotal: true},
                        {name: 'grupos', label: 'Grupos', aggregation: 'sum', addTotal: true},
                    ],
                    customMetrics: null,
                }}
            />}
            */}

        </CardBasic>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default Summary;
