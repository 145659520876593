import React from 'react'
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import auth from '../../services/users/AuthService';
import { userLogOut } from '../../services/users/ActionTypes';
import ProfileSelection from './ProfileSelection';


function ProfilesSelectionWithoutAuth(props) {

  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let user = useSelector(state => state.userState.user);

  const isProfileSelected = auth.isProfileSelected();
  const isAuthenticated = auth.isAuthenticated();

  console.log('isAuthenticated', isAuthenticated);
  console.log('isAuthenticated', isAuthenticated);

  if(!isAuthenticated){
    let { from } = location.state || { from: { pathname: "/login" } };
    history.replace(from);

  } else if(!(user && user.profiles && user.profiles.length !== 0)){
    dispatch(userLogOut());
    auth.logout();
  }

  return (
       isProfileSelected
        ? (
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
        ) : (
          <ProfileSelection />   
        )  
  );
}

export default ProfilesSelectionWithoutAuth
