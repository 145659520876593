import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SchoolReportV4 from './SchoolReportV4';

const MathReport = (props) => {

	let userState = useSelector(state => state.userState);
    const schoolId = props.match.params.id;

	return (<>

		<SchoolReportV4 schoolId={schoolId} /> 

	</>);
}

export default MathReport;
