import React from 'react';
import { useSelector } from 'react-redux';

const ZoneSupervisorProfile = (props) => {

    let profile = useSelector(state => state.userState.profile);

    return (<>

        {profile && profile.zone &&

            <div className='container mb-5 mt-5'>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Estado: </b>
                            {profile.zone.state_name}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Región: </b>
                            {profile.zone.region_name}
                            - {profile.zone.region_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Sector: </b>
                            {profile.zone.sector_name}
                            - {profile.zone.sector_cct}
                        </p>
                    </div>
                </div>
                <div className='row mb-1'>
                    <div className="col-lg-6">
                        <p>
                            <b>Zona: </b>
                            {profile.zone.zone_name}
                            - {profile.zone.zone_cct}
                        </p>
                    </div>
                </div>
            </div>
        }

    </>)
}

export default ZoneSupervisorProfile;
