import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Export from "react-data-table-component";
import Loading from '../../Loading';
import reports from '../../../services/reports/ReportsService';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

const SimpleTable = (props) => {

    const downloadXLS = () => {
        const ws_name = props.title || '';
        const ws_data = [];

        const titles = props.columns.map(column => column.name);

        ws_data.push(titles);
        props.data.forEach((row) => {
            ws_data.push(props.columns.map(column => row[column.selector]))
        });

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: ws_name,
            Subject: ws_name,
            Author: "Siaf",
            CreatedDate: new Date()
        };
        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // fix width
        ws["!cols"] = titles.map(title => ({ wch: 20 }));

        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        var wopts = { bookType:"xlsx", bookSST:false, type:"array" };
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${ws_name}.xlsx`);

    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return <>
            <button type="button" onClick={downloadXLS} className="btn-primary">Descargar</button>
          </>
    });

    return (<>
        <div className="table-responsive" style={{display:'grid'}}>

            <DataTable
              title={props.fulltitle}
              columns={props.columns}
              data={props.data}
              customStyles={customStyles}
              subHeader
              noDataComponent="Sin registros"
              subHeaderComponent={subHeaderComponentMemo}
            />
        </div>
    </>)
}

const customStyles = {
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
            backgroundColor: '#E0E0E0',
            fontWeight: 'bold',
            fontSize: '14px',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
    headRow: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
};

export default SimpleTable;
