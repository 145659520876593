import { LOADING_SET_GLOBAL } from "./ActionTypes";

const initialState = {
    isGlobalLoading: false
}

export const LoadingReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case LOADING_SET_GLOBAL:
            return {
                ...state,
                isGlobalLoading: action.payload
            }; 
        default:
            return state;
    }
};