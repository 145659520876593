import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
  faMugHot,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../../services/users/AuthService';
import IconCCT from '../../../../components/IconCCT';

import BaseListAdapterWithOptions from './../../core/BaseListAdapterWithOptions';

const TeachersListSection = ({props, schoolId, teachers}) => {

  const CustomConfig = {
    singular: 'Docente',
    plural: 'Docentes',
    filters: ['firstName', 'lastName', 'secondLastName', 'cct', 'email'],
    excludes: ['nombre', 'docente', 'maestro', ':', 'cct'],
    options: {
      loadService: null,
      loadParams: null,
      deleteService: auth.deleteSupervisor,
      adminTo: "/schools/{id}"
    }
  }

  const CustomViewHolder = ({item}) => {
    const teacher = item;

    return (<>
      <div className="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
        {teacher.firstName? teacher.firstName.charAt(0): ''}{teacher.lastName? teacher.lastName.charAt(0): ''}
      </div>
      <div className="job-content">
        <h5 className="text-center text-md-left mb-0">{teacher.firstName} {teacher.lastName}  {teacher.secondLastName}</h5>
        <ul className="d-md-flex ff-open-sans pl-0 text-left">
          <li className="mr-md-4">
            <FontAwesomeIcon icon={faIdCard} /> {teacher.curp}<br/>
            <FontAwesomeIcon icon={faEnvelope} /> {teacher.email}<br/>
          </li>
        </ul>
      </div>
    </>);
  }

  return (
    <BaseListAdapterWithOptions 
      ItemViewHolder={CustomViewHolder}
      items2={teachers} 
      config={CustomConfig} 
    />
  );

}

export default TeachersListSection;
