import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import CardBasic from '../../../components/Cards/Basic';
import SimpleTable from '../../../components/Charts/SimpleTable';
import auth from '../../../services/users/AuthService';

const SummarySection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState([]);
    const [summary2, setSummary2] = useState([]);
    
   
   React.useEffect(()=>{
        setIsLoading(true);
        auth.getStateSummary(stateId, currentSchoolYear.id).then(result => {
            setSummary(result);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

        setIsLoading(true);
        auth.getStateSummary2(stateId, currentSchoolYear.id).then(result => {
            setSummary2(result);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

    }, [stateId, currentSchoolYear]);



    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className="row align-items-center justify-content-center text-center mt-5">
            <div className="col-12">
                <span style={{color: 'black', textAlign: 'justify'}}>
                    <p>
                        <b>Reporte general del fin de ciclo escolar {currentSchoolYear.name}</b>
                    </p>
                </span>
            </div>
        </div>

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                {summary &&
                    <SimpleTable
                        title='Resumen'
                        columns={[{
                            name: 'Estado',
                            selector: 'name',
                        }, {
                            name: 'Escuelas',
                            selector: 'escuelas', 
                        }, {
                            name: 'Estudiantes',
                            selector: 'estudiantes', 
                        }, {
                            name: 'Docentes',
                            selector: 'docentes', 
                        }, {
                            name: 'Figuras directivas',
                            selector: 'directores', 
                        }, {
                            name: 'Supervisores/ATPs',
                            selector: 'supervisors', 
                        }, {
                            name: 'Jefes de sector',
                            selector: 'jefes', 
                        }, {
                            name: 'Regionales',
                            selector: 'regionales', 
                        }, {
                            name: 'Estatales',
                            selector: 'statales', 
                        }]}
                        data={summary}
                    />
                }

            </div>
        </div>

        <div className="row align-items-center justify-content-center text-center mt-5">
            <div className="col-12">

                {summary2 &&
                    <SimpleTable
                        title='Resumen'
                        columns={[{
                            name: 'Estado',
                            selector: 'name',
                        }, {
                            name: 'Estudiantes 1°',
                            selector: 'estudiantes1', 
                        }, {
                            name: '2°',
                            selector: 'estudiantes2', 
                        }, {
                            name: '3°',
                            selector: 'estudiantes3', 
                        }, {
                            name: 'sin grupo',
                            selector: 'estudiantesSin', 
                        }, {
                            name: 'Docentes 1°',
                            selector: 'docentes1', 
                        }, {
                            name: '2°',
                            selector: 'docentes2', 
                        }, {
                            name: '3°',
                            selector: 'docentes3', 
                         }, {
                            name: 'sin grupo',
                            selector: 'docentesSin', 
                        }]}
                        data={summary2}
                    />
                }

            </div>
        </div>

    </div>)
}

export default SummarySection;
