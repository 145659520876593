import React, { useEffect, useState, createRef } from 'react';
import Chart from "chart.js";
import CardBasic from '../../Cards/Basic';

const ChartEvaluado = (props) => {

    const chartRef = createRef();
    const [isInit, setIsInit] = useState(false);

    useEffect(()=>{init()},[props.progress]);

    const init = async() => {
        if(isInit){
            return;
        }
        const myChartRef = chartRef.current.getContext("2d");
        renderGraph(myChartRef);  
    }

    const renderGraph = (ref, data) => {
        new Chart(ref, {
            type: 'doughnut',
            data: {
                labels: ["Evaluado", "No evaluado"],
                datasets: [{
                    data: [props.progress, 100-props.progress],
                    backgroundColor: ['#06B050', '#FF0002'],
                }],
            },
            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                tooltip: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        formatter: function(value, index, values) {
                            if(value >0 ){
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join('');
                                return ''.concat(String(value), '%');
                            }else{
                                value = "";
                                return value;
                            }
                        },
                    },
                },
                cutoutPercentage: 70
            },
        });
    }

    return (
        <>
            <h5 style={{fontWeight: 'bold'}}>{props.title}</h5>
            <div className="chart-pie pt-4">
                <canvas id="myPieChart" ref={chartRef}></canvas>
            </div>
            {/*
                <hr />
                Styling for the area chart can be found in the <code>/Components/Charts/Line/Index.js</code> file.
            */}
        </>
    )
}

const styles = {
    pProgress: {
        position: 'absolute',
        bottom: '18%',
        left: '42%',
        fontWeight: 'bold',
        fontSize: '1.5rem',
    }
}

export default ChartEvaluado;