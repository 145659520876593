import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import Loading from '../../../components/Loading';
import { gtag } from 'ga-gtag';
import CardBasic from '../../../components/Cards/Basic';
import SummarySection from './sections/SummarySection';
import EGRASection from './sections/EGRASection';
import GoalsSection from './sections/GoalsSection';
import IDAISection from './sections/IDAISection';
import ICESection from './sections/ICESection';
import auth from '../../../services/users/AuthService';

const SchoolReportV2 = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);

    const schoolId = props.schoolId;

    const [currentSchoolYear, setCurrentSchoolYear] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    gtag('event', 'page_view', {
      page_title: 'Reporte Escuela',
      page_location: 'school-report',
      page_path: '/school-report',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(()=>{
        setCurrentSchoolYear(null);
        setTimeout(()=>{
            setCurrentSchoolYear(userState.schoolYear);
        }, 200);

    }, [userState.schoolYear]);

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_school_report', {
            'version': 'v2',
            'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }

    return (<div className="container mb-5">
        <div className="col-11">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {schoolId && currentSchoolYear && !isLoading? <div className="row align-items-center justify-content-center text-center">

                <div className="container mb-5">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-4 pl-0"></div>
                        <div className="col-2 ml-auto mr-0 pr-0" onClick={(e) => {downloadReport(e);}}>
                            <ReactToPrint
                              trigger={() => <Button>Imprimir</Button>}
                              content={() => componentRef}
                            />
                        </div>
                    </div>
                </div>

                <div ref={(el) => (componentRef = el)}>

                    <CardBasic title="">
                        <SummarySection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                    </CardBasic>

                    {/*

                    <CardBasic title="">
                        <EGRASection schoolId={schoolId} />
                    </CardBasic>

                    */}

                    <CardBasic title="">
                        <GoalsSection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                    </CardBasic>

                    <CardBasic title="">
                        <IDAISection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                    </CardBasic>

                    <CardBasic title="">
                        <ICESection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                    </CardBasic>

                </div>
            </div> : null}
        </div>
    </div>)
}

export default SchoolReportV2;
