import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import Loading from '../../components/Loading';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import NumberTypeRender from '../../components/CsvImporter/render-components/NumberTypeRender';
import DecimalTypeRender from '../../components/CsvImporter/render-components/DecimalTypeRender';

const formId = 48;

const FormacionDocenteImporter = (props) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    auth.getQuestionsByFormId(formId).then(form => {
      createColumns(form.questions);

    }).catch(error => {
        console.log('err', error);
        setIsLoading(false);
    });
  }, []);


  const createColumns = (questions) => {
    const errorsStyle = (cell, row, rowIndex, colIndex) => {
      if(row.errors[cols[colIndex].dataField] && cols[colIndex].customType !== 'number'){
        return {
          backgroundColor: '#ED3833'
        };
      }
    }

    const cols = [];

    questions.forEach((m, i) => {
      cols.push({
        dataField: "question_"+String(m.id),
        text: m.questionText,
        description: m.description,
        editable: true,
        required: m.required,
        editor: {
          type: Type.SELECT,
          options: [{
           value: 'Ciclo 2021-2022',
            label: 'Ciclo 2021-2022'
          }, {
            value: 'Ciclo 2022-2023',
            label: 'Ciclo 2022-2023'
          }, {
            value: 'Ciclo 2023-2024',
            label: 'Ciclo 2023-2024'
          }]
        },
        style: errorsStyle
      });
    });

    cols.push({
      dataField: 'teacherEmail',
      text: 'Correo Electrónico Docente',
      description:'Correo Electrónico del Docente',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'curp',
      text: 'CURP',
      description:'CURP de la figura',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'actions',
      text: 'Acciones',
      editable: false,
      required: false,
      actions: true,
    },{
      dataField: 'messages',
      text: 'Mensajes',
      editable: false,
      required: false,
      actions: true,
    });

    setColumns(cols);
    setIsLoading(false);
  }

  const saveInfo = (data) => {

    console.log('data-->', data);
    data.customScore = data['question_197'];

    /*

    const responses = Object.keys(data)
      .filter(key => key.includes('question_'))
      .map(key => {
          const k = key.split('_')[1];

          return {
              questionId: k,
              customResponse: data[key],
              singleResponseId: null,
              optionId: null
          }
      });

      */


    // data.responses = responses;

    return auth.saveFormacionDocente(formId, data);
  }

  return (<>
    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {columns && columns.length !== 0 ?
      <CardBasic title="Importar Ciclo de Formación de los Docentes">
        <CsvImporter columns={columns} saveInfo={saveInfo} />
      </CardBasic>
    : null}
  </>);

}



export default FormacionDocenteImporter;