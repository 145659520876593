import React, { useEffect, useState, createRef } from 'react';
import Chart from "chart.js";
import CardBasic from '../../Cards/Basic';

const ChartSpeed = (props) => {

    const chartRef = createRef();
    const [isInit, setIsInit] = useState(false);

    useEffect(()=>{init()},[props.progress]);

    const init = async() => {
        if(isInit){
            return;
        }
        const myChartRef = chartRef.current.getContext("2d");
        renderGraph(myChartRef);  
    }

    const renderGraph = (ref, data) => {
        new Chart(ref, {
            type: 'doughnut',
            data: {
                labels: ["", ""],
                datasets: [{
                    data: [props.progress, 100-props.progress],
                    backgroundColor: ['#A3D968', '#FFF'],
                    borderWidth: 1,
                    borderColor: '#8bbf50',
                }],
            },
            options: {
                rotation: 1 * Math.PI,
                circumference: 1 * Math.PI,
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
                cutoutPercentage: 50
            },
        });
    }

    return (
        <CardBasic title={props.title}>
            <h3 style={{fontWeight: 'bold'}}>{props.subtitle}</h3>
            <div className="chart-pie pt-4">
                <span style={styles.pProgress}>{props.progress}%</span>
                <canvas id="myPieChart" ref={chartRef}></canvas>
            </div>
            {/*
                <hr />
                Styling for the area chart can be found in the <code>/Components/Charts/Line/Index.js</code> file.
            */}
        </CardBasic>
    )
}

const styles = {
    pProgress: {
        position: 'absolute',
        bottom: '12%',
        left: '40%',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    }
}

export default ChartSpeed;