import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';

const IDAISection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>III. Aplicación de los instrumentos diagnósticos para primero, segundo y tercer grados de primaria.</b>
                                </p>
                                <br></br>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-8 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de aplicación de instrumento IDAI por grado</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'inicial', label: 'Inicial %', color: '#1665c0', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 19, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'intermedio', label: 'Intermedio %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 20, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'final', label: 'Final %', color: '#999999', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 21, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-5 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de aplicación de instrumento IDAI por grado y nivel</b></p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-5 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>ESTATALES</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {levelName: 'ESTATAL'},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'inicial', label: 'Inicial %', color: '#1665c0', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 19, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'intermedio', label: 'Intermedio %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 20, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'final', label: 'Final %', color: '#999999', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 21, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>

                        <div className="col-5 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>FEDERALES</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {levelName: 'FEDERALIZADA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'inicial', label: 'Inicial %', color: '#1665c0', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 19, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'intermedio', label: 'Intermedio %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 20, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'final', label: 'Final %', color: '#999999', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 21, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-5 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>INDÍGENAS</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {levelName: 'INDÍGENA'},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'inicial', label: 'Inicial %', color: '#1665c0', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 19, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'intermedio', label: 'Intermedio %', color: '#f28e3c', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 20, 1, 0))/count(distinct(id))*100), 2)"},
                                        {name: 'final', label: 'Final %', color: '#999999', aggregation: 'none', custom: "FORMAT((SUM(IF(formId = 21, 1, 0))/count(distinct(id))*100), 2)"},
                                    ]
                                }}
                            />

                        </div>

                        <div className="col-5 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b></b></p>
                            </span>

                            <Table
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'levelName', label: 'Nivel'},
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'respuestas', label: 'Respuestas', aggregation: 'none', custom: 'SUM(IF(formId= 19, 1, 0))', priority: 2},
                                        {name: 'alumnos', label: 'Total Alumnos', aggregation: 'none', custom: 'count(distinct(id))', priority: 2},
                                    ],
                                }}
                            />

                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAISection;
