import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../../services/users/AuthService';
import CardBasic from '../../../../components/Cards/Basic';

import StatesListSection from './../../lists/StatesListSection';
import AdminProfile from './../../profiles/AdminProfile';
import ChangePasswordModal from '../../modals/ChangePasswordModal';


const AdminPage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const userId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    return (<div>
        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faUser} className="mr-2 tab-icon" /><span>Perfil</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="states-tab" data-toggle="tab" href="#states" role="tab" aria-controls="states" aria-selected="false">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Estados</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">

            <CardBasic title="">
                <div className='container'>
                    <div className='row '>

                        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

                            <AdminProfile userId={userId} />

                        </div>

                        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

                            <div className="container mb-5 mt-5">
                                <div className="row mb-1">
                                    <div className="col-lg-6">
                                        <p><b>Acciones</b></p>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        <ChangePasswordModal userId={userId} />

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalChangePassword">
                                            <FontAwesomeIcon icon={faKey} className="mr-3" /><b>Cambiar Contraseña</b>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </CardBasic>
            </div>
          
            <div className="tab-pane fade" id="states" role="tabpanel" aria-labelledby="states-tab">
                <StatesListSection />
            </div>

        </div>
      </div>)
}

export default AdminPage;
