import React, { useState, useEffect }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import BaseListAdapter from './../core/BaseListAdapter';

const defaultConfig = {
  singular: 'Registro',
  plural: 'Registros',
  filters: ['name',],
  excludes: [],
  options: {
    loadService: null,
    loadParams: null,
    deleteService: null,
    adminTo: ""
  }
}

const BaseListAdapterWithOptions = ({props, ItemViewHolder, config = defaultConfig, items2 = null}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (items2) { setItems(items2) }
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, [items2]);

  useEffect(() => {
    if (config.options.loadService) { getItems(); }
  },[]);

  const getItems = () => {
    setIsLoading(true);
    config.options.loadService(config.options.loadParams).then(result => {
      console.log('result', result);
      setItems(result);
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });   
  }

  const deleteItem = (id) => {
    Swal.fire({
      text: `¿Seguro que desea eliminar este registro?`,
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        config.options.deleteService(id).then(result => {
          getItems(items.filter(item => item.id !== id));
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'El registro fue eliminado exitosamente',
          });

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);

          Swal.fire({
            icon: 'error',
            title: 'No fue posible eliminar el registro',
            text: 'Asegúresese de haber eliminado cualquier dependencia asociada con este registro.',
          });
        }); 
      }
    });

  }

  const ViewHolder = ({item}) => {
    return (
      <div className="job-box d-md-flex align-items-center justify-content-between mb-3">
        <div className="job-left my-4 d-md-flex align-items-center flex-wrap">
          <ItemViewHolder item={item} />
        </div>
        <div className="d-flex">
          {config.options.adminTo && <div className="job-right my-4 flex-shrink-0 mr-3">
            <Link
              className="btn d-block w-100 d-sm-inline-block btn-light"
              to={config.options.adminTo.replace("{id}", item.id)}>
                <FontAwesomeIcon icon={faGear} className="mr-1" /> Administrar
            </Link>
          </div>}
          {config.options.deleteService && <div className="job-right my-4 flex-shrink-0">
            <a href="#" onClick={(e) => {e.preventDefault(); deleteItem(item.id);}} className="btn d-block w-100 d-sm-inline-block btn-danger white">
              <FontAwesomeIcon icon={faTrash} />
            </a>
          </div>}
        </div>
      </div>
    );
  }

  return (
    <BaseListAdapter 
      items={items}
      ViewHolder={ViewHolder} 
      config={config} 
    />
  );

}

export default BaseListAdapterWithOptions;
