import React from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { userLogOut } from '../../services/users/ActionTypes';
import auth from '../../services/users/AuthService'

const Logout = (props) => {

  let dispatch = useDispatch();

  React.useEffect(() => {

    handleSignOut();

  }, []);

  const handleSignOut = () => {
    Swal.fire({
      title: '¿Seguro que desea cerrar sesión?',
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        dispatch(userLogOut());
        auth.logout();
      }
    });
    
  }

  return (<></>)
}

export default Logout;