import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import CardBasic from '../../../components/Cards/Basic';
import SimpleTable from '../../../components/Charts/SimpleTable';
import auth from '../../../services/users/AuthService';

const SummarySection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [summary, setSummary] = useState([]);
    
    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId, currentSchoolYear]);

    const onStateIdChanged = async(id) => {
        setStateStatus(false);

        getSummary({
            schoolYearId: currentSchoolYear.id,
            state: id
        });

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const getSummary = (filters) => {
        setIsLoading(true);
        auth.getSchoolSummary(filters).then(result => {
            setSummary(result);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });        
    }

    const getTotals = (data, key) => {
        let total = 0;
        data.forEach(item => {
            total += Number(item[key]);
        });
        return total;
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className="row align-items-center justify-content-center text-center mt-5">
            <div className="col-12">
                <span style={{color: 'black', textAlign: 'justify'}}>
                    <p>
                        <b>I. Resumen de Registros</b>
                    </p>
                </span>
            </div>
        </div>

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-6">

                {stateStatus && summary && summary.schools &&
                    <SimpleTable
                        title='Escuelas'
                        columns={[{
                            name: 'Nivel',
                            selector: 'rubro',
                        }, {
                            name: 'Escuelas',
                            selector: 'nopersonas', 
                        }]}
                        data={[{
                                'rubro': 'Total',
                                'nopersonas': getTotals(summary.schools, 'escuelas')
                            },...summary.schools.map((school) => {
                            return {
                                'rubro': school.level_name,
                                'nopersonas': school.escuelas
                            }
                        })]}
                    />
                }

                {stateStatus && summary && summary.teachers &&
                    <SimpleTable
                        title='Docentes'
                        columns={[{
                            name: 'Grado',
                            selector: 'rubro',
                        }, {
                            name: 'Docentes',
                            selector: 'nopersonas', 
                        }]}
                        data={[{
                                'rubro': 'Total',
                                'nopersonas': getTotals(summary.teachers, 'maestros')
                            },...summary.teachers.map((teacher) => {
                            return {
                                'rubro': teacher.grado? ('Grado: '+teacher.grado): 'Sin grupos',
                                'nopersonas': teacher.maestros
                            }
                        })]}
                    />
                }

                {stateStatus && summary && summary.students &&
                    <SimpleTable
                        title='Estudiantes'
                        columns={[{
                            name: 'Grado',
                            selector: 'rubro',
                        }, {
                            name: 'Estudiantes',
                            selector: 'nopersonas', 
                        }]}
                        data={[{
                                'rubro': 'Total',
                                'nopersonas': getTotals(summary.students, 'estudiantes')
                            },...summary.students.map((student) => {
                            return {
                                'rubro': student.grado? ('Grado: '+student.grado): 'Sin grupo',
                                'nopersonas': student.estudiantes
                            }
                        })]}
                    />
                }

            </div>
        </div>
    </div>)
}

export default SummarySection;
