import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import CardBasic from '../../../components/Cards/Basic';

import RegionsListSection from './../lists/RegionsListSection';
import StateSupervisorsListSection from './../lists/figuras/StateSupervisorsListSection';
import AdminProfile from './../profiles/AdminProfile';

import NewRegionModal from '../modals/NewRegionModal';


const StatePage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const stateId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);
    const [currentState, setCurrentState] = useState(null);

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(() => {
        if (stateId) {
            getState(stateId);
        }
    },[stateId]);

    const getState = (id) => {
        setIsLoading(true);
        auth.getState(id).then(result => {
            setCurrentState(result);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const OnNewRegionCreated = (newRegion) => {
        //
    }

    // TODO: va en otra parte
    const ViewState = ({state}) => {
        return (
          <div className="container mb-5 mt-5">

            <div className="row mb-1">
              <div className="col-lg-6">
                <p><span>Nombre</span><br/><b>{state.name}</b></p>
              </div>
            </div>

            {/*
            <div className="row mb-1">
              <div className="col-lg-6">
                <p><span>CURP</span><br/><b>{user.curp}</b></p>
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-lg-6">
                <p><span>Email</span><br/><b>{user.email}</b></p>
              </div>
            </div>
            */}

          </div>)
    }

    return (<div>
        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Estado</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="regions-tab" data-toggle="tab" href="#regions" role="tab" aria-controls="regions" aria-selected="false">
                <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Regiones</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="regions-supervisors-tab" data-toggle="tab" href="#regions-supervisors" role="tab" aria-controls="regions-supervisors" aria-selected="false">
                <FontAwesomeIcon icon={faPersonChalkboard} className="mr-2 tab-icon" /><span>Supervisores</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">

            <CardBasic title="">
                <div className='container'>
                    <div className='row '>

                        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

                            {currentState && <ViewState state={currentState} />}

                        </div>

                        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

                            <div className="container mb-5 mt-5">
                                <div className="row mb-1">
                                    <div className="col-lg-6">
                                        <p><b>Agregar</b></p>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        {currentState && <NewRegionModal stateId={currentState.id} OnNewRegionCreated={OnNewRegionCreated} />}

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalNewRegion">
                                            <FontAwesomeIcon icon={faEarthAmericas} className="mr-3" /><b>Región</b>
                                        </button>
                                        
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        <NewRegionModal userId={1} />

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalChangePassword">
                                            <FontAwesomeIcon icon={faPersonChalkboard} className="mr-3" /><b>Supervisor Estatal</b>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </CardBasic>
            </div>
          
            <div className="tab-pane fade" id="regions" role="tabpanel" aria-labelledby="regions-tab">
                <RegionsListSection stateId={stateId} />
            </div>

            <div className="tab-pane fade" id="regions-supervisors" role="tabpanel" aria-labelledby="regions-supervisors-tab">
                <StateSupervisorsListSection stateId={stateId} />
            </div>

        </div>
      </div>)
}

export default StatePage;
