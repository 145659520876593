import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import SimpleTable from '../../components/Charts/SimpleTable';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import Loading from '../../components/Loading';

const FichaSector = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const sectorId = props.match.params.id;

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [sector, setSector] = React.useState(null);

    gtag('event', 'page_view', {
      page_title: 'Ficha de Sector',
      page_location: 'ficha-sector',
      page_path: '/avance-section/id',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(()=>{
        if(sectorId){
            setIsLoading(true);
            auth.getFichaBySector(sectorId).then(result => {
                setData(result.summary);
                setSector(result.sector);
                setIsLoading(false);
            }).catch(error => {
                console.log('err', error);
                setIsLoading(false);
            });
        }
    }, [props.match.params.id]);

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_ficha_report', {
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }
    

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className='container mb-5'>
            <div className='row '>
                <div className="col-12 ml-auto" onClick={(e) => {downloadReport(e);}}>
               
                    <ReactToPrint
                      trigger={() => <Button style={{
                          float: 'right',
                          marginBottom: '1rem',
                          padding: '1rem 2rem'
                      }}>Imprimir</Button>}
                      content={() => componentRef}
                    />

                </div>
            </div>
        </div>

        <div ref={(el) => (componentRef = el)}>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">

                    {sector && <div className="container">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <h4 className="mb-0 mt-0"><b>Sector:</b> {sector.name}<br/></h4>
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>Formación de docentes, directores y figuras de acompañamiento</b></p>
                            </div>
                        </div>
                    </div>}

                    {data && <div className="container">
                        <div className="row align-items-center justify-content-center text-center mb-3 mt-3">
                            <div className="col-12">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Docentes</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Fig. directiva</p></th>
                                            <th style={styles.th} colSpan="5"><p style={styles.thp}>Fig. acompañamiento</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Formados</p></th>
                                            <th style={styles.th}><p style={styles.thp}>En formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>% de avance de formación</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No formados</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td style={styles.td}>{zone.escuelasTotales}</td>

                                                <td style={styles.td}>{zone.docentesTotales}</td>
                                                <td style={styles.td}>{zone.docentesFormados}</td>
                                                <td style={styles.td}>{zone.docentesEnFormacion}</td>
                                                <td style={styles.td}>{zone.docentesPorcFormacion}</td>
                                                <td style={styles.td}>{zone.docentesNoFormados}</td>

                                                <td style={styles.td}>{zone.directoresTotal}</td>
                                                <td style={styles.td}>{zone.directoresFormados}</td>
                                                <td style={styles.td}>{zone.directoresEnFormacion}</td>
                                                <td style={styles.td}>{zone.directoresPorcFormacion}</td>
                                                <td style={styles.td}>{zone.directoresNoFormados}</td>

                                                <td style={styles.td}>{zone.figurasTotal}</td>
                                                <td style={styles.td}>{zone.figurasFormados}</td>
                                                <td style={styles.td}>{zone.figurasEnFormacion}</td>
                                                <td style={styles.td}>{zone.figurasPorcFormacion}</td>
                                                <td style={styles.td}>{zone.figurasNoFormados}</td>
                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    <div className="container mt-5">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>Alfabetización incial. Estudiantes de primero, segundo y tercer grado. Segundo momento de captura</b></p>
                            </div>
                        </div>
                    </div>

                    {data && <div className="container">
                        <div className="row align-items-center justify-content-center text-center mb-3 mt-3">
                            <div className="col-12">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Primer Grado</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Segundo Grado</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes Tercer Grado</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>

                                            <th style={styles.th}><p style={styles.thp}>Totales</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>No alfabéticos</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Sin datos</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td style={styles.td}>{zone.escuelasTotales}</td>

                                                <td style={styles.td}>{zone.estudiantesPrimeroTotal}</td>
                                                <td style={styles.td}>{zone.estudiantesPrimeroAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesPrimeroNoAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesPrimeroSinDatos}</td>

                                                <td style={styles.td}>{zone.estudiantesSegundoTotal}</td>
                                                <td style={styles.td}>{zone.estudiantesSegundoAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesSegundoNoAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesSegundoSinDatos}</td>

                                                <td style={styles.td}>{zone.estudiantesTerceroTotal}</td>
                                                <td style={styles.td}>{zone.estudiantesTerceroAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesTerceroNoAlfabetizados}</td>
                                                <td style={styles.td}>{zone.estudiantesTerceroSinDatos}</td>
                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}

                    <div className="container mt-5">
                        <div className="row align-items-center text-left mb-3 mt-3">
                            <div className="col-12">
                                <p className="mb-0 mt-0" style={{color: 'black'}}><b>ICE  Estudiantes de tercer grado. Segundo momento de captura</b></p>
                            </div>
                        </div>
                    </div>

                    {data && <div className="container">
                        <div className="row align-items-center text-center mb-3 mt-3">
                            <div className="col-6">

                                <table className="table responsive-table" style={{height: '1px'}}>
                                    <tbody>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}>CCT ZONA ESCOLAR</p></th>
                                            <th style={styles.th}><p style={styles.thp}>Núm. escuelas PAF</p></th>
                                            <th style={styles.th} colSpan="4"><p style={styles.thp}>Estudiantes por niveles del Instrumento de convenciones de la escritura</p></th>
                                        </tr>
                                        <tr>
                                            <th style={styles.th}><p style={styles.thp}></p></th>
                                            <th style={styles.th}><p style={styles.thp}></p></th>

                                            <th style={styles.th}><p style={styles.thp}>1</p></th>
                                            <th style={styles.th}><p style={styles.thp}>2</p></th>
                                            <th style={styles.th}><p style={styles.thp}>3</p></th>
                                            <th style={styles.th}><p style={styles.thp}>4</p></th>
                                        </tr>
                                        {data.map((zone, i) => 
                                            <tr key={i}>
                                                <td style={styles.td}>{zone.zoneCCT? zone.zoneCCT: zone.zoneName}</td>
                                                <td style={styles.td}>{zone.escuelasTotales}</td>

                                                <td style={styles.td}>{zone.estudiantesNivelUno}</td>
                                                <td style={styles.td}>{zone.estudiantesNivelDos}</td>
                                                <td style={styles.td}>{zone.estudiantesNivelTres}</td>
                                                <td style={styles.td}>{zone.estudiantesNivelCuatro}</td>

                                            </tr>
                                        )}
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </div>)
}

const styles = {
    th: {
        padding: '0px',
        border: '1px solid #044684'
    },
    thp: {
        backgroundColor: '#1D6AB3',
        color: 'white',
        padding: '2px',
        fontWeight: 'bold',
        fontSize: '11px',
        textTransform: 'capitalize',
        margin: '0px',
        height: '100%'

    },
    td: {
        padding: '2px',
        fontSize: '12px',
        textTransform: 'uppercase',
        border: '1px solid gray'
    }
}


export default FichaSector;
