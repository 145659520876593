import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import LineBar from '../../../components/Charts/LineBar';
import HorizontalBar2 from '../../../components/Charts/HorizontalBar2';

const IDAILevelsSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="pagebreak" />

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span><b>Resultados de la evaluación del aprendizaje por nivel</b></span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del IDAI - Primer Grado</b></p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="ESTATALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'ESTATAL'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 1},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="FEDERALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'FEDERALIZADA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 1},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="INDÍGENAS"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'INDÍGENA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 1},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del IDAI - Segundo Grado</b></p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="ESTATALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'ESTATAL'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 2},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="FEDERALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'FEDERALIZADA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 2},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="INDÍGENAS"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'INDÍGENA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 2},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del IDAI - Tercer Grado</b></p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="ESTATALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'ESTATAL'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="FEDERALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'FEDERALIZADA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <HorizontalBar2
                                title="INDÍGENAS"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {levelName: 'INDÍGENA'},
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />
                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAILevelsSection;
