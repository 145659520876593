import React from 'react';

const Last = (props) => {

    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
        <div>
            <div className={'text-center'}>
                <h3>This is the last step in this example!</h3>
                <hr />
            </div>
        </div>
    );
};


export default Last;