import React from 'react';
import StepWizard from "react-step-wizard";
import Nav from "./wizard-nav";

import First from './steps/First';
import Second from './steps/Second';
import Third from './steps/Third';
import Last from './steps/Last';

const MoodleCsvImporter = (props) => {

  const _stepsNames = ["CARGAR ARCHIVO", "VALIDAR COLUMNAS", "VALIDAR DATOS", "¡LISTO!"];

  const [raw, setRaw] = React.useState({
    data: [],
    meta: {
      fields: []
    }
  });
  const [rows, setRows] = React.useState([]);

  React.useEffect(()=>{
  }, []);

  // Do something on step change
  const onStepChange = (stats) => {
      // console.log(stats);
  };

  return (<>
    <div className='container' style={{overflow: 'hidden'}}>
      <div>
          <div className='row'>
              <div className='col-12'>
                  <StepWizard
                      onStepChange={onStepChange}
                      isHashEnabled
                      nav={<Nav stepsNames={_stepsNames}/>}
                  >
                      <First hashKey={'FirstStep'} setRaw={setRaw} />
                      <Second hashKey={'SecondStep'} raw={raw} setRows={setRows} {...props} />
                      <Third hashKey={'ThirdStep'} rows={rows} setRows={setRows} {...props} />
                      <Last hashKey={'TheEnd!'} />
                  </StepWizard>
              </div>
          </div>
      </div>
    </div>
  </>);

}

export default MoodleCsvImporter;
