import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2'
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import Topbar from '../../../components/public/Topbar';
import LevelSelectorModal from '../../../components/modals/LevelSelectorModal';
import ZoneSelectorModal from '../../../components/modals/ZoneSelectorModal';
import sketch4 from'../../../assets/unesco/img/sketch4.png';
import auth from '../../../services/users/AuthService'
import {userLogin} from '../../../services/users/ActionTypes'
import $ from 'jquery';
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faSchool,
  faIdCard, 
  faTrash,
  faArrowLeft,
  faChalkboardUser
} from '@fortawesome/free-solid-svg-icons';

const registerForm = FormBuilder.group({
  firstName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  secondLastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
  curp: ["", [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]],
  email: ["", [Validators.required, Validators.email]],
});

const AdminProfile = ({props, userId}) => {

  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    getUser();
  },[]);

  const getUser = () => {
    setIsLoading(true);

    auth.getUserProfile(userId).then(result => {
        setUser(result);
        registerForm.controls.firstName.setValue(result.firstName);
        registerForm.controls.lastName.setValue(result.lastName);
        registerForm.controls.secondLastName.setValue(result.secondLastName);
        registerForm.controls.curp.setValue(result.curp);
        registerForm.controls.email.setValue(result.email);
        setIsLoading(false);
    }).catch(err => {
      console.log('err', err);
      setIsLoading(false);

      Swal.fire({
          icon: 'error',
          title: 'Error al cargar datos del usuario',
          text: err.error? err.error.message : '',
          showConfirmButton: true,
          showCancelButton: false,
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }

          let { from } = location.state || { from: { pathname: "/dashboard" } };
          history.push(from.pathname);
      });

    });
  }

  const handleReset = () => {
    registerForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: userId,
      firstName: registerForm.value.firstName.trim(),
      lastName: registerForm.value.lastName.trim(),
      secondLastName: registerForm.value.secondLastName.trim(),
      email: registerForm.value.email.trim(),
      curp: registerForm.value.curp.trim(),
    }

    setIsLoading(true);
    auth.updateUser(userId, data).then(result => {
      // setRoles(result);
      setIsLoading(false);
      Swal.fire('Usuario actualizado con éxito', '', 'success');
    }).catch(err => {
      console.log('err', err);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.error.message,
        footer: 'Ocurrió un error al actualizar el usuario.'
      });
    });

  }

  const onInput = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/i, "");
  }

  const onInputCurp = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  }

  const ViewProfile = ({user}) => {
    console.log('user', user);
    return (
      <div className="container mb-5 mt-5">

        <div className="row mb-1">
          <div className="col-lg-6">
            <p><span>Nombre</span><br/><b>{user.firstName} {user.lastName} {user.secondLastName}</b></p>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-lg-6">
            <p><span>CURP</span><br/><b>{user.curp}</b></p>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-lg-6">
            <p><span>Email</span><br/><b>{user.email}</b></p>
          </div>
        </div>

      </div>)
  }

  return (<>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {/* check for permissios */}
    {false? <ViewProfile user={user} /> : <div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-11">
            <div className="pt-5">
              <FieldGroup
                control={registerForm}
                render={({ get, invalid }) => (
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-sm-12 mb-3 mb-sm-0">
                        <FieldControl
                          name="firstName"
                          render={({ handler, touched, hasError }) => (
                            <div>
                              <input type="text" {...handler()} 
                              onInput={onInput}
                              className="form-control" placeholder="Nombre" />
                              <span className="errorAlert pt-3">
                                {touched && hasError('required') && 'Nombre(s) obligatorio(s)'}
                              </span>
                              <span className="errorAlert pt-3">
                                {touched && hasError('pattern') && 'Nombre(s) inválido(s)'}
                              </span>
                            </div>
                          )}
                          meta={{ label: "Nombre(s)" }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <FieldControl
                          name="lastName"
                          render={({ handler, touched, hasError }) => (
                            <div>
                              <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Paterno" />
                              <span className="errorAlert pt-3">
                                {touched && hasError('required') && 'Apellido obligatorio'}
                              </span>
                              <span className="errorAlert pt-3">
                                {touched && hasError('pattern') && 'Apellido inválido'}
                              </span>
                            </div>
                          )}
                          meta={{ label: "Apellido Paterno" }}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldControl
                          name="secondLastName"
                          render={({ handler, touched, hasError }) => (
                            <div>
                              <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Materno" />
                              <span className="errorAlert pt-3">
                                {touched && hasError('required') && 'Apellido obligatorio'}
                              </span>
                              <span className="errorAlert pt-3">
                                {touched && hasError('pattern') && 'Apellido inválido'}
                              </span>
                            </div>
                          )}
                          meta={{ label: "Apellido Materno" }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 mb-3 mb-sm-0">
                        <FieldControl
                          name="curp"
                          render={({ handler, touched, hasError }) => (
                            <div>
                              <input type="text" maxLength="18" {...handler()} onInput={onInputCurp} className="form-control" placeholder="CURP" />
                              <span className="errorAlert pt-3">
                                {touched && hasError('required') && 'CURP obligatorio'}
                              </span>
                              <span className="errorAlert pt-3">
                                {touched && hasError('pattern') && 'CURP inválido'}
                              </span>
                            </div>
                          )}
                          meta={{ label: "CURP" }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <FieldControl
                        name="email"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <input type="email" {...handler()} 
                            onInput={e => e.target.value = ("" + e.target.value).toUpperCase()}
                            className="form-control" placeholder="Correo Institucional" />
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Correo obligatorio'}
                            </span>
                            <span className="errorAlert pt-3">
                              {touched && hasError('email') && 'Correo Inválido'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Correo Institucional" }}
                      />
                    </div>
                    <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                      ACTUALIZAR
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>}
  </>)

}

export default AdminProfile;
