import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DecimalTypeRender extends React.Component {

  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onUpdate: PropTypes.func.isRequired
  }
  static defaultProps = {
    value: 0.00
  }

  getValue() {
    return parseFloat(this.range.value).toFixed(2) || 0.00;
  }
  
  render() {
    const { value, onUpdate, ...rest } = this.props;
    return [
      <input
        { ...rest }
        key="range"
        className="form-control"
        ref={ node => this.range = node }
        type="number"
        step=".01"
        onBlur={() => onUpdate(this.getValue())} 
      />
    ];
  }
}

export default DecimalTypeRender;