import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import Swal from 'sweetalert2'
import auth from '../../../services/users/AuthService'
import { gtag } from 'ga-gtag';
import $ from 'jquery';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";

const newStudentForm = FormBuilder.group({
  firstName: ["", [Validators.required, Validators.pattern(/^[a-zA-ZáÁéÉíÓóÚúÜü\s]*$/)]],
  lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-ZáÁéÉíÓóÚúÜü\s]*$/)]],
  secondLastName: ["", [Validators.required, Validators.pattern(/^[a-zA-ZáÁéÉíÓóÚúÜü\s]*$/)]],
  sex: ["", Validators.required],
  curp: ["", [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]],
});

const NewStudentModal = ({ props, onNewStudentCreated, onStudentEdited, studentId, groupId }) => {

  let userState = useSelector(state => state.userState);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(()=>{
    if (studentId) {
      loadStudentInfo(studentId);
    }
  }, [studentId]);

  const loadStudentInfo = (studentId) => {
    setIsLoading(true);
    handleReset();
    auth.getStudent(studentId).then(result => {
      // setRoles(result);
      setIsLoading(false);
      const obj = {
        firstName: result.firstName,
        lastName: result.lastName,
        secondLastName: result.secondLastName,
        sex: result.sex,
        curp: result.curp,
      };
      newStudentForm.setValue(obj);
      newStudentForm.patchValue(obj);
    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);

      if (error.response) {
        const message = error.response.status === 500 ?
          "Ocurrió un error al crear al estudiante." :
          error.response.data.message;

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
            footer: 'Por favor revise los datos ingresados.'
        });
      }

    });
  }

  const handleReset = () => {
    newStudentForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(studentId){
      editStudent();
      gtag('event', 'edit_student', { 
        'action': 'edit',
        'student_id': studentId,
        'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : '',
      });

    }else{
      createStudent();
      gtag('event', 'save_student', { 
        'action': 'edit',
        'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : '',
      });
    }

  }

  const createStudent = () => {
    if (newStudentForm.valid) {
      setIsLoading(true);
      const data = {
        firstName: newStudentForm.value.firstName.trim(),
        lastName: newStudentForm.value.lastName.trim(),
        secondLastName: newStudentForm.value.secondLastName.trim(),
        sex: newStudentForm.value.sex,
        curp: newStudentForm.value.curp,
        group: groupId
      }

      auth.createStudent(data).then(result => {
        // setRoles(result);
        setIsLoading(false);
        onNewStudentCreated({newStudent: result});
        $('#modalNewStudent').modal('toggle');
        handleReset();
      }).catch(error => {
        console.log('err', error);
        setIsLoading(false);

        if (error.response) {
          const message = error.response.status === 500 ?
            "Ocurrió un error al crear al estudiante." :
            error.response.data.message;

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: message,
              footer: 'Por favor revise los datos ingresados.'
          });
        }

      });
    }

  }

  const editStudent = () => {
    if (newStudentForm.valid) {
      setIsLoading(true);
      const data = {
        firstName: newStudentForm.value.firstName.trim(),
        lastName: newStudentForm.value.lastName.trim(),
        secondLastName: newStudentForm.value.secondLastName.trim(),
        sex: newStudentForm.value.sex,
        curp: newStudentForm.value.curp,
      }

      auth.editStudent(studentId, data).then(result => {
        setIsLoading(false);
        onStudentEdited({student: result});
        $('#modalNewStudent').modal('toggle');
        handleReset();
      }).catch(error => {
        console.log('err', error);
        setIsLoading(false);

        if (error.response) {
          const message = error.response.status === 500 ?
            "Ocurrió un error al editar al estudiante." :
            error.response.data.message;

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: message,
              footer: 'Por favor revise los datos ingresados.'
          });
        }

      });
    }

  }

  const onInput = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
    e.target.value = e.target.value.replace(/[^a-zA-ZáÁéÉíÓóÚúÜü ]/i, "");
  }

  const onInput2 = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  }

  return (
    <div className="modal fade" id="modalNewStudent" tabIndex={-1} role="dialog" aria-labelledby="modalExampleTitle" aria-hidden="true">
      <div className="modal-dialog extended modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
            {/* Close */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <div className="container mb-11 mt-5">
              <div className="row">
                <div className="col-12">
                  <div className="pt-0 pl-5 pr-5 pb-5">
                    <div className="text-center">
                      { studentId ?
                        <h1 className="text-gray-900 mb-4">Editar Estudiante</h1>
                        : <h1 className="text-gray-900 mb-4">Nuevo Estudiante</h1>
                      }
                    </div>
                    <FieldGroup
                      control={newStudentForm}
                      render={({ get, invalid }) => (
                        <form className="user" onSubmit={handleSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                              <FieldControl
                                name="firstName"
                                control={newStudentForm.controls.firstName}
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="text" {...handler()} onInput={onInput}
                                    className="form-control" placeholder="Nombre" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Nombre(s) obligatorio(s)'}
                                    </span>
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('pattern') && 'Nombre(s) inválido(s)'}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <FieldControl
                                name="sex"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <select {...handler()} className="form-control">
                                      <option value="" disabled>Sexo</option>
                                      <option value="FEMENINO">FEMENINO</option>
                                      <option value="MASCULINO">MASCULINO</option>
                                    </select>
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Selección de sexo obligatorio'}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                              <FieldControl
                                name="lastName"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Paterno" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Apellido obligatorio'}
                                    </span>
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('pattern') && 'Apellido inválido'}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-sm-6">
                              <FieldControl
                                name="secondLastName"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="text" {...handler()} onInput={onInput} className="form-control" placeholder="Apellido Materno" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'Apellido obligatorio'}
                                    </span>
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('pattern') && 'Apellido inválido'}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                              <FieldControl
                                name="curp"
                                render={({ handler, touched, hasError }) => (
                                  <div>
                                    <input type="text" {...handler()} onInput={onInput2} className="form-control" placeholder="CURP" />
                                    <span className="errorAlert pt-3">
                                      {touched && hasError('required') && 'CURP obligatorio'}
                                    </span>
                                    <span className="errorAlert pt-3">
                                      {hasError('pattern') && 'CURP inválido'}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                            REGISTRAR
                          </button>
                        </form>
                      )}
                    />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewStudentModal;
