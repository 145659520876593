import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2'
import auth from '../../../services/users/AuthService'
import { gtag } from 'ga-gtag';
import $ from 'jquery';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import RegionForm from '../forms/RegionForm';


const NewRegionModal = ({ props, stateId }) => {

  let userState = useSelector(state => state.userState);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    $('#modalNewRegion').on('hidden.bs.modal', (e) => {
      // do something...
    });
  },[]);

  const OnNewRegionCreated = (newRegion) => {
     $("#modalNewRegion").modal("hide");
     // callback(newRegion);
  }

  return (
    <div className="modal fade" id="modalNewRegion" tabIndex={-1} role="dialog" aria-labelledby="modalNewRegionTitle" aria-hidden="true">
      <div className="modal-dialog extended modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
            {/* Close */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <RegionForm stateId={stateId} onRegionSaved={OnNewRegionCreated} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewRegionModal;
