import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';

//Boostrap Libs
import 'core-js/es/symbol'
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// SBAdmin2 Style
import './assets/sb-admin/scss/sb-admin-2.scss';
// Skola Style
import './assets/skola/scss/theme.scss';
// Custom Style
import './index.css';
import './assets/unesco/css/wizard.css';
import './assets/unesco/css/profiles-selection.css';
import './assets/unesco/css/administration.css';
import './assets/unesco/css/checks.css';


//Redux
import { Provider } from 'react-redux';
import storeConfig from './redux/store';

ReactDOM.render(
<Provider store={storeConfig().store}>
  <PersistGate loading={null} persistor={storeConfig().persistor}>
    <App />
  </PersistGate>
</Provider> , document.getElementById('root'));
