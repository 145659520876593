import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2'
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus,
    faUserGroup,
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

const form = FormBuilder.group({
  name: ["", [Validators.required]],
  cct: ["", [Validators.required, Validators.pattern(/^[0-9a-zA-Z]*$/)]],
  officeName: ["", null],
  officeAddress: ["", null],
  colonia: ["", null],
  localidad: ["", null],
  state: [0, [Validators.required]],
  municipality: [0, [Validators.required]],
});

const RegionForm = ({regionId, stateId, onRegionSaved}) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);

    const isEditing = regionId? true: false;

    const [isLoading, setIsLoading] = useState(false);
    const [currentRegion, setCurrentRegion] = useState(null);
    const [states, setStates] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    React.useEffect(() => {
        form.reset();
        if (regionId || stateId) {
            getStates();
        }
    }, [regionId, stateId]);

    React.useEffect(() => {
        if (regionId) {
            getRegion(regionId);
        } else if(stateId) {
            form.controls.state.setValue(stateId);
            form.controls.state.disable();
        }
    }, [states]);
        



    form.get('state').valueChanges.subscribe((value) => {
        if (form.value.state !== value) {
            form.controls.municipality.patchValue("");
            getMunicipios(value, "");
        }
    });

    const getRegion = (id) => {
        setIsLoading(true);
        auth.getRegion(id).then(result => {
            setCurrentRegion(result);

            form.controls.name.setValue(result.name);
            form.controls.cct.setValue(result.cct);
            form.controls.officeName.setValue(result.officeName);
            form.controls.officeAddress.setValue(result.officeAddress);
            form.controls.colonia.setValue(result.colonia);
            form.controls.localidad.setValue(result.localidad);
            form.controls.state.setValue(result.state? result.state.id: 0);

            if(result.state){
                getMunicipios(result.state.id, result.municipality? result.municipality.id: "");
            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getStates = () => {
        setIsLoading(true);
        auth.getActiveStates().then(result => {
            console.log('states', result);
            setStates(result);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getMunicipios = (stateId, municipalityId) => {
        setIsLoading(true);
        auth.getMunicipios(stateId).then(result => {
            setMunicipios(result);
            form.controls.municipality.setValue(municipalityId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const onInput = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/i, "");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
          text: `¿Seguro que desea ${isEditing? 'actualizar': 'crear'} la región?`,
          showDenyButton: true,
          confirmButtonText: 'Sí',
          denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    name: form.value.name? form.value.name.trim() : '',
                    cct: form.value.cct? form.value.cct.trim() : '',
                    officeName: form.value.officeName? form.value.officeName.trim() : '',
                    officeAddress: form.value.officeAddress? form.value.officeAddress.trim() : '',
                    colonia: form.value.colonia? form.value.colonia.trim() : '',
                    localidad: form.value.localidad? form.value.localidad.trim() : '',
                    state: form.value.state,
                    municipality: form.value.municipality,
                }

                if(isEditing){
                    data.id = regionId;
                    update(data);
                }else{
                    data.state = stateId;
                    create(data);
                }
            }
        });
    }

    const create = (data) => {
        setIsLoading(true);
        auth.createRegion(data).then(result => {
            if (onRegionSaved) { onRegionSaved(result); }
            setIsLoading(false);
            Swal.fire('Región creada con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al crear la región.'
          });
        });
    }

    const update = (data) => {
        setIsLoading(true);
        auth.updateRegion(data.id, data).then(result => {
            if (onRegionSaved) { onRegionSaved(result); }
            setIsLoading(false);
            Swal.fire('Región actualizada con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al actualizar la región.'
          });
        });
    }

    return (
        <div className="container mb-5 mt-5">
            <FieldGroup
                control={form}
                strict={false}
                render={({ get, invalid }) => (
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row mb-3">

                            <FieldControl
                                name="name"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-8">
                                        <span>Nombre</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nombre obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="cct"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>CCT</span><br/>
                                        <input type="text"  onInput={onInput} {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'CCT obligatorio'}
                                        </span>
                                        <span className="errorAlertSmall pt-3">
                                            {touched && hasError('pattern') && 'CCT inválido'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="state"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Estado</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {states && states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Estado obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="municipality"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Municipio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {municipios && municipios.map((municipio,i) => <option key={i} value={municipio.id}>{municipio.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Municipio obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="colonia"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Colonia</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>
                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="officeName"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nombre del centro de Trabajo</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="officeAddress"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Dirección de la Oficina</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="localidad"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Localidad</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                                GUARDAR
                            </button>
                        </div>

                    </form>
                )}
            />
        </div>
    );

}

export default RegionForm;
