import React, { useState, useEffect } from 'react';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
 } from "react-reactive-form";
import $ from 'jquery';

const cctForm = FormBuilder.group({
  state: ["", [Validators.required]],
});

const SupportSelectorModal = ({ props, childToParent }) => {

  const [states, setStates] = useState([
    {id: 22, name: 'Querétaro', url: 'https://forms.gle/PDVMjv4j35wCKScj8'},
    {id: 30, name: 'Veracruz', url: 'https://forms.gle/bpTo5ybZGEQQJo346'},
    {id: 31, name: 'Yucatán', url: 'https://forms.gle/rBoB5bRc9HPNzxeq9 '},
  ]);

  const handleSubmit = (e, value) => {
    e.preventDefault();
    const state = states.find(state => state.id === Number(value.state));
    console.log('state', state);
    window.open(state.url, "_blank", "noreferrer");
    cctForm.reset();
    $('#modalSupportSelector').modal('toggle');

  }

  return (
    <div className="modal fade" id="modalSupportSelector" tabIndex={-1} role="dialog" aria-labelledby="modalSupportSelector" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            {/* Close */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            {/* Heading */}
            <h2 className="fw-bold text-center mb-1" id="modalExampleTitle">
              ¿Necesitas ayuda?
            </h2>
            {/* Text */}
            <p className="font-size-lg text-center text-muted mb-6 mb-md-8">
              Selecciona tu estado
            </p>
            {/* Form */}
            <FieldGroup
              control={cctForm}
              strict={false}
              render={({ get, invalid, value }) => (
                <form onSubmit={e => handleSubmit(e, value)}>
                  <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="col-12 mb-3">
                      <FieldControl
                        name="state"
                        render={({ handler, touched, hasError }) => (
                          <div>
                            <select {...handler()} className="form-control">
                              <option value="" disabled>Estado</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                            <span className="errorAlert pt-3">
                              {touched && hasError('required') && 'Estado obligatorio'}
                            </span>
                          </div>
                        )}
                        meta={{ label: "Estado" }}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12">
                      {/* Submit */}
                      <button type="submit" disabled={invalid} className="btn btn-block btn-primary mt-3 lift">
                        ¡Listo!
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>)
}

export default SupportSelectorModal;
