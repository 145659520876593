import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import BaseListAdapterWithOptions from './../core/BaseListAdapterWithOptions';
import IconCCT from '../../../components/IconCCT';

const SchoolsListSection = ({props, zoneId}) => {

  const CustomConfig = {
    singular: 'Escuela',
    plural: 'Escuelas',
    filters: ['name', 'cct'],
    excludes: ['escuela', ':', 'cct'],
    options: {
      loadService: auth.getSchools,
      loadParams: zoneId,
      deleteService: auth.deleteSchool,
      adminTo: "/schools/{id}"
    }
  }

  const CustomViewHolder = ({item}) => {
    const school = item;

    return (<>
      <div className="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
        {school.name? school.name.charAt(0): ''}
      </div>
      <div className="job-content">
        <h5 className="text-center text-md-left mb-0">{school.name}</h5>
        <ul className="d-md-flex ff-open-sans pl-0 text-left">
          <li className="mr-md-4">
            <IconCCT />{school.cct}
            <br/>
            <FontAwesomeIcon icon={faBuilding} /> {school.direction}
          </li>
        </ul>
      </div>
    </>);
  }

  return (
    <BaseListAdapterWithOptions 
      ItemViewHolder={CustomViewHolder} 
      config={CustomConfig} 
    />
  );

}

export default SchoolsListSection;
