import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';
import ChartDonut2 from '../../../components/Charts/Donut2';
import ChartSpeed from '../../../components/Charts/Speed';
import ChartEvaluado from '../../../components/Charts/Evaluado';
import auth from '../../../services/users/AuthService';

const ResultadosSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});
    const [goals, setGoals] = useState([]);
    const [evaluadosNivelEscuela, setEvaluadosNivelEscuela] = useState(0);

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };


    React.useEffect(()=>{
        setIsLoading(true);
        auth.getStateGoals(stateId, currentSchoolYear.id).then(result => {
            setGoals(result);

            const value = result.filter(({grade}) => [1,2,3].includes(Number(grade)))
                .reduce((sum, record) => sum + Number(record.evaluadosTotalIDAI), 0);

            const total =  result.filter(({grade}) => [1,2,3].includes(Number(grade)))
                .reduce((sum, record) => sum + Number(record.total), 0);

            setEvaluadosNivelEscuela(Number(value/total * 100).toFixed(2));

            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

    }, [stateId, currentSchoolYear]);

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>Registro de resultados del Instrumento Diagnóstico de Alfabetización Inicial. </b><br></br>
                                    <b>Aplicación final</b>
                                </p>
                                <p>Logro de alfabetización inicial por grado</p>
                            </span>
                        </div>

                    </div>


                    <div className="row align-items-center justify-content-center text-center">

                        {goals.filter(goal => goal.grade == 1).map(goal =>
                            <div className="col-4">
                                <ChartSpeed
                                    title="80% estudiantes nivel D"
                                    subtitle="Meta Primer Grado"
                                    progress={Number(goal.progressIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                        {goals.filter(goal => goal.grade == 2).map(goal =>
                            <div className="col-4">
                                <ChartSpeed
                                    title="100% estudiantes nivel D"
                                    subtitle="Meta Segundo Grado"
                                    progress={Number(goal.progressIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                        {goals.filter(goal => goal.grade == 3).map(goal =>
                            <div className="col-4">
                                <ChartSpeed
                                    title="100% estudiantes nivel D"
                                    subtitle="Meta Tercer Grado"
                                    progress={Number(goal.progressIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>Porcentaje de estudiantes con resultados registrados en la aplicación final</p>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center">

                        {goals.filter(goal => goal.grade == 1).map(goal =>
                            <div className="col-4" style={{margin: '0 -3.6rem'}}>
                                <ChartEvaluado
                                    title="Primer grado"
                                    progress={Number(goal.evaluadosIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                        {goals.filter(goal => goal.grade == 2).map(goal =>
                            <div className="col-4" style={{margin: '0 -3.6rem'}}>
                                <ChartEvaluado
                                    title="Segundo grado"
                                    progress={Number(goal.evaluadosIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                        {goals.filter(goal => goal.grade == 3).map(goal =>
                            <div className="col-4" style={{margin: '0 -3.6rem'}}>
                                <ChartEvaluado
                                    title="Tercer grado"
                                    progress={Number(goal.evaluadosIDAI*100).toFixed(2)}
                                />
                            </div>
                        )}

                        {evaluadosNivelEscuela && <div className="col-5" style={{margin: '0 -5rem'}}>
                            <ChartEvaluado
                                title="A nivel estado"
                                progress={evaluadosNivelEscuela}
                            />
                        </div>}

                    </div>

                </div>
            </div>
        </div> }
    </div>)
}

export default ResultadosSection;
