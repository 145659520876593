import React, { useState, useEffect }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import sketch5 from'../../../assets/unesco/img/sketch7.jpg';

const defaultConfig = {
  singular: 'Registro',
  plural: 'Registros',
  filters: ['name',],
  excludes: [],
}

const BaseListAdapter = ({props, items, ViewHolder, config = defaultConfig}) => {

  const [filteredList, setFilteredList] = useState(items);

  useEffect(() => {
    let isMounted = true;
    if (items) { setFilteredList(items); }
    return () => { isMounted = false }; // cleanup toggles value, if unmounted
  }, [items]);

  const normalizeQuery = (query) => {
    if(!query){
      return "";
    }

    query = query.toLowerCase();

    config.excludes.forEach((term => query = query.replace(term.toLowerCase(), "")));

    return query.trim();

  }

  const filterBySearch = (event) => {
     event.preventDefault();
    // Access input value
    const query = normalizeQuery(event.target.keywords.value);
    // Create copy of item list
    var updatedList = [...items];

    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return config.filters.some(filter => item[filter]? item[filter].toLowerCase().indexOf(query) !== -1 : false);
    });
    // Trigger render with updated values
    setFilteredList(updatedList);
  };

  return (<div className="container mb-5">
    <div className="row align-items-center justify-content-center text-center">
      <div className="col-12">

        {(!items || items.length === 0) && <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto mb-4">
              <div className="section-title text-center ">
                <img  src={sketch5} className="w-20" alt="empty"/>
                <h3 className="top-c-sep mt-5">Sin Resultados</h3>
                <p>No se encontró ningún {config.singular}.</p>
              </div>
            </div>
          </div>
        </div>}

        {(items && items.length !== 0) && <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto mb-4">
              <div className="section-title text-center ">
                <h3 className="top-c-sep">Lista de {config.plural}</h3>
                <p>Aquí encontrara el listado de {config.plural}.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="career-search mb-60">
                <form action="#" className="career-form mb-60" onSubmit={filterBySearch}>
                  <div className="row">
                    <div className="col-md-6 col-lg-9 my-3">
                      <div className="input-group position-relative">
                        <input type="text" className="form-control" placeholder="Introduce un nombre..." id="keywords" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 my-3">
                      <button type="submit" className="btn btn-lg btn-block btn-light btn-custom" id="contact-submit">
                        <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar 
                      </button>
                    </div>
                  </div>
                </form>
                <div className="filter-result">
                  <p className="mb-30 ff-montserrat">{/* Total Job Openings : 89 */}</p>
                  {items && filteredList && filteredList.map((item, i) => <ViewHolder item={item} key={i} /> )}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>)
}

export default BaseListAdapter;
