import React  from 'react';
import { gtag, install } from 'ga-gtag';
import Main from "./auth/Main";

install('G-B9VM39K2M7'); 

const App = () => <Main />;

export default App;

