import React, { useState, useRef } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import HorizontalBar2 from '../../components/Charts/HorizontalBar2';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';
import TitleSection from './sections/TitleSection';
import SummarySection from './sections/SummarySection';
import GoalsSection from './sections/GoalsSection';
import IDAISection from './sections/IDAISection';
import IDAIGoalsSection from './sections/IDAIGoalsSection';
import ICESection from './sections/ICESection';
import IDAILevelsSection from './sections/IDAILevelsSection';
import TrayectoSection from './sections/TrayectoSection';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";

const StateReport = (props) => {

    let userState = useSelector(state => state.userState);

    let componentRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateSelectedName, setStateSelectedName] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});
    const [currentSchoolYear, setCurrentSchoolYear] = useState(null);

    gtag('event', 'page_view', {
      page_title: 'Reporte Estado',
      page_location: 'state-report',
      page_path: '/state-report',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(()=>{
        setCurrentSchoolYear(null);
        setTimeout(()=>{
            setCurrentSchoolYear(userState.schoolYear);
        }, 200);

    }, [userState.schoolYear]);

    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);
                    setStateSelectedName(userState.profile.state.state_name);

            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_state_report', {
          'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }

    return (<>

        <div className='container mb-5'>
            <div className='row '>
                <div className="col-12 ml-auto" onClick={(e) => {downloadReport(e);}}>
               
                    <ReactToPrint
                      trigger={() => <Button style={{
                          float: 'right',
                          marginBottom: '1rem',
                          padding: '1rem 2rem'
                      }}>Imprimir</Button>}
                      content={() => componentRef}
                    />

                </div>
            </div>
        </div>

        <div ref={(el) => (componentRef = el)}>


            <div className='container mb-5'>
                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                {userState.profile && userState.profile.role &&
                    [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                    
                        <div className='row '>
                            <div className="col-14 ml-auto">
                                <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                                  <option value="">Estado (Todos)</option>
                                  {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                                </select>
                            </div>
                        </div>

                    : null
                }

            </div >

            {currentSchoolYear && <div>

                <CardBasic title="">
                    <TitleSection stateId={stateSelectedId} stateName={stateSelectedName} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <SummarySection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <div className="pagebreak" />

                <CardBasic title="Trayecto Formativo">
                    <TrayectoSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <GoalsSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <IDAISection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <GoalsSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <IDAIGoalsSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span><b>Resultados de la evaluación del aprendizaje: aplicación inicial</b></span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del IDAI</b></p>
                            </span>

                            {stateStatus && <HorizontalBar2
                                title="Primer grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 1},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />}

                            {stateStatus && <HorizontalBar2
                                title="Segundo Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 2},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            /> }

                            <div className="pagebreak" />

                            {stateStatus && <HorizontalBar2
                                title="Tercer Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 19},
                                            {formId: 20},
                                            {formId: 21},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        'A': '#0e72f1', 
                                        'B': '#4cb7cd', 
                                        'C': '#ec4497', 
                                        'D': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            /> }

                        </div>
                    </div>
                </CardBasic>

                <CardBasic title="">
                    <IDAILevelsSection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>
                
                <CardBasic title="">
                    <ICESection stateId={stateSelectedId} currentSchoolYear={currentSchoolYear} />
                </CardBasic>

                <CardBasic title="">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Resultados del ICE</b></p>
                            </span>

                            {stateStatus && <HorizontalBar2
                                title="Tercer Grado"
                                horizontal={true}
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id },
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 22},
                                            {formId: 23},
                                            {formId: 24},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#0e72f1', 
                                        '2': '#4cb7cd', 
                                        '3': '#ec4497', 
                                        '4': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />}

                        </div>
                    </div>
                </CardBasic>

                <CardBasic title="">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-6 mt-5">
                            
                            {stateStatus && <HorizontalBar2
                                title="ESTATALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id },
                                        {grado: 3},
                                        {levelName: 'ESTATAL'},
                                        {'$or': [
                                            {formId: 22},
                                            {formId: 23},
                                            {formId: 24},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#0e72f1', 
                                        '2': '#4cb7cd', 
                                        '3': '#ec4497', 
                                        '4': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            /> }

                        </div>

                        <div className="col-6 mt-5">
                            {stateStatus &&  <HorizontalBar2
                                title="FEDERALES"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id },
                                        {levelName: 'FEDERALIZADA'},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 22},
                                            {formId: 23},
                                            {formId: 24},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#0e72f1', 
                                        '2': '#4cb7cd', 
                                        '3': '#ec4497', 
                                        '4': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />}

                        </div>

                        <div className="col-6 mt-5">
                            {stateStatus && <HorizontalBar2
                                title="INDÍGENAS"
                                horizontal={true}
                                porcent={true}
                                porcentBold={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responsesWithNoCapture'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters, 
                                        {lastSchoolYearId: currentSchoolYear.id },
                                        {levelName: 'INDÍGENA'},
                                        {grado: 3},
                                        {'$or': [
                                            {formId: 22},
                                            {formId: 23},
                                            {formId: 24},
                                        ]},
                                    ]},
                                    dimension: {name: 'formName', label: ''},
                                    dimensions: [{name: 'formId', label: ''}],
                                    breakdownDimension: {name: 'customScore', label: 'Nivel'},
                                    metrics: [],
                                    customMetrics: [],
                                    colorLabels: {
                                        '1': '#0e72f1', 
                                        '2': '#4cb7cd', 
                                        '3': '#ec4497', 
                                        '4': '#f06d38',
                                    },
                                    order: {
                                        dimension: 'formId',
                                        ascendent: true,
                                    },
                                    orderBy: 'ORDER BY formId ASC',
                                }}
                            />}

                        </div>
                    </div>
                </CardBasic>
            </div>}

        </div>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default StateReport;
