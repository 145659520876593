export const USER_SCHOOL_YEAR = '@users/schoolYear';
export const USER_GROUPS = '@users/groups';
export const USER_AUTH = '@users/prelogin';
export const USER_LOGIN = '@users/login';
export const USER_SIGNUP = '@users/signup';
export const USER_LOGOUT = '@users/logout';

export const userAuth = user => ({
    type: USER_AUTH,
    payload: user
});

export const userGroups = groups => ({
    type: USER_GROUPS,
    payload: groups
});

export const userSchoolYear = schoolYear => ({
    type: USER_SCHOOL_YEAR,
    payload: schoolYear
});

export const userLogin = profile => ({
    type: USER_LOGIN,
    payload: profile
});

export const userSignUp = profile => ({
    type: USER_SIGNUP,
    payload: profile
});

export const userLogOut = () => ({
    type: USER_LOGOUT,
    payload: {}
});
