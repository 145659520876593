import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import auth from '../../../services/users/AuthService';
import NumberTypeRender from '../render-components/NumberTypeRender';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createTeacher(data);

const columns = [{
  dataField: 'cct',
  text: 'CCT Escuela',
  description:'CCT de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'firstName',
  text: 'Nombre',
  description:'Nombre del Maestro',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'lastName',
  text: 'Primer Apellido',
  description:'Primer Apellido del Maestro',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'secondLastName',
  text: 'Segundo Apellido',
  description:'Segundo Apellido del Maestro',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'email',
  text: 'Correo Electrónico',
  description:'Correo Electrónico del Maestro',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'curp',
  text: 'CURP',
  description:'CURP del Maestro',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'phone',
  text: 'Teléfono Móvil',
  description:'Teléfono Móvil del Maestro',
  editable: true,
  required: false,
  style: errorsStyle
}, {
  dataField: 'grade',
  text: 'Grado',
  description:'Grado',
  editable: true,
  required: true,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},{
  dataField: 'group',
  text: 'Grupo',
  description:'Grupo',
  editable: true,
  required: false,
  style: errorsStyle
}/*,{
  dataField: 'isMultiGrade',
  text: 'Es Multigrado',
  description:'¿Es multigrado?',
  editable: true,
  required: false,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
}*/,{
  dataField: 'actions',
  text: 'Acciones',
  editable: false,
  required: false,
  actions: true,
},{
  dataField: 'messages',
  text: 'Mensajes',
  editable: false,
  required: false,
  actions: true,
}];

export {columns, saveInfo};
