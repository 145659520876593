import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import LineBar from '../../../components/Charts/LineBar';

const IDAIGoalsSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Alfabetización inicial Metas contra aplicación final Estatal</b></p>
                            </span>

                            <LineBar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'responses21'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                        {lastSchoolYearId: currentSchoolYear.id},
                                        {'$or': [
                                            {grado: 1},
                                            {grado: 2},
                                            {grado: 3},
                                        ]},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'inicial', label: 'Resultado %', color: '#1665c0', aggregation: 'none', custom: "FORMAT(SUM(IF(grado=1 AND (customscore = 'C' OR customscore = 'D'), 1, IF(customscore = 'D', 1, 0)))/count(distinct(id))*100, 2)"},

                                        {name: 'meta', label: 'Meta %', type: 'line', color: '#f28e3c', aggregation: 'none', custom: "FORMAT(IF(grado=1, 80, 100) , 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    <div className="pagebreak" />

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAIGoalsSection;
