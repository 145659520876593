import React from 'react';
import Loading from '../../../../components/Loading';
import auth from '../../../../services/users/AuthService';
import Table from '../../../../components/Charts/Table';

const SummarySection = ({props, schoolId, currentSchoolYear}) => {

    const [school, setSchool] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [statistics, setStatistics] = React.useState(null);
    const [isLoadingStatistics, setIsLoadingStatistics] = React.useState(false);

    React.useEffect(()=>{
        setIsLoading(true);
        auth.getSchool(schoolId).then(school => {
            setSchool(school);
            setIsLoading(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoading(false);
        });

        setIsLoadingStatistics(true);
        auth.getSchoolStatistics(schoolId, 1).then(school => {
            setStatistics(school);
            setIsLoadingStatistics(false);
        }).catch(error => {
            console.log('err', error);
            setIsLoadingStatistics(false);
        });

    }, [currentSchoolYear]);


    return (<div className="container mb-5">

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-12">
                            <h6>
                                <b>Política Escuela de Aprendizajes Fundamentales</b><br/>
                                <b>Reporte Escuela</b><br/>
                                <b>{currentSchoolYear? currentSchoolYear.name: ''}</b>
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    El <b>Reporte Escuela</b> es la herramienta de la Política Estatal de Aprendizajes Fundamentales que ofrece a la comunidad escolar un
                                    informe que recupera las evidencias sobre el logro de aprendizajes fundamentales en lengua de las niñas y los niños de primero, segundo y tercer grado de educación primaria.
                                </p>
                                <br></br>
                                <p>
                                    La información se entrega por escuela como un insumo para el análisis y la reflexión individual y colectiva que realizan las y los
                                    docentes y las figuras directivas en relación con el aprendizaje de las y los estudiantes. Para la supervisión y la asesoría técnico
                                    pedagógica es un instrumento que permite planear apoyos pedagógicos focalizados para las escuelas, con base en la evidencia
                                    sobre el logro de aprendizajes de niñas y niños.
                                </p>
                                <br></br>
                                <p>
                                    ¿Cómo llegan las y los estudiantes? ¿qué avance tienen a la mitad del ciclo? ¿quiénes lograron avanzar? ¿quiénes no lo hicieron
                                    o avanzaron poco? Son algunas de las preguntas que el Reporte Escuela ayuda a responder y que son fundamentales cuando se
                                    diseñan acciones orientadas a la mejora de todas y todos los estudiantes.
                                </p>
                            </span>
                        </div>
                    </div>

                    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                    {!school? null : <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p><b>I. Datos generales de la escuela:</b></p>
                            </span>

                            <table className="table responsive-table">
                                <tbody>
                                    <tr>
                                        <td style={styles.th}>Entidad</td>
                                        <td style={styles.th}>Municipio</td>
                                        <td style={styles.th}>Localidad</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>{school.state_name}</td>
                                        <td style={styles.td}>{school.municipality_name}</td>
                                        <td style={styles.td}>{school.school_location}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.th}>CCT</td>
                                        <td style={styles.th}>Escuela</td>
                                        <td style={styles.th}>Turno</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>{school.school_cct}</td>
                                        <td style={styles.td}>{school.school_name}</td>
                                        <td style={styles.td}>{school.school_shift}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.th}>Director</td>
                                        <td style={styles.th}>Zona Escolar</td>
                                        <td style={styles.th}>Nivel</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>{school.principal_name? school.principal_name: '-'}</td>
                                        <td style={styles.td}>{school.zone_name} - {school.zone_cct}</td>
                                        <td style={styles.td}>{school.level_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.th}>Grado de Marginación</td>
                                        <td style={styles.th}>Tipo de Organización</td>
                                        <td style={styles.th}></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>{school.school_marginalizationLevel}</td>
                                        <td style={styles.td}>{school.schoolType_name}</td>
                                        <td style={styles.td}></td>
                                    </tr>
                               </tbody>
                            </table>
                        </div>
                    </div> }


                    <Loading loading={isLoadingStatistics} background="#00000029" loaderColor="#3498dbAA" />

                    {!statistics? null : <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p><b>II. Estadística de la escuela:</b></p>
                            </span>

                            <table className="table responsive-table mb-0">
                                <tbody>
                                    <tr>
                                        <th style={styles.th}></th>
                                        <th style={styles.th}>Total</th>
                                        <th style={styles.th}>1º</th>
                                        <th style={styles.th}>2º</th>
                                        <th style={styles.th}>3º</th>
                                        <th style={styles.th}>4º</th>
                                        <th style={styles.th}>5º</th>
                                        <th style={styles.th}>6º</th>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Estudiantes</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.total: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.firstGrade: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.secondGrade: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.thirdGrade: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.forthGrade: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.fifthGrade: ''}</td>
                                        <td style={styles.td}>{statistics.students? statistics.students.sixthGrade: ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.th}>Grupos</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.total: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.firstGrade: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.secondGrade: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.thirdGrade: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.forthGrade: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.fifthGrade: ''}</td>
                                        <td style={styles.th}>{statistics.groups? statistics.groups.sixthGrade: ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>Docentes</td>
                                        <td style={styles.td}>*{statistics.docentes? statistics.docentes.total: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.firstGrade: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.secondGrade: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.thirdGrade: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.forthGrade: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.fifthGrade: ''}</td>
                                        <td style={styles.td}>{statistics.docentes? statistics.docentes.sixthGrade: ''}</td>
                                    </tr>
                               </tbody>
                            </table>
                            <span style={{float: 'left', color: '#424141'}}>*Los docentes pueden tener más de un grupo</span>

                        </div>
                    </div>}

                </div>

            </div>
        </div>
    </div>)
}

export default SummarySection;

const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}
