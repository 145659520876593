import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import $ from 'jquery';
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
    faUserPen,
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

import SectorsListSection from './../lists/SectorsListSection';
import RegionSupervisorsListSection from './../lists/figuras/RegionSupervisorsListSection';
import RegionForm from './../forms/RegionForm';

import NewRegionModal from '../modals/NewRegionModal';

const form = FormBuilder.group({
  name: ["", [Validators.required]],
  cct: ["", [Validators.required, Validators.pattern(/^[0-9a-zA-Z]*$/)]],
  officeName: ["", null],
  officeAddress: ["", null],
  colonia: ["", null],
  localidad: ["", null],
  state: [0, [Validators.required]],
  municipality: [0, [Validators.required]],
});

const editTabName = 'edit-supervisor';

const RegionPage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const regionId = props.match && props.match.params? props.match.params.id: 0;
    const userId = props.match && props.match.params? props.match.params.userId: 0;

    const [isLoading, setIsLoading] = useState(false);

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    const selectEditTab = () => {
        setTimeout(()=>{
            $(`#schoolTabs a[href="#${editTabName}"]`).tab('show');
        }, 200);
    }

    setTimeout(()=>{
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            if(e.relatedTarget.id == `${editTabName}-tab`){
                $(`#schoolTabs a[href="#${editTabName}"]`).parents('li').remove();
            }
        })
    }, 200);

    if (userId) { selectEditTab(); }

    return (<div>

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                    <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Región</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="regions-tab" data-toggle="tab" href="#regions" role="tab" aria-controls="regions" aria-selected="false">
                    <FontAwesomeIcon icon={faEarthAmericas} className="mr-2 tab-icon" /><span>Sectores</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="regions-supervisors-tab" data-toggle="tab" href="#regions-supervisors" role="tab" aria-controls="regions-supervisors" aria-selected="false">
                    <FontAwesomeIcon icon={faPersonChalkboard} className="mr-2 tab-icon" /><span>Supervisores</span>
                </a>
            </li>

            {userId && <li className="nav-item">
                <a className="nav-link" id="edit-supervisor-tab" data-toggle="tab" href="#edit-supervisor" role="tab" aria-controls="edit-supervisor" aria-selected="false">
                    <FontAwesomeIcon icon={faUserPen} className="mr-2 tab-icon" /><span>Editar</span>
                </a>
            </li>}

        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">

            <CardBasic title="">
                <div className='container'>
                    <div className='row '>

                        <div className="col-12 col-lg-8 col-md-5 order-md-2" style={{borderRight: "1px solid lightgray"}} data-aos="fade-in" data-aos-delay={50}>

                            {regionId && <RegionForm regionId={regionId} />}

                        </div>

                        <div className="col-12 col-lg-4 col-md-5 order-md-2" data-aos="fade-in" data-aos-delay={50}>

                            <div className="container mb-5 mt-5">
                                <div className="row mb-1">
                                    <div className="col-lg-6">
                                        <p><b>Agregar</b></p>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">
                                        {/* {regionId && <NewRegionModal stateId={regionId} />} */}

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalNewRegion">
                                            <FontAwesomeIcon icon={faEarthAmericas} className="mr-3" /><b>Sector</b>
                                        </button>
                                        
                                    </div>
                                </div>
                                <div className="row mb-1 mt-0">
                                    <div className="col-12">

                                        <button type="submit" className="btn btn-block btn-link-add lift text-left" data-toggle="modal" data-target="#modalChangePassword">
                                            <FontAwesomeIcon icon={faPersonChalkboard} className="mr-3" /><b>Supervisor Regional</b>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </CardBasic>
            </div>
          
            <div className="tab-pane fade" id="regions" role="tabpanel" aria-labelledby="regions-tab">
                <SectorsListSection regionId={regionId} />
            </div>

            <div className="tab-pane fade" id="regions-supervisors" role="tabpanel" aria-labelledby="regions-supervisors-tab">
                <RegionSupervisorsListSection regionId={regionId} />
            </div>

            {userId && <div className="tab-pane fade" id="edit-supervisor" role="tabpanel" aria-labelledby="edit-supervisor-tab">
                <RegionSupervisorsListSection regionId={regionId} />
            </div>}             

        </div>
      </div>)
}

export default RegionPage;
