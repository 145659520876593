import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import Loading from "react-fullscreen-loading";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { Redirect, } from 'react-router-dom';
import Swal from 'sweetalert2';
import { gtag } from 'ga-gtag';
import NewStudentModal from '../../components/modals/NewStudentModal';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import auth from '../../services/users/AuthService';

import {DualListbox} from 'dual-listbox';
import "dual-listbox/dist/dual-listbox.css";
let dualListbox;

const Group = (props) => {

  // TODO: en la version mobile
  // se pueden poner los botones hasta abajo (con css)
  // para darle mas espacio a los nombres de los estudiantes.

  let userState = useSelector(state => state.userState);
  const [studentsAlt, setStudentsAlt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const groupId = props.match.params.id;
  const grupo =  userState.groups?
    userState.groups.find(group => {
      return Number(group.id) === Number(groupId)
    }) : null;

  console.log('grupo', grupo);

  gtag('event', 'page_view', {
      page_title: 'Grupo',
      page_location: 'evaluation',
      page_path: '/evaluation',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : '',
      'group': grupo? `${grupo.grado} Grado - ${grupo.cct}`: ''
  });

  React.useEffect(()=>{
    if(grupo){
      loadData();
    }
  }, [props.match.params]);

  React.useEffect(()=>{

    dualListbox = new DualListbox('.select1', {
        // TODO: guardas las listas temporales y hacer una sola petición
        addEvent: function(value) { // Should use the event listeners
            addStudentsToMyGroup({grupId:grupo.id, ids:[value]});
            gtag('event', 'edit_student', { 
              'action': 'add_to_group',
              'group_id': grupo.id,
              'student_ids': [value],
              'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });     
        },
        removeEvent: async function(value) { // Should use the event listeners
            removeStudentsFromMyGroup({grupId: grupo.id, ids:[value]});
            gtag('event', 'edit_student', { 
              'action': 'remove_from_group',
              'group_id': grupo.id,
              'student_ids': [value],
              'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });     
        },
        availableTitle: '',
        selectedTitle: '',
        searchTitle: '',
        addButtonText: '>',
        removeButtonText: '<',
        addAllButtonText: '>>',
        removeAllButtonText: '<<'
    });

    dualListbox.search_left.setAttribute('placeholder', 'Filtrar estudiante');
    dualListbox.search_right.setAttribute('placeholder', 'Filtrar estudiante');
    dualListbox.remove_all_button.classList.add('hidden');
    dualListbox.add_all_button.classList.add('hidden');

    dualListbox.addEventListener('added', function(event){
        // console.log("event", event);
    });
    dualListbox.addEventListener('removed', function(event){
        // console.log("event", event);
    });

  }, []);

  React.useEffect(()=>{
    dualListbox.available = [];
    dualListbox.selected = [];
    dualListbox.options = "";
    studentsAlt.forEach(function (item) {
        dualListbox._addOption(item);
    });
    dualListbox.redraw();
  }, [studentsAlt]);

  const loadData = () => {
    setIsLoading(true);

    // TODO: ese try catch no cubre todo
    // se necesita un catch en cada then
    try {
      auth.getStudentsWithoutGroup(grupo.school.id).then(result1 => {
        const dataAlt1 = result1.map(student => {
          const fullname = student.firstName + ' ' + student.lastName +
            ' ' + student.secondLastName;
          return { 
            text: fullname, 
            value: student.id, 
            selected: false
          }
        });

        auth.getStudentsByGroup(groupId).then(result2 => {
          const dataAlt2 = result2.map(student => {
            const fullname = student.firstName + ' ' + student.lastName +
              ' ' + student.secondLastName;
            return { 
              text: fullname, 
              value: student.id,
              selected: true
            }
          });
          const allAlt = dataAlt1.concat(dataAlt2).filter((v,i,a)=>a.findIndex(v2=>(v2.value===v.value))===i);
          setStudentsAlt(allAlt);
          setIsLoading(false);
        })
      })
    } catch(error){
      console.log('err', error);
      setIsLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al cargar la información',
        footer: ''
      });
    }

  }

  const addStudentsToMyGroup = ({grupId, ids}) => {
    setIsLoading(true);
    auth.addStudentsToMyGroup(grupId, ids).then(result => {
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al guardar la información',
        footer: ''
      });
      setIsLoading(false);
    });
  }

  const removeStudentsFromMyGroup = ({grupId, ids}) => {
    setIsLoading(true);
    auth.removeStudentsFromMyGroup(grupId, ids).then(result => {
      setIsLoading(false);
    }).catch(error => {
      console.log('err', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocurrió un error al guardar la información',
        footer: ''
      });
      setIsLoading(false);
    });
  }

  const onNewStudentCreated = ({newStudent}) => {
    setIsLoading(true);
    const fullname = newStudent.firstName + ' ' +
        newStudent.lastName + ' ' +
        newStudent.secondLastName;
    const obj = { 
      text: fullname, 
      value: newStudent.id, 
      selected: true
    }
    setStudentsAlt([...studentsAlt, obj]);
    setIsLoading(false);
  }

  return (<>

    {!grupo?
      <Redirect to={{
        pathname: '/dashboard',
        state: { from: props.location }
      }} />
    : <>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    <NewStudentModal onNewStudentCreated={onNewStudentCreated} groupId={groupId} />

    <div className="row mt-5">
      <div className="col-md-9 col-12">
        <h1 className="mb-2">Administración de estudiantes</h1>
        <PageBreadcrumb title={`${grupo.grado} Grado - ${grupo.cct}`} />
      </div>
      <div className="col-md-3 col-12">
        <button type="submit" className="btn btn-block btn-primary mb-5 lift" data-toggle="modal" data-target="#modalNewStudent">
          Agregar Nuevo Alumno +
        </button>
      </div>
    </div>

    <div className="row mt-0">
      <div className="col-12">
        <div className="d-sm-flex align-items-center justify-content-between mb-5" >
          <h1 className="h6 mb-0 text-primary" style={{fontWeight: 400}}>
            1) Seleccione de la tabla 1 los estudiantes que se encuentran en su grupo. Dé clic sobre el nombre del estudiante y después presione la flecha azul. Repita la operación hasta que todos sus estudiantes estén en la Tabla 2.
          </h1>
        </div>
      </div>
    </div >

    <div className="row mt-0">
      <div className="col-6">
        Tabla 1. Estudiantes de la escuela sin grupo asignado
      </div>
      <div className="col-5 ml-5">
        Tabla 2. Estudiantes de su grupo
      </div>

      <div className="col-12">
        <select className="select1" multiple></select>
      </div>
    </div>
    </>}
  </>)
}

export default Group;
