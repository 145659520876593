import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';

const TrayectoFormativo = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState(null);
    
    gtag('event', 'page_view', {
      page_title: 'Trayecto Formativo',
      page_location: 'trayecto-formativo',
      page_path: '/trayecto-formativo',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);
            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters(null);
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    return (<>

        <CardBasic title="">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }

            {stateStatus && <Table
                title="Trayecto Formativo"
                totals={false}
                style = {{
                    pagination: false
                }}
                requestObj = {{
                    configParams: { // DataSource (custom, table)
                        custom: 'trayectoMoodle',
                    },
                    filters,
                    dateRange: null,
                    dimensions: [
                        {name: 'estado', label: 'Estado'},
                    ],
                    breakdownDimension: null,
                    metrics: [],
                    customMetrics: [
                        {name: 'module1Porc', label: 'Módulo 1', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module1)/SUM(module1Max)*100), 2), '%') "},
                        {name: 'module2Porc', label: 'Módulo 2', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module2)/SUM(module2Max)*100), 2), '%') "},
                        {name: 'module3Porc', label: 'Módulo 3', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module3)/SUM(module3Max)*100), 2), '%') "},
                        {name: 'module4Porc', label: 'Módulo 4', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module4)/SUM(module4Max)*100), 2), '%') "},
                        {name: 'module5Porc', label: 'Módulo 5', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module5)/SUM(module5Max)*100), 2), '%') "},
                        {name: 'module6Porc', label: 'Módulo 6', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module6)/SUM(module6Max)*100), 2), '%') "},
                        {name: 'module7Porc', label: 'Módulo 7', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module7)/SUM(module7Max)*100), 2), '%') "},
                        {name: 'module8Porc', label: 'Módulo 8', aggregation: 'none', custom: "CONCAT(FORMAT((SUM(module8)/SUM(module8Max)*100), 2), '%') "},
                    ],
                }}
            />}
        </CardBasic>
    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default TrayectoFormativo;
