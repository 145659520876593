import { USER_SCHOOL_YEAR } from "./ActionTypes";
import { USER_GROUPS } from "./ActionTypes";
import { USER_AUTH } from "./ActionTypes";
import { USER_LOGIN } from "./ActionTypes";
import { USER_SIGNUP } from "./ActionTypes";
import { USER_LOGOUT } from "./ActionTypes";

const initialState = {
    user: null,
    profile: null,
    schoolYear: null,
    groups: []
}

export const UsersReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case USER_SCHOOL_YEAR:
            return {
                ...state,
                schoolYear: action.payload
            };
        case USER_GROUPS:
            return {
                ...state,
                groups: action.payload
            };
        case USER_AUTH:
            return {
                ...state,
                user: action.payload
            };
        case USER_LOGIN:
        case USER_SIGNUP:
            return {
                ...state,
                profile: action.payload,
                schoolYear: action.payload.selectedSchoolYear
            };
        case USER_LOGOUT:
            return {
                ...state,
                profile: null,
                user: null,
                schoolYear: null,
                groups: []
            }; 
        default:
            return state;
    }
    
};
