import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2'
import {
  FormBuilder,
  FieldGroup,
  FieldControl,
  Validators,
} from "react-reactive-form";
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus,
    faUserGroup,
    faChalkboardUser,
    faEarthAmericas,
    faUser,
    faKey,
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

const form = FormBuilder.group({
  cct: ["", [Validators.required, Validators.pattern(/^[0-9a-zA-Z]*$/)]],
  name: ["", [Validators.required]],
  location: ["", null],
  direction: ["", null],
  shift: ["", null],
  marginationLevel: ["", null],
  level: [0, [Validators.required]],
  schoolType: [0, [Validators.required]],
  municipality: [0, [Validators.required]],
  zone: [0, [Validators.required]],
});

const SchoolForm = ({schoolId, zoneId, onSchoolSaved}) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);

    const isEditing = schoolId? true: false;

    const [isLoading, setIsLoading] = useState(false);
    const [currentSchool, setCurrentSchool] = useState(null);

    const [levels, setLevels] = useState([]);
    const [schoolTypes, setSchoolTypes] = useState([]);
    const [zones, setZones] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [zoneSelected, setZoneSelected] = useState(null);
    
    React.useEffect(() => {
        getZones();
        if (schoolId) {
            getSchool(schoolId);
        }

        if(!isEditing && zoneId){
            setZoneSelected(zoneId);
            form.controls.zone.setValue(zoneId);
            form.controls.zone.disable();
            getMunicipiosByZoneId(zoneId, "");
        }

    }, [schoolId]);

    form.get('zone').valueChanges.subscribe((value) => {
        if (form.value.zone !== value) {
            form.controls.municipality.patchValue("");
            getMunicipiosByZoneId(value, "");
        }
    });

    const getSchool = (id) => {
        setIsLoading(true);
        auth.getSchool(id).then(result => {
            setCurrentSchool(result);

            form.controls.cct.setValue(result.cct);
            form.controls.name.setValue(result.name);
            form.controls.location.setValue(result.officeName);
            form.controls.direction.setValue(result.officeAddress);
            form.controls.shift.setValue(result.colonia);
            form.controls.marginationLevel.setValue(result.colonia);
            form.controls.level.setValue(result.level? result.level.id: 0);
            form.controls.schoolType.setValue(result.schoolType? result.schoolType.id: 0);

            if(result.zone){
                setZoneSelected(result.zone.id);
                getMunicipiosByZoneId(result.zone.id, result.municipality? result.municipality.id: "");
            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getZones = () => {
        setIsLoading(true);
        auth.getZones().then(result => {
            setZones(result);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const getMunicipiosByZoneId = (zoneId, municipalityId) => {
        setIsLoading(true);
        auth.getMunicipiosByZoneId(zoneId).then(result => {
            setMunicipios(result);
            form.controls.municipality.setValue(municipalityId);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });   
    }

    const onInput = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/i, "");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
          text: `¿Seguro que desea ${isEditing? 'actualizar': 'crear'} la escuela?`,
          showDenyButton: true,
          confirmButtonText: 'Sí',
          denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    cct: form.value.cct? form.value.cct.trim() : '',
                    name: form.value.name? form.value.name.trim() : '',
                    location: form.value.location? form.value.location.trim() : '',
                    direction: form.value.direction? form.value.direction.trim() : '',
                    shift: form.value.shift? form.value.shift.trim() : '',
                    marginationLevel: form.value.marginationLevel? form.value.marginationLevel.trim() : '',
                    level: form.value.level,
                    schoolType: form.value.schoolType,
                    zone: form.value.zone,
                    municipality: form.value.municipality,
                }

                if(isEditing){
                    data.id = schoolId;
                    update(data);
                }else{
                    data.zone = zoneId;
                    create(data);
                }
            }
        });
    }

    const create = (data) => {
        setIsLoading(true);
        auth.createSchool(data).then(result => {
            if (onSchoolSaved) { onSchoolSaved(result); }
            setIsLoading(false);
            Swal.fire('Escuela creada con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al crear la escuela.'
          });
        });
    }

    const update = (data) => {
        setIsLoading(true);
        auth.updateSchool(data.id, data).then(result => {
            if (onSchoolSaved) { onSchoolSaved(result); }
            setIsLoading(false);
            Swal.fire('Escuela actualizada con éxito', '', 'success');
        }).catch(err => {
          console.log('err', err);
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.message,
            footer: 'Ocurrió un error al actualizar la escuela.'
          });
        });
    }

    return (
        <div className="container mb-5 mt-5">
            <FieldGroup
                control={form}
                strict={false}
                render={({ get, invalid }) => (
                    <form className="user" onSubmit={handleSubmit}>
                        <div className="form-group row mb-3">

                            <FieldControl
                                name="name"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-8">
                                        <span>Nombre</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nombre obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="cct"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>CCT</span><br/>
                                        <input type="text"  onInput={onInput} {...handler()} className="form-control form-control-height" placeholder="..." />
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'CCT obligatorio'}
                                        </span>
                                        <span className="errorAlertSmall pt-3">
                                            {touched && hasError('pattern') && 'CCT inválido'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="zone"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Zona</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {zones && zones.map((zone,i) => <option key={i} value={zone.id}>{zone.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Zona obligatoria'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="municipality"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Municipio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {municipios && municipios.map((municipio,i) => <option key={i} value={municipio.id}>{municipio.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Municipio obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="colonia"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Localidad</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <FieldControl
                                name="level"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nivel/Servicio</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {levels && levels.map((level,i) => <option key={i} value={level.id}>{level.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Nivel obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="schoolType"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Tipo de Escuela</span><br/>
                                        <select {...handler()} className="form-control form-control-height">
                                          <option value="" disabled>...</option>
                                          {schoolTypes && schoolTypes.map((schoolType,i) => <option key={i} value={schoolType.id}>{schoolType.name}</option>)}
                                        </select>
                                        <span className="errorAlertSmall pt-1">
                                            {touched && hasError('required') && 'Tipo de Escuela obligatorio'}
                                        </span>
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                        
                            <FieldControl
                                name="direction"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Dirección</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="shift"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Turno</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                            <FieldControl
                                name="marginationLevel"
                                render={({ handler, touched, hasError }) => (
                                    <div className="col-lg-4">
                                        <span>Nivel de Marginación</span><br/>
                                        <input type="text" {...handler()} className="form-control form-control-height" placeholder="..." />
                                    </div>
                                )}
                            />

                        </div>

                        <div className="form-group row mb-3 mt-5 pt-5">
                            <button type="submit" disabled={invalid} className="btn btn-block btn-primary">
                                GUARDAR
                            </button>
                        </div>

                    </form>
                )}
            />
        </div>
    );

}

export default SchoolForm;
