import React from 'react';

const IconCCT = () => (
    <span style={{
      fontFamily: 'system-ui', 
      fontSize: '16px',
      fontWeight: 900,
      letterSpacing: '1px',
      marginRight: '6px',
      userSelect: 'none', 
    }}>cct</span>
)

export default IconCCT;
