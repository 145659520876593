import React from 'react';

const TitleSection = ({props, stateId, stateName, currentSchoolYear}) => {


    return (<div className="container mb-5">

        {currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-12">
                            <h6>
                                <b>REPORTE RESUMEN</b><br/><br/>
                                <b>Política Escuela de Aprendizajes Fundamentales</b><br/>
                                <b>Estrategia de lengua</b><br/>
                                <b>{currentSchoolYear.name}</b><br/>
                                {stateName && <b>{stateName}</b>}
                            </h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>}
    </div>)
}

export default TitleSection;
