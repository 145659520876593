import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import HorizontalBar2 from '../../components/Charts/HorizontalBar2';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';

const Evaluation = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    gtag('event', 'page_view', {
      page_title: 'Resumen Evaluación',
      page_location: 'evaluation',
      page_path: '/evaluation',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });
    
    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);

            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const downloadTeachersReport = () => {
        setIsLoading(true);
        auth.downloadTeachersAndAnswers(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_teachers_list', {
                'username': (userState && userState.profile)? userState.profile.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadStudentsReport = () => {
        setIsLoading(true);
        auth.downloadStudentsAndAnswers(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_students_list', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadFigurasReport = () => {
        setIsLoading(true);
        auth.downloadFiguras(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_figuras_list', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadFigurasWithTrainingAnio1 = () => {
        setIsLoading(true);
        auth.downloadFigurasWithTrainingAnio1(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_figuras_with_training_anio1', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadFigurasWithTrainingAnio2 = () => {
        setIsLoading(true);
        auth.downloadFigurasWithTrainingAnio2(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_figuras_with_training_anio2', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    return (<>

        <CardBasic title="">
            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }

            <div className='container'>
                <div className="row mt-5">
                    <div className="col-3"></div>
                    <div className="col-3 ml-auto">
                        <button type="button" onClick={() => {downloadTeachersReport()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Reporte (Docentes)
                        </button>
                    </div>
                    <div className="col-3">
                        <button type="button" onClick={() => {downloadStudentsReport()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Reporte (Alumnos)
                        </button>
                    </div>
                    <div className="col-3">
                        <button type="button" onClick={() => {downloadFigurasReport()}} className="btn btn-block btn-primary mb-5 lift">
                          Descargar Reporte (Figuras de Acompañamiento)
                        </button>
                    </div>
                </div>
            </div>

            {stateStatus && <Table
                title="Resumen de Captura de resultados de IDAI e ICE"
                totals={true}
                style = {{
                    pagination: false
                }}
                requestObj = {{
                    configParams: { // DataSource (custom, table)
                        custom: 'responsesSummary2023',
                    },
                    filters,
                    dateRange: null,
                    dimensions: [
                        {name: 'estado', label: 'Estado'},
                        {name: 'instrumento', label: 'Instrumento'},
                        {name: 'etapa', label: 'Etapa'},
                        {name: 'grado', label: 'Grado'},
                    ],
                    breakdownDimension: null,
                    metrics: [
                        {name: 'docentes', label: 'Docentes', aggregation: 'sum', addTotal: true},
                        {name: 'respuestas', label: 'Estudiantes', aggregation: 'sum', addTotal: true},
                    ],
                    customMetrics: null,
                }}
            />}
        </CardBasic>

        <CardBasic title="">
            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'center'}}>
                        <p><b>Resultados del IDAI</b></p>
                    </span>

                    {stateStatus && <HorizontalBar2
                        title="Primer grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                filters, 
                                {lastSchoolYearId: 3},
                                {grado: 1},
                                {'$or': [
                                    {formId: 40},
                                    {formId: 41},
                                    {formId: 42},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#0e72f1', 
                                'B': '#4cb7cd', 
                                'C': '#ec4497', 
                                'D': '#f06d38',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />}

                    {stateStatus && <HorizontalBar2
                        title="Segundo Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                filters, 
                                {lastSchoolYearId: 3},
                                {grado: 2},
                                {'$or': [
                                    {formId: 40},
                                    {formId: 41},
                                    {formId: 42},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#0e72f1', 
                                'B': '#4cb7cd', 
                                'C': '#ec4497', 
                                'D': '#f06d38',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    /> }

                    {stateStatus && <HorizontalBar2
                        title="Tercer Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                filters, 
                                {lastSchoolYearId: 3},
                                {grado: 3},
                                {'$or': [
                                    {formId: 40},
                                    {formId: 41},
                                    {formId: 42},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#0e72f1', 
                                'B': '#4cb7cd', 
                                'C': '#ec4497', 
                                'D': '#f06d38',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    /> }

                </div>
            </div>
        </CardBasic>

        <CardBasic title="">
            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'center'}}>
                        <p><b>Resultados del ICE</b></p>
                    </span>

                    {stateStatus && <HorizontalBar2
                        title="Tercer Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                filters, 
                                {lastSchoolYearId: 3 },
                                {grado: 3},
                                {'$or': [
                                    {formId: 43},
                                    {formId: 44},
                                    {formId: 45},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                '1': '#0e72f1', 
                                '2': '#4cb7cd', 
                                '3': '#ec4497', 
                                '4': '#f06d38',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />}

                </div>
            </div>
        </CardBasic>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    }
}

export default Evaluation;
