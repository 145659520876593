import React from 'react';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import {columns, saveInfo} from '../../components/CsvImporter/configs/supervisors-state.columns.js';

const SupervisorsStateImporter = (props) => {

  return (<>
    <CardBasic title="Importar Supervisores de Estado">
      <CsvImporter columns={columns} saveInfo={saveInfo} />
    </CardBasic>
  </>);

}

export default SupervisorsStateImporter;