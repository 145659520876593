import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import Loading from '../../components/Loading';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import NumberTypeRender from '../../components/CsvImporter/render-components/NumberTypeRender';
import DecimalTypeRender from '../../components/CsvImporter/render-components/DecimalTypeRender';

const CaptureICEImporter = (props) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    auth.getActiveFormsByInstrument(2).then(instrument => {
      createColumns(instrument.forms);

    }).catch(error => {
        console.log('err', error);
        setIsLoading(false);
    });
  }, []);

  const createColumns = (forms) => {
    const errorsStyle = (cell, row, rowIndex, colIndex) => {
      if(row.errors[cols[colIndex].dataField] && cols[colIndex].customType !== 'number'){
        return {
          backgroundColor: '#ED3833'
        };
      }
    }

    const cols = [];

    forms.forEach((m, i) => {
      cols.push({
        dataField: "form_"+String(m.id),
        text: m.name,
        description: m.name,
        editable: true,
        required: true,
        editor: {
          type: Type.SELECT,
          options: [{
            value: '1',
            label: '1'
          }, {
            value: '2',
            label: '2'
          }, {
            value: '3',
            label: '3'
          }, {
            value: '4',
            label: '4'
          }]
        },
        style: errorsStyle
      });
    });

    cols.push({
      dataField: 'cct',
      text: 'CCT Escuela',
      description:'CCT de la escuela',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'firstName',
      text: 'Nombre',
      description:'Nombre del Estudiante',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'lastName',
      text: 'Primer Apellido',
      description:'Primer Apellido del Estudiante',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'secondLastName',
      text: 'Segundo Apellido',
      description:'Segundo Apellido del Estudiante',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'teacherEmail',
      text: 'Correo Electrónico del Profesor',
      description:'Correo Electrónico del Profesor',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'curp',
      text: 'CURP',
      description:'CURP del Estudiante',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'grade',
      text: 'Grado',
      description:'Grado',
      editable: true,
      required: true,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle
    },{
      dataField: 'group',
      text: 'Grupo',
      description:'Grupo',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'speaksIndigenous',
      text: 'Es indígena o hablante de lengua indígena',
      description:'Es indígena o hablante de lengua indígena',
      editable: true,
      required: false,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle
    },{
      dataField: 'hasDisability',
      text: 'Tiene alguna discapacidad, dificultad o trastorno que obstaculice el aprendizaje',
      description:'Tiene alguna discapacidad, dificultad o trastorno que obstaculice el aprendizaje',
      editable: true,
      required: false,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle
    },{
      dataField: 'actions',
      text: 'Acciones',
      editable: false,
      required: false,
      actions: true,
    },{
      dataField: 'messages',
      text: 'Mensajes',
      editable: false,
      required: false,
      actions: true,
    });

    setColumns(cols);
    setIsLoading(false);
  }

  const saveInfo = (data) => auth.saveCaptureICE(data);

  return (<>
    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {columns && columns.length !== 0 ?
      <CardBasic title="Importar Moodle">
        <CsvImporter columns={columns} saveInfo={saveInfo} />
      </CardBasic>
    : null}
  </>);

}



export default CaptureICEImporter;