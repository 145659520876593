import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';

const FormacionDocenteGraphSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>III. Aplicación de los instrumentos diagnósticos para primero, segundo y tercer grados de primaria.</b>
                                </p>
                                <br></br>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'center'}}>
                                <p><b>Porcentaje de aplicación de instrumento IDAI por grado</b></p>
                            </span>

                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'trayectoDocentes2022'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        filters,
                                    ]},
                                    dimensions: [
                                        {name: 'stateName', label: ''},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'menosCien', label: 'Menos de 100% a 70%', color: '#71AD47', aggregation: 'none', custom: "FORMAT((SUM(IF(promedio <= 100 AND promedio > 70, 1, 0))/count(*)*100), 2)"},
                                        {name: 'menosSetenta', label: 'Menos de 70% a 30%', color: '#5B9BD5', aggregation: 'none', custom: "FORMAT((SUM(IF(promedio < 70 AND promedio > 30, 1, 0))/count(*)*100), 2)"},
                                        {name: 'menosTreinta', label: 'Menos de 30% a 1%', color: '#FFC100', aggregation: 'none', custom: "FORMAT((SUM(IF(promedio < 30 AND promedio > 0, 1, 0))/count(*)*100), 2)"},
                                        {name: 'nunca', label: 'Nunca entró', color: '#FF0000', aggregation: 'none', custom: "FORMAT((SUM(IF(promedio = 0, 1, 0))/count(*)*100), 2)"},
                                    ]
                                }}
                            />

                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <Table
                                title="Docentes Formados por Estado"
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'formacionDocentes2022',
                                    },
                                    filters: {'$and': [
                                        filters, 
                                        {schoolYearId: currentSchoolYear.id},
                                    ]},
                                    dateRange: null,
                                    dimensions: [
                                        {name: 'estado', label: 'Estado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [],
                                    customMetrics: [
                                        {name: 'formado', label: 'Formado', color: '#1665c0', aggregation: 'none', custom: "(SUM(IF(customScore NOT IN ('No Capturado', 'En Curso'), 1, 0)))"},
                                        {name: 'total', label: 'Total', color: '#1665c0', aggregation: 'count', custom: "*"},
                                    ]

                                }}
                            />

                        </div>
                    </div>

                </div>
            </div>
        </div> }
    </div>)
}

export default FormacionDocenteGraphSection;
