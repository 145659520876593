import React from 'react';
import { useSelector } from 'react-redux';
import SurveyModal from '../../components/modals/SurveyModal';
import * as Roles from '../../auth/Roles';
import $ from 'jquery';


const Dashboard = (props) => {

  let userState = useSelector(state => state.userState);
  let menuState = useSelector(state => state.menuState);

  React.useEffect(()=>{
    document.getElementById('body').className = 'page-top';

    /*
    if (!menuState.surveyOpen ) {
      $('#modalSurvey').modal('toggle');
    }
    */

  }, []);

  return (

    <>{/*

      {!menuState.surveyOpen ?

          <SurveyModal /> :

      null }
    */}</>
  )
}

export default Dashboard;
