import React from 'react';
/* eslint react/prop-types: 0 */

const Nav = (props) => {

    const dots = [];
    for (let i = 1; i <= props.totalSteps; i += 1) {
        if (props.currentStep === i) {
            dots.push((
                <span
                    key={`step-${i}`}
                    className={`step col-3 active ${i === props.totalSteps? 'lastone': ''}`}
                >
                    <div className="circle">{i}</div>
                    <div className="label">{props.stepsNames[i-1]}</div>
                </span>
            ));
        } else if(props.currentStep > i) {
            dots.push((
                <span
                    key={`step-${i}`}
                    className={`step col-3 done ${i === props.totalSteps? 'lastone': ''}`}
                    onClick={() => props.goToStep(i)}
                >
                    <div className="circle">✓</div>
                    <div className="label">{props.stepsNames[i-1]}</div>
                </span>
            ));
        } else {
            dots.push((
                <span
                    key={`step-${i}`}
                    className={`step col-3 ${i === props.totalSteps? 'lastone': ''}`}
                >
                    <div className="circle">{i}</div>
                    <div className="label">{props.stepsNames[i-1]}</div>
                </span>
            ));
        }

        
    }

    return (
        <div className='container mb-5'>
            <div className='row justify-content-center pb-5'>
                <div className="col-12">
                    <div className='container'>
                        <div className='row'>
                            {dots}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row divider"></div>
        </div>
    );
};

export default Nav;