import { MenuOpenReducer } from '../../services/menu/MenuOpenReducer';
import { UsersReducer } from '../../services/users/UsersReducer';
import { LoadingReducer } from '../../services/loading/LoadingReducer';

import { combineReducers } from 'redux';

export const reducers = combineReducers({
  menuState: MenuOpenReducer,
  userState: UsersReducer,
  loadingState: LoadingReducer
});