import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Export from "react-data-table-component";
import Loading from '../../Loading';
import reports from '../../../services/reports/ReportsService';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

const Table = (props) => {

    const columns = [
        ...props.requestObj.dimensions || props.requestObj.dimension, 
        ...props.requestObj.metrics,
        ...props.requestObj.customMetrics || []
    ].filter(field => !field.hidden).map(field => ({
        name: field.header? <div>{field.header}<br/>{field.label}</div>: field.label,
        selector: field.name,
        sortable: true,
        minWidth: field.minWidth,
        priority: field.priority,
        addTotal: field.addTotal,
    })).sort((a,b) => a.priority - b.priority);

    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [data, setData] = useState([]);

    const getTotals = (data, key) => {
      let total = 0;
      data.forEach(item => {
        total += Number(item[key]);
      });
      return total;
    };

    useEffect(()=>{init()},[]);

    const downloadXLS = () => {
        const ws_name = (props.title && props.title.length > 30)? 
            props.title.substring(0,30) : props.title || '';
        const ws_data = [];

        const titles = columns.map(column => column.name);

        ws_data.push(titles);
        data.forEach((row) => {
            ws_data.push(columns.map(column => row[column.selector]))
        });


        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: ws_name,
            Subject: ws_name,
            Author: "Siaf",
            CreatedDate: new Date()
        };
        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // fix width
        ws["!cols"] = titles.map(title => ({ wch: 20 }));

        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        var wopts = { bookType:"xlsx", bookSST:false, type:"array" };
        var wbout = XLSX.write(wb, wopts);
        saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${ws_name}.xlsx`);

    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return <>
            <button type="button" onClick={downloadXLS} className="btn-primary">Descargar</button>
          </>
    });

    const init = async() => {
        if(isInit){
            return;
        }
        setIsInit(true);
        setIsLoading(true);

        reports.getData(props.requestObj).then(result => {
            const preData = result.map(item => {
                if(props.requestObj.customMetrics) {
                    props.requestObj.customMetrics.map(metric => {
                        if(metric.value){
                            item[metric.name] = metric.value
                        }
                    })
                }
                return item;
            });

            if(props.totals){
                const totals = {};
                columns.forEach(column => {
                    const key = column.name? column.name.toLowerCase(): '';
                    if(column.name === 'Estado'){
                        totals[key] = 'Total';
                    }else if(column.addTotal){
                        totals[key] = getTotals(preData, key);
                    }else{
                        totals[key] = '';
                    }
                });

                preData.push(totals);
            }

            setData(preData);
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

    }

    return (<>
        <div className="table-responsive" style={{display:'grid'}}>

            <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

            <DataTable
              title={props.title}
              paginationComponentOptions={paginationOptions}
              customStyles={props.style.small? smallStyles: customStyles }
              columns={columns}
              data={data}
              subHeader
              noDataComponent="Sin registros"
              subHeaderComponent={subHeaderComponentMemo}
              pagination={props.style && props.style.pagination? props.style.pagination: false}
            />
        </div>
    </>)
}


const paginationOptions = {
    rowsPerPageText: 'Filas Por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true, 
    selectAllRowsItemText: "Todos"
}
const customStyles = {
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
            backgroundColor: '#E0E0E0',
            fontWeight: 'bold',
            fontSize: '10px',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
    headRow: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
};

const smallStyles = {
    headCells: {
        style: {
            paddingLeft: '2px', // override the cell padding for head cells
            paddingRight: '2px',
            backgroundColor: '#E0E0E0',
            fontWeight: 'bold',
            fontSize: '10px',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px', // override the cell padding for data cells
            paddingRight: '0px',
            fontSize: '10px',
        },
    },
    headRow: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
};

export default Table;
