import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { gtag } from 'ga-gtag';
import uploadIcon from'../../../assets/unesco/img/icons/upload_icon2.svg';
import archiveIcon from'../../../assets/unesco/img/icons/archive_icon.svg';
import graphUpIcon from'../../../assets/unesco/img/icons/graph_up_icon.svg';
import mathIcon from'../../../assets/unesco/img/icons/ic_math_white.svg';
import surveyIcon from'../../../assets/unesco/img/icons/ic_survey.svg';
import auth from '../../../services/users/AuthService'
import { userGroups, userLogOut } from '../../../services/users/ActionTypes';
import { clickMenuOpen } from '../../../services/menu/ActionTypes';
import { resetSurvey } from '../../../services/menu/ActionTypes';

import * as Roles from '../../../auth/Roles';

const ArchiveSchoolYearOptions = (props) => {

  let dispatch = useDispatch();

  let userState = useSelector(state => state.userState);

  const [groupsAndInstruments, setGroupsAndInstruments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  const isMobile = width <= 768;

  React.useEffect(()=>{
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  React.useEffect(()=>{
    if(userState.profile && userState.profile.role && userState.profile.role.id === Roles.ROLE_TEACHER){
      if(userState.schoolYear && userState.schoolYear.isCurrentSchoolYear){
        loadInstruments();
        loadGroups();        
      }
    }
  }, [userState.schoolYear]);


  const handleClickMenuOpen = (e) => {
    if(isMobile){
      dispatch(clickMenuOpen());
    }
  }

  const handleSignOut = (e) => {
    e.preventDefault();
    Swal.fire({
      title: '¿Seguro que desea cerrar sesión?',
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        dispatch(userLogOut());
        dispatch(resetSurvey());
        auth.logout();
      }
    });
    
  }

  const loadInstruments = () => {
    if(userState.profile && userState.profile.docente && userState.profile.docente.id){
      auth.getInstruments(userState.profile.docente.id).then(result => {
          setGroupsAndInstruments(result);
        }).catch(error => {
          console.log('err', error);
      }); 
    }
  }

  const loadGroups = () => {
    if(userState.profile && userState.profile.docente && userState.profile.docente.id){
      auth.getGroupsByTeacherId(userState.profile.docente.id, userState.schoolYear? userState.schoolYear.id : '').then(result => {
          setGroups(result.groups);
          dispatch(userGroups(result.groups));
        }).catch(error => {
          console.log('err', error);
      }); 
    }
  }

  const getData = (group, i) => {
    return <>
      <h6 className="collapse-header" key={i}>{group.name}</h6>
      {group.instruments? group.instruments.map((instrument, j) => {
        return (<Link key={j} onClick={handleClickMenuOpen} 
          className="collapse-item" to={`/instruments/${instrument.id}/groups/${group.id}`}>{instrument.name}</Link>)
      }): null}
    </>
  }

  return (
    <>

      {userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN, Roles.ROLE_ESTATAL].includes(userState.profile.role.id) ?
        <div className="sidebar-heading">Reportes</div>
        : null
      }

      {userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN, Roles.ROLE_ESTATAL].includes(userState.profile.role.id) ?
        <li className="nav-item">
          <Link className="nav-link" to="/state-summary-report">
            <i className="sk-book"></i>
            <span>Resumen (Reporte Estado)</span></Link>
        </li> : null
      }

      {userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN, Roles.ROLE_ESTATAL].includes(userState.profile.role.id) ?
        <li className="nav-item">
          <Link className="nav-link" to="/downloadables">
            <i className="sk-book"></i>
            <span>Reportes Descargables</span></Link>
        </li> : null
      }

      {userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN, Roles.ROLE_ESTATAL].includes(userState.profile.role.id) ?
        <>
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporteEscuela" aria-expanded="true" aria-controls="collapseReporteEscuela">
              <i className="sk-book"></i>
              <span>Retención</span>
            </a>
            <div id="collapseReporteEscuela" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/cohort/teachers">Docentes</Link>
                <Link className="collapse-item" to="/cohort/schools">Escuelas</Link>
              </div>
            </div>
          </li>
        </> : null
      }

      {/*

      {userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN, Roles.ROLE_ESTATAL].includes(userState.profile.role.id) ?
        <li className="nav-item">
          <Link className="nav-link" to="/indice-implementacion">
            <i className="sk-book"></i>
            <span>Índice de Implementación</span></Link>
        </li> : null
      }

      */}

      {/* Teachers Options */}


      {(userState.profile && [Roles.ROLE_TEACHER].includes(userState.profile.id_cargo)) ||
       (userState.profile && userState.profile.role && [Roles.ROLE_TEACHER].includes(userState.profile.role.id)) ?
        <>
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporteEscuela" aria-expanded="true" aria-controls="collapseReporteEscuela">
              <i className="sk-book"></i>
              <span>Reporte Escuela</span>
            </a>
            <div id="collapseReporteEscuela" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to={`/school-report/${userState.profile.docente.school.school_id}`} onClick={handleClickMenuOpen} > {userState.profile.docente.school.school_cct}</Link>
              </div>
            </div>
          </li>
        </> : null
      }

      {userState.profile && userState.profile.role && [Roles.ROLE_TEACHER].includes(userState.profile.role.id)
        && userState.profile.docente && userState.profile.docente.school && userState.profile.docente.school.extraInfrastructure_allowMathTest ?
          <>
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporteMate" aria-expanded="true" aria-controls="collapseReporteMate">
                <i className="sk-book"></i>
                <span>Reporte Matemáticas</span>
              </a>
              <div id="collapseReporteMate" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                <div className="py-2 collapse-inner rounded">
                  <Link className="collapse-item" to={`/math-report/${userState.profile.docente.school.school_id}`} onClick={handleClickMenuOpen} > {userState.profile.docente.school.school_cct}</Link>
                </div>
              </div>
            </li>
          </> : null
      }


      {(userState.profile && [Roles.ROLE_PRINCIPAL].includes(userState.profile.id_cargo)) ||
       (userState.profile && userState.profile.role && [Roles.ROLE_PRINCIPAL].includes(userState.profile.role.id)) ?

        <>
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporteEscuela" aria-expanded="true" aria-controls="collapseReporteEscuela">
              <i className="sk-book"></i>
              <span>Reporte Escuela</span>
            </a>


            <div id="collapseReporteEscuela" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="py-2 collapse-inner rounded">
                <Link className="collapse-item" to={`/school-report/${userState.profile.principal.school.school_id}`} onClick={handleClickMenuOpen} > {userState.profile.principal.school.school_cct}</Link>
              </div>
            </div>

          </li>
        </> : null
      }

      {userState.profile && userState.profile.role && [Roles.ROLE_PRINCIPAL].includes(userState.profile.role.id)
        && userState.profile.principal && userState.profile.principal.school && userState.profile.principal.school.extraInfrastructure_allowMathTest ?
          <>
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporteMate" aria-expanded="true" aria-controls="collapseReporteMate">
                <i className="sk-book"></i>
                <span>Reporte Matemáticas</span>
              </a>
              <div id="collapseReporteMate" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                <div className="py-2 collapse-inner rounded">
                  <Link className="collapse-item" to={`/math-report/${userState.profile.principal.school.school_id}`} onClick={handleClickMenuOpen} > {userState.profile.principal.school.school_cct}</Link>
                </div>
              </div>
            </li>
          </> : null
      }


      {/* <!-- Divider --> */}
      {/* <hr className="sidebar-divider" /> */}

      { (userState.profile && userState.profile.role && userState.profile.role.id === Roles.ROLE_TEACHER) &&
        (userState.schoolYear && userState.schoolYear.isCurrentSchoolYear) ?
        <>
          {/* <!-- Heading --> */}
          <div className="sidebar-heading"></div>


          {/* <!-- Matemáticas --> */}

          { userState.profile.docente && userState.profile.docente.school && 
            userState.profile.docente.school.extraInfrastructure_allowMathTest ?

            <li className="nav-item">
              <a className='nav-link featured collapsed' href="#" data-toggle="collapse" data-target="#collapseMatematicas"  aria-controls="collapseMatematicas">
                <img
                  style={{
                    boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
                    color: '#fff',
                    background: '#090761',
                    padding: '2px',
                    borderRadius: '4px',
                    width: '48px',
                    margin: '0px',
                    height: '44px'
                  }}
                  src={mathIcon} className="" 
                />
                <span>Matemáticas</span>
              </a>
              <div id="collapseMatematicas" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="py-2 collapse-inner rounded">
                  {groups? groups.filter(grupo => grupo.grado === 1).map((group,i) => {
                    return <Link key={i} onClick={handleClickMenuOpen} className="collapse-item" to={`/instrument-form/12/groups/${group.id}`}>{group.name} - Grupo {group.label}</Link>
                  }): null}
                </div>
              </div>
            </li>

          : null }


            {/* <!-- Lengua --> */}
            <li className="nav-item">
              <a className='nav-link featured collapsed' href="#" data-toggle="collapse" data-target="#collapseInstrumentos"  aria-controls="collapseInstrumentos">
                <i className="sk-courses"></i>
                <span>Lengua</span>
              </a>
              <div id="collapseInstrumentos" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="py-2 collapse-inner rounded">
                  {groupsAndInstruments? groupsAndInstruments.map((group,i) => {
                    return <div key={i}>{getData(group, i)}</div>
                  }) : null}
                </div>
              </div>
            </li>

            {/* <!-- Nav Item - Utilities Collapse Menu --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                <i className="sk-teamwork"></i>
                <span>Grupos</span>
              </a>
              <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                <div className="py-2 collapse-inner rounded">
                  {groups? groups.map((group,i) => {
                    return <Link className="collapse-item" key={i} to={`/groups/${group.id}`} onClick={handleClickMenuOpen} > {group.name} - Grupo {group.label}</Link>
                  }) : null}
                </div>
              </div>
            </li>
          </>
        : null}

        {(userState.profile && userState.profile.role && [Roles.ROLE_PRINCIPAL, Roles.ROLE_TEACHER].includes(userState.profile.role.id)) ?
          <li className="nav-item">
            <Link className="nav-link" to="/trayecto-formativo">
              <img src={archiveIcon} className="" style={{width: 36, margin: "0 0.46rem"}}/>
              <span>Trayecto Formativo</span>
            </Link>
          </li>
        :null}

        {/* <!-- Nav Item - Pages Collapse Menu --> 
        <li className="nav-item">
          <a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#collapseTwo"  aria-controls="collapseTwo">
            <i className="sk-setting"></i>
            <span>Components</span>
          </a>
          <div id="collapseTwo" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <a className="collapse-item" href="buttons.html">Buttons</a>
              <Link className="collapse-item" to="/cards">Cards</Link>
            </div>
          </div>
        </li>
        */}

        

        {/* <!-- Divider --> */}
         {/* <hr className="sidebar-divider" /> */}

        {/* <!-- Heading -->
        <div className="sidebar-heading">
          Reporte escuela
        </div>
         */}

      }

      </>)
}

export default ArchiveSchoolYearOptions;
