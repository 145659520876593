import React, { useState }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2'
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faGear,
  faBuilding,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import IconCCT from '../../../components/IconCCT';

const RegionsListSection = ({props, stateId}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  React.useEffect(() => {
    getRegions();
  },[]);

  const getRegions = () => {
    setIsLoading(true);

    auth.getRegions(stateId).then(result => {
        setRegions(result);
        setFilteredList(result);
        setIsLoading(false);

    }).catch(error => {
      console.log('err', error);
      setIsLoading(false);
    });   
  }

  const deleteRegion = (id) => {
    Swal.fire({
      text: '¿Seguro que desea eliminar esta región?',
      showDenyButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // TODO: async
        auth.deleteRegion(id).then(result => {
          setRegions(regions.filter(region => region.id !== id));
          setFilteredList(filteredList.filter(region => region.id !== id));
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'La región fue eliminada con éxito',
          });

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);

          Swal.fire({
            icon: 'error',
            title: 'No fue posible eliminar la región',
            text: 'Asegúresese de haber eliminado cualquier sector o supervisor regional perteneciente a esta región.',
          });
        }); 
      }
    });

  }

  const normalizeQuery = (query) => {
    if(!query){
      return "";
    }

    query = query.toLowerCase();

    return query
      .replace("región"," ")
      .replace("region"," ")
      .trim();
  }

  const filterBySearch = (event) => {
     event.preventDefault();
    // Access input value
    const query = normalizeQuery(event.target.keywords.value);
    // Create copy of item list
    var updatedList = [...regions];

    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query) !== -1
    });
    // Trigger render with updated values
    setFilteredList(updatedList);
  };

  return (<div className="container mb-5">
    <div className="row align-items-center justify-content-center text-center">
      <div className="col-12">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto mb-4">
              <div className="section-title text-center ">
                <h3 className="top-c-sep">Lista de Regiones</h3>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="career-search mb-60">
                <form action="#" className="career-form mb-60" onSubmit={filterBySearch}>
                  <div className="row">
                    <div className="col-md-6 col-lg-9 my-3">
                      <div className="input-group position-relative">
                        <input type="text" className="form-control" placeholder="Introduce un nombre..." id="keywords" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 my-3">
                      <button type="submit" className="btn btn-lg btn-block btn-light btn-custom" id="contact-submit">
                        <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar 
                      </button>
                    </div>
                  </div>
                </form>
                <div className="filter-result">
                  <p className="mb-30 ff-montserrat">{/* Total Job Openings : 89 */}</p>

                  {regions && filteredList && filteredList.map((region, i) => { return (

                    <div className="job-box d-md-flex align-items-center justify-content-between mb-3" key={i}>
                      <div className="job-left my-4 d-md-flex align-items-center flex-wrap">
                        <div className="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
                          {region.name.charAt(0)}
                        </div>
                        <div className="job-content">
                          <h5 className="text-center text-md-left mb-0">Región {region.name}</h5>
                          <ul className="d-md-flex ff-open-sans pl-0 text-left">
                            <li className="mr-md-4">
                              <IconCCT />{region.cct}
                              <br/>
                              <FontAwesomeIcon icon={faBuilding} /> {region.officeAddress}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="job-right my-4 flex-shrink-0 mr-3">
                          <Link
                            className="btn d-block w-100 d-sm-inline-block btn-light"
                            to={`/regions/${region.id}`}>
                              <FontAwesomeIcon icon={faGear} className="mr-1" /> Administrar
                          </Link>
                        </div>
                        <div className="job-right my-4 flex-shrink-0">
                          <a href="#" onClick={(e) => {e.preventDefault(); deleteRegion(region.id);}} style={{color: 'red'}} className="btn d-block w-100 d-sm-inline-block btn-danger white">
                            <FontAwesomeIcon icon={faTrash} />
                          </a>
                        </div>
                      </div>
                    </div>
                  )})}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default RegionsListSection;
