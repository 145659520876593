import React, { useState } from 'react';
import Loading from '../../../components/Loading';
import Table from '../../../components/Charts/Table';
import Bar from '../../../components/Charts/Bar';
import HorizontalBar2 from '../../../components/Charts/HorizontalBar2';
import HorizontalBarCapturados from '../../../components/Charts/HorizontalBarCapturados';

const AplicacionPorGrupoSection = ({stateId, currentSchoolYear}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    React.useEffect(() => {
        onStateIdChanged(stateId);
    },[stateId]);

    const onStateIdChanged = async(id) => {
        setIsLoading(true);
        setStateStatus(false);

        if(id){
            setFilters({stateId: id});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
            setIsLoading(false);
        }, 100);
    };

    return (<div className="container mb-5">

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        {stateStatus && currentSchoolYear && <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="col-12 mt-5">
                    <span><b>Porcentaje de aplicación por estado y grado</b></span>
                </div>

                <div className="row align-items-center justify-content-center text-center mt-5">
                    <div className="col-12 mt-5">
                        {stateStatus && <HorizontalBarCapturados
                            title="IDAI (final)"
                            horizontal={true}
                            porcent={true}
                            requestObj = {{
                                configParams: { // DataSource (custom, table)
                                    custom: 'responsesWithNoCaptureEnrol'
                                },
                                dateRange: null,
                                filters: {'$and': [
                                    filters, 
                                    {schoolYearId: currentSchoolYear.id},
                                    {instrumentId: 1},
                                    {formPhase: 3},
                                    {'$or': [
                                        {grado: 1},
                                        {grado: 2},
                                        {grado: 3},
                                    ]},
                                ]},
                                dimension: {name: 'estado', label: ''},
                                dimensions: [{name: 'stateId', label: ''}],
                                breakdownDimension: {name: 'grado', label: 'Grado'},
                                metrics: [],
                                customMetrics: [
                                    {name: 'capturados', label: 'Capturados', aggregation: 'none', custom: "SUM(IF(customScore = 'No Capturado', 0, 1))"},
                                ],
                                colorLabels: {
                                    '1': '#71AD47', 
                                    '2': '#5B9CD5', 
                                    '3': '#FFBF00',
                                },
                                order: {
                                    dimension: 'grado',
                                    ascendent: true,
                                },
                                orderBy: 'ORDER BY grado ASC',
                            }}
                        />}

                    </div>
                </div>
            </div>
        </div> }
    </div>)
}

export default AplicacionPorGrupoSection;
