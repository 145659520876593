import React, { useState } from 'react';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gtag } from 'ga-gtag';
import CardBasic from '../../components/Cards/Basic';
import Table from '../../components/Charts/Table';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import HorizontalBar2 from '../../components/Charts/HorizontalBar2';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';
import IconCsv from'../../assets/unesco/img/icons/ic_csv.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Downloadables = (props) => {

    let userState = useSelector(state => state.userState);

    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [stateSelectedId, setStateSelectedId] = useState('');
    const [stateStatus, setStateStatus] = useState(true);
    const [filters, setFilters] = useState({1:1});

    gtag('event', 'page_view', {
      page_title: 'Reportes Descargables',
      page_location: 'downloadables',
      page_path: '/downloadables',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });
    
    React.useEffect(() => {
        getStates();
    },[]);

    const getStates = () => {
        setIsLoading(true);
        auth.getStates().then(result => {
            setStates(result);

            if (userState.profile && 
                userState.profile.role && 
                userState.profile.state && 
                userState.profile.role.id === Roles.ROLE_ESTATAL) {
                    onStateIdChanged(userState.profile.state.state_id);

            }

            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

        
    }

    const onStateChanged = async(event) => {
        onStateIdChanged(event.target.value);
    }

    const onStateIdChanged = async(stateId) => {
        setStateSelectedId(stateId);
        setStateStatus(false);

        if(stateId){
            setFilters({stateId: stateId});
        }else{
            setFilters({1:1});
        }

        await setTimeout(() => {
            setStateStatus(true);
        }, 100);
    };

    const downloadSchoolsReport = () => {
        setIsLoading(true);
        auth.downloadSchools(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_schools_list', {
                'username': (userState && userState.profile)? userState.profile.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadTeachersReport = () => {
        setIsLoading(true);
        auth.downloadTeachersAndAnswers(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_teachers_list', {
                'username': (userState && userState.profile)? userState.profile.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadStudentsReport = () => {
        setIsLoading(true);
        auth.downloadStudentsAndAnswers(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_students_list', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const downloadFigurasReport = () => {
        setIsLoading(true);
        auth.downloadFiguras(stateSelectedId, userState.schoolYear.id).then(result => {
            gtag('event', 'download_figuras_list', {
                'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
            });
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    return (<>

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <div className="row mt-5">
          <div className="col-md-9 col-12">
            <h1 className="mb-2">Reportes Descargables</h1>
            <PageBreadcrumb title={`${userState.schoolYear.name}`} />
          </div>
          <div className="col-md-3 col-12">
            {userState.profile && userState.profile.role &&
                [Roles.ROLE_SUPER_ADMIN, Roles.ROLE_ADMIN].includes(userState.profile.role.id) ?
                <div className='container'>
                    <div className='row '>
                        <div className="col-14 ml-auto">
                            <select onChange={onStateChanged} value={stateSelectedId} className="form-control">
                              <option value="">Estado (Todos)</option>
                              {states.map((state,i) => <option key={i} value={state.id}>{state.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                : null
            }
          </div>
        </div>

        <div className="row mt-5">

            <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                <div className="card" style={{ height: '100%', margin: '10px'}}>
                  <img className="card-img-top" src={IconCsv} alt="Excel icon" style={styles.imgIcon}  />
                  <div className="card-body">
                    <h5 className="card-title">Escuelas Participantes</h5>
                    <p className="card-text pb-3">
                      Datos de ubicación (sector, zona escolar, cct escuela, municipio, etc.)
                      Número total de docentes, grupos y estudiantes
                    </p>
                  </div>
                  <div className="card-footer p-0" style={styles.footerCard}>
                     <div className="row">
                         <div className="col-6"></div>
                         <div className="col-6" style={styles.downloadButton} onClick={() => {downloadSchoolsReport()}}>
                            <FontAwesomeIcon icon={faDownload} />
                            <small className="text-muted ml-3">Descargar</small>
                         </div>
                     </div>
                 </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                <div className="card" style={{ height: '100%', margin: '10px'}}>
                  <img className="card-img-top" src={IconCsv} alt="Excel icon" style={styles.imgIcon}  />
                  <div className="card-body">
                    <h5 className="card-title">Docentes</h5>
                    <p className="card-text pb-3">
                        Datos de ubicación (sector, zona escolar, cct escuela, municipio, etc.)
                        Información del docente (grado, grupo, correo electrónico)
                        Número de alumnos por grupo, alumnos con resultados capturados por instrumento
                        (IDAI e ICE) y momento (1, 2 y 3)
                        Formación Aventuras en Papel: Estado (formado/en formación), avance en la
                        formación
                    </p>
                  </div>
                  <div className="card-footer p-0" style={styles.footerCard}>
                     <div className="row">
                         <div className="col-6"></div>
                         <div className="col-6"style={styles.downloadButton} onClick={() => {downloadTeachersReport()}}>
                            <FontAwesomeIcon icon={faDownload} />
                            <small className="text-muted ml-3">Descargar</small>
                         </div>
                     </div>
                 </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                <div className="card" style={{ height: '100%', margin: '10px'}}>
                  <img className="card-img-top" src={IconCsv} alt="Excel icon" style={styles.imgIcon}  />
                  <div className="card-body">
                    <h5 className="card-title">Estudiantes</h5>
                    <p className="card-text pb-3">
                        Datos de ubicación (sector, zona escolar, cct escuela, municipio, etc.)
                        Información del docente (grado, grupo)
                        Información estudiante (nombre, sexo y las notas obtenidas en cada instrumento y
                        momento)
                    </p>
                  </div>
                  <div className="card-footer p-0" style={styles.footerCard}>
                     <div className="row">
                         <div className="col-6"></div>
                         <div className="col-6" style={styles.downloadButton} onClick={() => {downloadStudentsReport()}}>
                            <FontAwesomeIcon icon={faDownload} />
                            <small className="text-muted ml-3">Descargar</small>
                         </div>
                     </div>
                 </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
                <div className="card" style={{ height: '100%', margin: '10px'}}>
                  <img className="card-img-top" src={IconCsv} alt="Excel icon" style={styles.imgIcon}  />
                  <div className="card-body">
                    <h5 className="card-title">Figuras de Acompañamiento</h5>
                    <p className="card-text pb-3">
                        Datos de ubicación (sector, zona escolar, cct escuela (directores))
                        Información de la figura (nombre, correo y posición)
                        Formación en acompañamiento: Formación año 1 y año 2 (formado/en formación)
                        Hoja Año 1: Estado (formado/en formación), avance en la formación
                        Hoja Año 2: Estado (formado/en formación), avance en la formación
                    </p>
                  </div>
                  <div className="card-footer p-0" style={styles.footerCard}>
                     <div className="row">
                         <div className="col-6"></div>
                         <div className="col-6" style={styles.downloadButton} onClick={() => {downloadFigurasReport()}}>
                            <FontAwesomeIcon icon={faDownload} />
                            <small className="text-muted ml-3">Descargar</small>
                         </div>
                     </div>
                 </div>
                </div>
            </div>
        </div>

    </>)
}


const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    },
    imgIcon: {
        width: '100px',
        margin: '0 auto',
        padding: '26px 10px 10px',
    },
    footerCard: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        padding: '20px !important',
        borderTop: '1px solid #d5d5d5'
    },
    downloadButton: {
        cursor: 'pointer',
        padding: '16px',
        textAlign: 'right',
        paddingRight: '30px'
    }
}

export default Downloadables;
