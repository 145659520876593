import React from 'react'
import {
  Redirect,
} from 'react-router-dom'
import Landing from './Landing'

import auth from '../../services/users/AuthService'


function HomeWithoutAuth(props) {
const isAuthenticated = auth.isAuthenticated();
  return (
       isAuthenticated
        ? (
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
        ) : (
          <Landing />   
        )  
  );
}

export default HomeWithoutAuth
