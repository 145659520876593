import React,  {useState}  from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Redirect,
} from 'react-router-dom'
import * as Roles from '../../auth/Roles';


function AdministrationHandler(props) {

    let userState = useSelector(state => state.userState);

    return (

        userState.profile && userState.profile.role &&
        [Roles.ROLE_TEACHER].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/teachers/${userState.profile.docente.id}`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_PRINCIPAL].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/schools/${userState.profile.principal.school}`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_ZONE_SUPERVISOR].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/zones/${userState.profile.supervisor.cctId}`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_SECTOR_SUPERVISOR].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/sectos/${userState.profile.supervisor.cctId}`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_REGIONAL_SUPERVISOR].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/regions/${userState.profile.supervisor.cctId}`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_ESTATAL ].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/states/${userState.profile.estatal.cctId}/regions`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_ADMIN ].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/states`
            }} />

        : userState.profile && userState.profile.role &&
        [Roles.ROLE_SUPER_ADMIN ].includes(userState.profile.role.id) ?

            <Redirect to={{
              pathname: `/users`
            }} />

        :
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }} />
    );
}

export default AdministrationHandler
