import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import Loading from '../../components/Loading';
import CsvImporter from '../../components/CsvImporter';
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import NumberTypeRender from '../../components/CsvImporter/render-components/NumberTypeRender';
import DecimalTypeRender from '../../components/CsvImporter/render-components/DecimalTypeRender';

const formId = 46;

const FdaPrimerAnioImporter = (props) => {

  const [isLoading, setIsLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    auth.getQuestionsByFormId(formId).then(form => {
      createColumns(form.questions);

    }).catch(error => {
        console.log('err', error);
        setIsLoading(false);
    });
  }, []);


  const createColumns = (questions) => {
    const errorsStyle = (cell, row, rowIndex, colIndex) => {
      if(row.errors[cols[colIndex].dataField] && cols[colIndex].customType !== 'number'){
        return {
          backgroundColor: '#ED3833'
        };
      }
    }

    const cols = [];

    questions.forEach((m, i) => {

      console.log('m.required', m.required);

      cols.push({
        dataField: "question_"+String(m.id),
        text: m.questionText,
        description: m.description,
        editable: true,
        required: m.required,
        style: errorsStyle
      });
    });

    cols.push({
      dataField: 'state',
      text: 'Estado',
      description:'Estado',
      editable: true,
      required: true,
      editor: {
        type: Type.SELECT,
        async: true,
        options: []
      },
      loadOptions: () => {
        return new Promise((resolve, reject) => {
          auth.getStates().then(states => {
            resolve(states.map(state => {
              return {
                  id: state.id,
                  value: state.name,
                  label: state.name
              }
            }))
          }).catch(error => {
            resolve([]);
          });
        })
      },
      style: errorsStyle
    },{
      dataField: 'year',
      text: 'Año de formación (1 o 2)',
      description:'Año de formación (1 o 2)',
      editable: true,
      required: true,
      customType: 'number',
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <NumberTypeRender { ...editorProps } value={ value } />
      ),
      style: errorsStyle
    },{
      dataField: 'type',
      text: 'Tipo de figura',
      description:'Tipo de figura',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'firstName',
      text: 'Nombre',
      description:'Nombre de la figura',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'lastName',
      text: 'Primer Apellido',
      description:'Primer Apellido de la figura',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'secondLastName',
      text: 'Segundo Apellido',
      description:'Segundo Apellido de la figura',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'teacherEmail',
      text: 'email figura',
      description:'Correo Electrónico de la figura',
      editable: true,
      required: false,
      style: errorsStyle
    },{
      dataField: 'curp',
      text: 'CURP',
      description:'CURP de la figura',
      editable: true,
      required: true,
      style: errorsStyle
    },{
      dataField: 'actions',
      text: 'Acciones',
      editable: false,
      required: false,
      actions: true,
    },{
      dataField: 'messages',
      text: 'Mensajes',
      editable: false,
      required: false,
      actions: true,
    });

    setColumns(cols);
    setIsLoading(false);
  }

  const saveInfo = (data) => {

    const responses = Object.keys(data)
      .filter(key => key.includes('question_'))
      .map(key => {
          const k = key.split('_')[1];

          return {
              questionId: k,
              customResponse: data[key],
              singleResponseId: null,
              optionId: null
          }
      });

    data.responses = responses;

    return auth.saveFda(formId, data);
  }

  return (<>
    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    {columns && columns.length !== 0 ?
      <CardBasic title="Importar Avance Formación Figuras de Acompañamiento">
        <CsvImporter columns={columns} saveInfo={saveInfo} />
      </CardBasic>
    : null}
  </>);

}



export default FdaPrimerAnioImporter;