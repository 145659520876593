import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { gtag } from 'ga-gtag';
import Loading from '../../components/Loading';
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import Swal from 'sweetalert2'
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
 } from "react-reactive-form";

import Topbar from '../../components/public/Topbar';
import auth from '../../services/users/AuthService'
import {userLogin, userAuth} from '../../services/users/ActionTypes';

const loginForm = FormBuilder.group({
    username: ["", [Validators.required]],
    password: ["", Validators.required],
    rememberMe: false
});

const SignIn = (props) => {

  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(()=>{

  }, []);

  const handleReset = () => {
    loginForm.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loginForm.valid) {
      setIsLoading(true);
      auth.login(loginForm.value).then(result => {
        setIsLoading(false);
        dispatch(userAuth(result.user));
        gtag('event', 'login', { 
          'success': 'true',
          'username': loginForm.value.username
        });

        let { from } = location.state || { from: { pathname: "/profiles" } };
        history.push(from.pathname);

      }).catch(error => {
        console.log('err', error);

        gtag('event', 'login_incorrect', { 
          'success': 'false',
          'username': loginForm.value.username,
          'code': error.response? error.response.status: '',
        });

        setIsLoading(false);

        if(error.response && error.response.status === 404){

          Swal.fire({
            title: 'Usuario no encontrado',
            text: 'Si eres supervisor o ATP ¿Deseas registrarte?',
            showCancelButton: true,
            confirmButtonText: '¡Sí! Registrar',
            cancelButtonText: `Regresar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Swal.fire('Saved!', '', 'success')
            } else if (result.isDenied) {
              // Swal.fire('Changes are not saved', '', 'info')
            }

            if (result.isConfirmed) {
              let { from } = location.state || { from: { pathname: "/supervisors/signup" } };
              history.push(from.pathname);
            }


          })

        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Contraseña incorrecta',
          });

        }
        
      });

    }
  }

  return(<>

    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

    <Topbar />

    <header className="py-4 py-md-6" style={{backgroundImage: 'none'}}>
      <div className="container text-center py-xl-2">
        <h1 className="display-4 fw-semi-bold mb-0">Iniciar Sesión</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-scroll justify-content-center">
            <li className="breadcrumb-item">
              <div className="text-gray-800">Bienvenido</div>
            </li>
          </ol>
        </nav>
      </div>
      {/* Img */}
      <img className="d-none img-fluid" src="..." alt="..." />
    </header>

    <div className="container mb-11">
      <div className="row gx-0">
        <div className="col-md-7 col-xl-4 mx-auto">
          
          {/* Form Register */}
          <FieldGroup
            control={loginForm}
            render={({ get, invalid }) => (
              <form className="mb-5" onSubmit={handleSubmit}>
                <div className="form-group mb-5">
                  <label htmlFor="modalSignupEmail1">
                    CURP
                  </label>
                  <FieldControl
                    name="username"
                    render={({ handler, touched, hasError }) => (
                      <div>
                        <input type="text" {...handler()} className="form-control" placeholder="OEAF771012HMCRGR09" />
                        <span className="errorAlert pt-3">
                          {touched && hasError('required') && 'CURP Requerido'}
                        </span>
                      </div>
                    )}
                    meta={{ label: "CURP" }}
                  />
                </div>
                
                <div className="form-group mb-5">
                  <label htmlFor="modalSignupPassword3">
                    Contraseña
                  </label>
                  <FieldControl
                    name="password"
                    render={({ handler, touched, hasError }) => (
                      <div>
                        <input type="password"  {...handler()}  className="form-control" placeholder="**********" />
                        <span className="errorAlert pt-3">
                          {touched && hasError('required') && 'Contraseña obligatoria'}
                        </span>
                      </div>
                    )}
                    meta={{ label: "Contraseña" }}
                  />
                </div>
            
                <button type="submit" disabled={invalid} className="btn btn-block btn-primary" >
                  ENTRAR
                </button>
              </form>
            )}
          />

          {/*
          <p className="mb-0 text-center">
            <Link className="small" to="/register">¿Aún no te has registrado? ¡Hazlo aquí!</Link>
          </p>
          */}

        </div>
      </div>
    </div>

  </>)
}

export default SignIn;
