import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const requestObject = {
  configParams: {},
  scriptParams: {},
  fields: [],
  dateRange: {
      startDate: new Date(),
      endDate: new Date()
  },
}

export default {
  getData(request) {
    return axios
      .post(API_URL + "reports", {params: {...requestObject, ...request}})
      .then(response => {
        return response.data;
      });
  },

};
