import React from 'react';
import { gtag } from 'ga-gtag';
import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Loading from '../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardBasic from '../../components/Cards/Basic';
import auth from '../../services/users/AuthService';
import * as Roles from '../../auth/Roles';
import {userLogin} from '../../services/users/ActionTypes';
import TeacherProfile from './Profiles/TeacherProfile';
import PrincipalProfile from './Profiles/PrincipalProfile';
import ZoneSupervisorProfile from './Profiles/ZoneSupervisorProfile';
import SectorSupervisorProfile from './Profiles/SectorSupervisorProfile';
import RegionSupervisorProfile from './Profiles/RegionSupervisorProfile';
import StateSupervisorProfile from './Profiles/StateSupervisorProfile';
import AdminProfile from './Profiles/AdminProfile';

let ProfileComponent;

const Monitoring = (props) => {

    let profile = useSelector(state => state.userState.profile);
    let dispatch = useDispatch();

    gtag('event', 'page_view', {
      page_title: 'Monitoreo',
      page_location: 'evaluation',
      page_path: '/monitoring',
      'username': profile && profile.user? profile.user.username : ''
    });

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [showSubHeader, setShowSubHeader] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [perPage, setPerPage] = React.useState(10);


    const filteredItems = data.filter(
        item => item.schools_cct && item.schools_cct.toLowerCase().includes(filterText.toLowerCase()),
    );

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
      <>
        <input id="search" autoFocus type="text" placeholder="Filtrar por CCT" value={filterText} onChange={onFilter} />
        <button type="button" onClick={onClear} className="btn-primary">X</button>
      </>
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
            Roles.ROLE_REGIONAL_SUPERVISOR,
            Roles.ROLE_SECTOR_SUPERVISOR,
            Roles.ROLE_ZONE_SUPERVISOR,
        ].includes(profile.role.id) ){
            return (
                <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            );
        }

        return null;

        
    }, [filterText, resetPaginationToggle]);

    const fetchUsers = async (page) => {
        setLoading(true);

        auth.getSchoolsFullInfoByProfile(profile.id, page, perPage).then(response => {
            setData(response.data.data);
            setTotalRows(Number(response.data.total));
            setLoading(false);
            setIsLoading(false);

        }).catch(error => {
            console.log('err', error);
            setLoading(false);
            setIsLoading(false);
        });
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        auth.getSchoolsFullInfoByProfile(profile.id, page, newPerPage).then(response => {
            setData(response.data.data);
            setPerPage(newPerPage);
            setLoading(false);
            setIsLoading(false);

        }).catch(error => {
            console.log('err', error);
            setLoading(false);
            setIsLoading(false);
        });

    };

    React.useEffect(() => {
        if (profile.user) {
            if([Roles.ROLE_SUPER_ADMIN, 
                Roles.ROLE_ADMIN,
                Roles.ROLE_ESTATAL,
                Roles.ROLE_REGIONAL_SUPERVISOR,
                Roles.ROLE_SECTOR_SUPERVISOR,
                Roles.ROLE_ZONE_SUPERVISOR,
            ].includes(profile.role.id) ){
                setShowSubHeader(true);
            }

            createSummary();

            auth.getInstrumentByShortName('IEI').then(instrument => {
                createColumns(instrument);
                fetchUsers(1); // fetch page 1 of users

            }).catch(error => {
                console.log('err', error);
                setIsLoading(false);
            });             
        }

    }, []);

    const LinkEscuela = (row) => (
        <div style={{ 
            textAlign: "left",
            width: "100%" 
          }}>{row.schools_cct}
        </div>
    );

    const LinkDetalleEscuela = (row) => (
        <div style={{ 
            textAlign: "left",
            width: "100%" 
          }}>
            <Link to={{ pathname: `/schools/${row.schools_id}` }}> {row.schools_cct}
            </Link>
        </div>
    );

    const LinkReporteEscuela = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>
            <Link to={{ pathname: `/school-report/${row.schools_id}` }}> Ir
        </Link></div>
    );

    const AjustesEscuela = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>
            <Link to={{ pathname: `/school-report/${row.schools_id}` }}>
                <i className="sk-setting"></i>
            </Link>
        </div>
    );


    

    const LinkTrayectoFormativo = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}></div>
    );

    const IndiceEscuela = (row) => (
        <div style={{
            textAlign: "center",
            width: "100%" 
        }}>
            <Link to={{ pathname: `/school-indice/${row.schools_id}` }}>
                {(row.indice.value * 100)}%
            </Link>
        </div>
    );

    const SemaforoIDAIEscuela = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>{row.semaforo.responsesIDAI} / {row.semaforo.docentesTotales}</div>
    );

    const SemaforoICEEscuela = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>{row.semaforo.responsesICE} / {row.semaforo.docentesTercero}</div>
    );
    
    const LinkSeguimiento = ({row, form}) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>
            <Link to={{     
                pathname: `/implementation-index`,
                schoolId: row.schools_id,
                schoolCct: row.schools_cct,
                formId: form.id
            }}> Ir
        </Link></div>
    );

    const LinkImplementacion = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>
            <Link to={{     
                pathname: `/implementation-index`,
                schoolId: row.schools_id,
                schoolCct: row.schools_cct,
                formId: 35
            }}> Ir
        </Link></div>
    );

    const LinkTrayecto = (row) => (
        <div style={{ 
            textAlign: "center",
            width: "100%" 
          }}>
            <Link to={{     
                pathname: `/trayecto-formativo`,
            }}> Ir
        </Link></div>
    );

    const createSummary = () => {
        if([Roles.ROLE_TEACHER].includes(profile.role.id) ){
            ProfileComponent = TeacherProfile;
        }

        if([Roles.ROLE_PRINCIPAL].includes(profile.role.id) ){
            ProfileComponent = PrincipalProfile;
        }

        if([Roles.ROLE_ZONE_SUPERVISOR].includes(profile.role.id) ){
            ProfileComponent = ZoneSupervisorProfile;
        }

        if([Roles.ROLE_SECTOR_SUPERVISOR].includes(profile.role.id) ){
            ProfileComponent = SectorSupervisorProfile;
        }

        if([Roles.ROLE_REGIONAL_SUPERVISOR].includes(profile.role.id) ){
            ProfileComponent = RegionSupervisorProfile;
        }

        if([Roles.ROLE_ESTATAL].includes(profile.role.id) ){
            ProfileComponent = StateSupervisorProfile;
        }

        if([Roles.ROLE_ADMIN, Roles.ROLE_SUPER_ADMIN].includes(profile.role.id) ){
            ProfileComponent = AdminProfile;
        }
    }

    const createColumns = (instrument) => {
        const cols = [];

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN
        ].includes(profile.role.id) ){
            cols.push({
                name: "Estado",
                selector: row => row["state_name"],
                sortable: true,
                cell: row => row["state_name"],
            });
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL
        ].includes(profile.role.id) ){
            cols.push({
                name: "Región",
                selector: row => row["region_name"],
                sortable: true,
                cell: row => row["region_name"],
            });
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
            Roles.ROLE_REGIONAL_SUPERVISOR,
        ].includes(profile.role.id) ){
            cols.push({
                name: "Sector",
                selector: row => row["sector_name"],
                sortable: true,
                cell: row => row["sector_name"],
            });
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
            Roles.ROLE_REGIONAL_SUPERVISOR,
            Roles.ROLE_SECTOR_SUPERVISOR,
        ].includes(profile.role.id) ){
            cols.push({
                name: "Zona Escolar",
                selector: row => row["zone_name"],
                sortable: true,
                cell: row => row["zone_name"],
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            });
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
            Roles.ROLE_REGIONAL_SUPERVISOR,
            Roles.ROLE_SECTOR_SUPERVISOR,
            Roles.ROLE_ZONE_SUPERVISOR,
            Roles.ROLE_PRINCIPAL,
            Roles.ROLE_TEACHER
        ].includes(profile.role.id) ){
            cols.push({
                name: "CCT Escuela",
                selector: row => row["schools_cct"],
                sortable: true,
                width: "200px",
                cell: row => <LinkEscuela {...row} />,
                /* cell: row => row["schools_cct"], */
            },
            {
                name: "Escuela",
                selector: row => row["schools_name"],
                sortable: true,
                cell: row => row["schools_name"],
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            },/*{
                name: <div>Ajustes</div>,
                selector: row => row["schools_cct"],
                sortable: true,
                cell: row => <AjustesEscuela {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            },*/{
                name: <div>Reporte Escuela</div>,
                selector: row => row["schools_cct"],
                sortable: true,
                cell: row => <LinkReporteEscuela {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            }/*,{
                name: <div>Trayecto Formativo Docentes</div>,
                selector: row => row["schools_cct"],
                sortable: true,
                cell: row => <LinkTrayectoFormativo {...row} />,
                width: "200px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            }*/);
        }

        if([Roles.ROLE_ZONE_SUPERVISOR,].includes(profile.role.id) ){
            cols.push({
                name: <div>Trayecto formativo docentes</div>,
                selector: row => row["schools_indice"],
                sortable: true,
                cell: row => <LinkTrayecto {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            });
            /*,{
                name: <div>Encuesta 2</div>,
                selector: row => row["schools_indice"],
                sortable: true,
                cell: row => <LinkImplementacion {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            })*/
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
        ].includes(profile.role.id) ){
            cols.push({
                name: <div>Índice Implementación</div>,
                selector: row => row["schools_indice"],
                sortable: true,
                cell: row => <IndiceEscuela {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            });
        }

        if([Roles.ROLE_SUPER_ADMIN, 
            Roles.ROLE_ADMIN,
            Roles.ROLE_ESTATAL,
        ].includes(profile.role.id) ){
            cols.push({
                name: <div>Avance IDAI</div>,
                selector: row => row["schools_semaforo"],
                sortable: true,
                cell: row => <SemaforoIDAIEscuela {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            },{
                name: <div>Avance ICE</div>,
                selector: row => row["schools_semaforo"],
                sortable: true,
                cell: row => <SemaforoICEEscuela {...row} />,
                width: "150px",
                headerStyle: (selector, id) => {
                    return { textAlign: "center" };
                },
            });
        }

        if(instrument && instrument.instrumentRoles &&
            instrument.instrumentRoles.map(instrumentRole => instrumentRole.role.id).includes(profile.role.id)){
            instrument.forms.forEach((form => {
                if (form.status === 'active' && form.isVisible) {
                    cols.push({
                        name: <div>{instrument.name}<br/>{form.name}</div>,
                        selector: row => row["schools_cct"],
                        sortable: true,
                        cell: row => <LinkSeguimiento row={row} form={form} />,
                        width: "300px",
                        headerStyle: (selector, id) => {
                            return { textAlign: "center" };
                        },
                    })                    
                }
            }))
        }
        setColumns([...columns, ...cols]);
    }

    return (<>

        {ProfileComponent && <ProfileComponent />}

        <CardBasic title={props.title}>

            <div className="table-responsive">

                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                <DataTable
                  title=""
                  fixedHeader
                  fixedHeaderScrollHeight="500px"
                  paginationComponentOptions={paginationOptions}
                  customStyles={customStyles}
                  columns={columns}
                  data={filteredItems}
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader={showSubHeader}
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
            </div>
        </CardBasic>
    </>)
}

const paginationOptions = {
    rowsPerPageText: 'Filas Por Página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true, 
    selectAllRowsItemText: "Todos"
}
const customStyles = {
    headCells: {
        style: {
            paddingLeft: '4px', // override the cell padding for head cells
            paddingRight: '4px',
            backgroundColor: '#E0E0E0',
            fontWeight: 'bold',
            textAlign: 'center',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
        },
    },
    headRow: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '36px', // override the row height
        },
    },
};


export default Monitoring;
