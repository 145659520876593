import React, { useState }from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMagnifyingGlass, 
  faEnvelope, 
  faIdCard, 
  faTrash,
  faChalkboardUser
} from '@fortawesome/free-solid-svg-icons';
import auth from '../../../services/users/AuthService';
import Swal from 'sweetalert2';

const GroupSection = ({props, schoolId, grupo, teachers, onDeleteGroup}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [teacherSelected, setTeacherSelected] = useState(grupo.teacher.id);

    const setTeacherGroup = (grupoId, teacherId) => {
      Swal.fire({
          title: '',
          text: '¿Seguro que desea asignar el docente al grupo?',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            setIsLoading(true);
            auth.changeGroupTeacher(grupoId, teacherId).then(result => {
              setTeacherSelected(teacherId);
              setIsLoading(false);
              Swal.fire('Docente asignado con éxito', '', 'success');

            }).catch(error => {
              console.log('err', error);
              setIsLoading(false);
              Swal.fire('Ocurrió un error al asignar al docente', '', 'error');
            });

          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
      });

    }

    const deleteGroup = (grupoId) => {
      Swal.fire({
          title: '¿Seguro desea eliminar el grupo?',
          text: 'No es posible eliminar grupos con alumnos asignados',
          showCancelButton: true,
          confirmButtonText: 'Eliminar',
          confirmButtonColor: '#dc3545',
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            setIsLoading(true);
            auth.deleteGroup(grupoId).then(result => {
              setIsLoading(false);
              Swal.fire('Grupo eliminado con éxito', '', 'success');
              onDeleteGroup(grupoId);

            }).catch(err => {
              console.log('err', err);
              setIsLoading(false);
              Swal.fire('No fue posible eliminar el grupo', err.response && err.response.data && err.response.data.message? err.response.data.message: '', 'error');
            });

          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
      });

    }

    return (<>{!grupo? null :
      <div className="container mb-5 mt-5 text-left">
        <div className="row mb-1">
          <div className="col-lg-6">
            <p><b>Grupo: </b>{grupo.name}</p>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-lg-10">
            <span><b>Docente: </b>
              <div className="select-container" style={{display: 'inline-flex', width: '60%', marginLeft: '1rem'}}>
                <select className="custom-select" value={teacherSelected} onChange={(e) => setTeacherGroup(grupo.id, e.target.value)}>
                  <option value={0}>Selecciona un docente</option>

                  {teachers && teachers.map((teacher, i) => { return (
                    <option key={i} value={teacher.id}>
                      {teacher.firstName} {teacher.lastName} {teacher.secondLastName}
                    </option>
                  )})}

                </select>

              </div>
            </span>

          </div>
        </div>
        <div className="row mb-1">
          <div className="col-lg-6">
            <Link 
              className="btn btn-block btn-light btn-link-add lift text-left mt-5"
              to={`/schools/${schoolId}/groups/${grupo.id}`}>
                <FontAwesomeIcon icon={faChalkboardUser} className="mr-3" /><b>Editar Alumnos</b>
            </Link>
          </div>
          <div className="col-lg-2">
            <button 
              type="button"
              style={{backgroundColor: '#dc3545', color: 'white'}}
              className="btn btn-block btn-danger mt-5 lift"
              onClick={() => {deleteGroup(grupo.id)}}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>

      </div>
    }</>)
}

export default GroupSection;
