import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const SelectBox = ({  handler, hasError, meta: { label, name, options } }) => {return (
  <tr>
      <td style={{width: '35%'}}>*{label}</td>
      <td style={{width: '20%'}}>
          <div className="icons-match">
              <i className="fas fa-link mr-1"></i>
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </div>
      </td>
      <td style={{width: '35%', paddingRight: '0px'}}>
        <select name={name} className="form-control" {...handler()}>
          <option value="">-- Sin Seleccionar --</option>
          {options.map((option, i) => (
            <option key={i} disabled={option.disabled} value={option.value}>{option.label}</option>
          ))}
        </select>
      </td>
      <td style={{width: '10%'}}>
        <i className={hasError('required')? 'fas fa-check-circle icon-done': 'fas fa-check-circle icon-done icon-ready'}></i>
      </td>
  </tr>
)};
export default SelectBox;
