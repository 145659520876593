import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import auth from '../../../services/users/AuthService';
import NumberTypeRender from '../render-components/NumberTypeRender';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createSchool(data);

const columns = [{
  dataField: 'level',
  text: 'Tipo de Sostenimiento',
  description: 'Nivel de la escuela (indígena, federalizada, estatal)',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    async: true,
    options: []
  },
  loadOptions: () => {
    return new Promise((resolve, reject) => {
      auth.getLevels().then(levels => {
        resolve(levels.map(level => {
          return {
              id: level.id,
              value: level.name,
              label: level.name
          }
        }))
      }).catch(error => {
        resolve([]);
      });
    })
  },
  style: errorsStyle
},{
  dataField: 'region',
  text: 'Región',
  description:'Región de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'sectorName',
  text: 'Sector',
  description:'Sector de la escuela',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'sectorCCT',
  text: 'CCT Sector',
  description:'CCT del Sector de la escuela',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'zoneName',
  text: 'Zona Escolar',
  description:'Zona Escolar',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'zoneCCT',
  text: 'CCT Zona Escolar',
  description:'CCT de la Zona Escolar',
  editable: true,
  required: false,
  style: errorsStyle
}, {
  dataField: 'shift',
  text: 'Turno',
  description: 'Turno de la escuela (Matutino / Vespertino)',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    options: [{
     value: 'MATUTINO',
      label: 'MATUTINO'
    }, {
      value: 'VESPERTINO',
      label: 'VESPERTINO'
    }, {
      value: 'CONTINUO',
      label: 'CONTINUO'
    }, {
      value: 'DISCONTINUO',
      label: 'DISCONTINUO'
    }, {
      value: 'JORNADA AMPLIADA',
      label: 'JORNADA AMPLIADA'
    }, {
      value: 'TIEMPO COMPLETO',
      label: 'TIEMPO COMPLETO'
    }]
  },
  style: errorsStyle
},{
  dataField: 'name',
  text: 'Escuela',
  description:'Nombre de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'cct',
  text: 'CCT',
  description:'CCT de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'address',
  text: 'Dirección',
  description:'Dirección de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'location',
  text: 'Localidad',
  description:'Localidad de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'state',
  text: 'Estado',
  description:'Estado',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    async: true,
    options: []
  },
  loadOptions: () => {
    return new Promise((resolve, reject) => {
      auth.getStates().then(states => {
        resolve(states.map(state => {
          return {
              id: state.id,
              value: state.name,
              label: state.name
          }
        }))
      }).catch(error => {
        resolve([]);
      });
    })
  },
  style: errorsStyle
},{
  dataField: 'municipality',
  text: 'Municipio',
  description:'Municipio',
  editable: true,
  required: true,
  style: errorsStyle
}, {
  dataField: 'marginalizationLevel',
  text: 'Grado de Marginación',
  description: 'Grado de Marginación de la escuela (Bajo, Medio, Alto...)',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    options: [{
      value: 'MUY BAJO',
      label: 'MUY BAJO'
    }, {
      value: 'BAJO',
      label: 'BAJO'
    }, {
      value: 'MEDIO',
      label: 'MEDIO'
    }, {
      value: 'ALTO',
      label: 'ALTO'
    }, {
      value: 'MUY ALTO',
      label: 'MUY ALTO'
    }],
  },
  style: errorsStyle
},{
  dataField: 'schoolType',
  text: 'Tipo de Organización',
  description: 'Tipo de Organización (organización completa, unidocente, multigrado, etc)',
  editable: true,
  required: true,
  editor: {
    type: Type.SELECT,
    async: true,
    options: []
  },
  loadOptions: () => {
    return new Promise((resolve, reject) => {
      auth.getSchoolTypes().then(schoolTypes => {
        resolve(schoolTypes.map(schoolType => {
          return {
              id: schoolType.id,
              value: schoolType.name,
              label: schoolType.name
          }
        }))
      }).catch(error => {
        resolve([]);
      });
    })
  },
  style: errorsStyle
}
,{
  dataField: 'actions',
  text: 'Acciones',
  editable: false,
  required: false,
  actions: true,
},{
  dataField: 'messages',
  text: 'Mensajes',
  editable: false,
  required: false,
  actions: true,
}];

export {columns, saveInfo};
