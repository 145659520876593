import React, {useCallback} from 'react'
import { useDropzone } from 'react-dropzone'
import Loading from '../../Loading';
import { parse } from "papaparse";
import IconCsv from'../../../assets/unesco/img/icons/ic_csv.svg';

const First = (props) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setIsLoading(true);

        props.setRaw({
            data: [],
            meta: {
              fields: []
            }
        });

        Array.from(acceptedFiles)
            .filter((file) => file.type === "text/csv")
            .forEach(async (file) => {
                const text = await file.text();
                const result = parse(text, {
                    header: true,
                    transformHeader:function(h) {
                        return h.trim().toUpperCase();
                    }
                });
                props.setRaw(result);
            });
            setIsLoading(false);
            props.nextStep();
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        maxFiles: 1,
        accept: { 
            "text/csv": [".csv"] 
        },
        onDrop
    });

    return (<>
        <div className='container mb-5 mt-5'>
            <div className='row justify-content-center pb-5'>
                <div className="col-12">
                    <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />
                    <div className={`dragdrop-zone ${
                          isDragActive ? "highlighted" : ""
                        }`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        { isDragActive ?
                            <div style={{alignItems: 'center'}}>
                                <p className="ml-3">Suelta tu archivo aquí ...</p>
                            </div> :
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <img src={IconCsv} className="csv-icon" alt="..." />
                                <div className="ml-3">
                                <p style={{color: '#3280CD'}}>Arrastra un archivo o da clic aquí para seleccionarlo</p>
                                  <p>Sólo se permiten archivos .csv</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default First;
