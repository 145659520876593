import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import auth from '../../../services/users/AuthService';
import NumberTypeRender from '../render-components/NumberTypeRender';

const errorsStyle = (cell, row, rowIndex, colIndex) => {
  if(row.errors[columns[colIndex].dataField]){
    return {
      backgroundColor: '#ED3833'
    };
  }
}

const saveInfo = (data) => auth.createUserStudent(data);

const columns = [{
  dataField: 'cct',
  text: 'CCT Escuela',
  description:'CCT de la escuela',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'firstName',
  text: 'Nombre',
  description:'Nombre del Estudiante',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'lastName',
  text: 'Primer Apellido',
  description:'Primer Apellido del Estudiante',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'secondLastName',
  text: 'Segundo Apellido',
  description:'Segundo Apellido del Estudiante',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'teacherEmail',
  text: 'Correo Electrónico del Profesor',
  description:'Correo Electrónico del Profesor',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'curp',
  text: 'CURP',
  description:'CURP del Estudiante',
  editable: true,
  required: true,
  style: errorsStyle
},{
  dataField: 'grade',
  text: 'Grado',
  description:'Grado',
  editable: true,
  required: true,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},{
  dataField: 'group',
  text: 'Grupo',
  description:'Grupo',
  editable: true,
  required: false,
  style: errorsStyle
},{
  dataField: 'speaksIndigenous',
  text: 'Es indígena o hablante de lengua indígena',
  description:'Es indígena o hablante de lengua indígena',
  editable: true,
  required: false,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},{
  dataField: 'hasDisability',
  text: 'Tiene alguna discapacidad, dificultad o trastorno que obstaculice el aprendizaje',
  description:'Tiene alguna discapacidad, dificultad o trastorno que obstaculice el aprendizaje',
  editable: true,
  required: false,
  customType: 'number',
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
    <NumberTypeRender { ...editorProps } value={ value } />
  ),
  style: errorsStyle
},



{
  dataField: 'actions',
  text: 'Acciones',
  editable: false,
  required: false,
  actions: true,
},{
  dataField: 'messages',
  text: 'Mensajes',
  editable: false,
  required: false,
  actions: true,
}];

export {columns, saveInfo};
