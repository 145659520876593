import React, { useEffect, useState, createRef, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faSchool, 
    faUsersLine, 
    faPersonChalkboard, 
    faUserPlus, 
    faUserGroup, 
    faChalkboardUser 
} from '@fortawesome/free-solid-svg-icons';
import CardBasic from '../../../components/Cards/Basic';
import auth from '../../../services/users/AuthService';

import TeachersListSection  from './../lists/figuras/TeachersListSection';
import GroupsListSection    from './../sections/GroupsListSection';
import SchoolSection        from './../sections/SchoolSection';

const config = {
    id: 'teachers',
    searchText: '',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    options: [10, 20, 50, 100],
    order: {
      field: 'id',
      reverse: false
    },
    filter: {
      name: '',
      "school.id": 272,
    },
  };

const SchoolPage = (props) => {

    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const schoolId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);
    const [teachers, setTeachers] = useState([]);
    const [schoolInfo, setSchoolInfo] = useState(null);

    React.useEffect(() => {
        getSchoolInfo();
        getTeachers();
    },[schoolId]);

    const getTeachers = () => {
        setIsLoading(true);
        auth.getTeachersBySchoolId(schoolId).then(result => {
            setTeachers(result);
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    const getSchoolInfo = () => {
        setIsLoading(true);
        auth.getSchool(schoolId).then(result => {
            setSchoolInfo(result);
            setIsLoading(false);

        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });
    }

    gtag('event', 'page_view', {
      page_title: 'Pantalla Escuela',
      page_location: 'school-screen',
      page_path: '/school-screen',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    return (<div>
        <ul className="nav nav-tabs tabs-admin" id="schoolTabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
                <FontAwesomeIcon icon={faSchool} className="mr-2 tab-icon" /><span>Escuela</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="teachers-tab" data-toggle="tab" href="#teachers" role="tab" aria-controls="teachers" aria-selected="false">
                <FontAwesomeIcon icon={faPersonChalkboard} className="mr-2 tab-icon" /><span>Docentes</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">
                <FontAwesomeIcon icon={faUsersLine} className="mr-2 tab-icon" /><span>Grupos</span>
            </a>
          </li>
        </ul>
        <div className="tab-content" id="schoolTabsContent">

            <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                <SchoolSection schoolId={schoolId} />
            </div>
          
            <div className="tab-pane fade" id="teachers" role="tabpanel" aria-labelledby="teachers-tab">
                <TeachersListSection schoolId={schoolId} teachers={teachers}/>
            </div>
            
            <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                <GroupsListSection schoolId={schoolId} teachers={teachers}/>
            </div>
        </div>
      </div>)
}

export default SchoolPage;
