import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { gtag } from 'ga-gtag';
import auth from '../../../services/users/AuthService';
import Loading from '../../../components/Loading';
import CardBasic from '../../../components/Cards/Basic';
import SummarySection from './sections/SummarySection';
import GoalsSection from './sections/GoalsSection';
import IDAISection from './sections/IDAISection';
import ICESection from './sections/ICESection';
import PorcentajesSection from './sections/PorcentajesSection';
import ResultadosIDAISection from './sections/ResultadosIDAISection';

const SchoolReportV3 = (props) => {

    let dispatch = useDispatch();
    let componentRef = useRef();
    let userState = useSelector(state => state.userState);
    const [isLoading, setIsLoading] = useState(false);
    const [currentSchoolYear, setCurrentSchoolYear] = useState(null);

    const schoolId = props.schoolId;

    React.useEffect(()=>{
        setCurrentSchoolYear(null);
        setTimeout(()=>{
            setCurrentSchoolYear(userState.schoolYear);
        }, 200);

    }, [userState.schoolYear]);

    gtag('event', 'page_view', {
      page_title: 'Reporte Escuela',
      page_location: 'school-report',
      page_path: '/school-report',
      'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
    });

    const downloadReport = (e) => {
        e.preventDefault();
        gtag('event', 'download_school_report', {
            'version': 'v3',
            'username': (userState && userState.profile && userState.profile.user)? userState.profile.user.username : ''
        });
    }

    return (<div className="container mb-5">
        <div className="row align-items-center justify-content-center">
            <div className="col-11">

                <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

                {schoolId && currentSchoolYear && !isLoading? <div className="row align-items-center justify-content-center text-center">

                    <div className="container mb-5">
                        <div className="row align-items-center justify-content-center text-center mt-5">
                            <div className="col-4 pl-0"></div>
                            <div className="col-2 ml-auto mr-0 pr-0" onClick={(e) => {downloadReport(e);}}>
                                <ReactToPrint
                                  trigger={() => <Button>Imprimir</Button>}
                                  content={() => componentRef}
                                />
                            </div>
                        </div>
                    </div>

                    <div ref={(el) => (componentRef = el)}>

                        <CardBasic title="">
                            <SummarySection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />

                            <PorcentajesSection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                            
                            <GoalsSection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />

                            <ResultadosIDAISection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />

                        </CardBasic>

                        <div className="pagebreak" />

                        <CardBasic title="">
                            <IDAISection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                        </CardBasic>

                        <div className="pagebreak" />

                        <CardBasic title="">
                            <ICESection schoolId={schoolId} currentSchoolYear={currentSchoolYear} />
                        </CardBasic>

                    </div>
                </div> : null}
            </div>
        </div>
    </div>)
}

export default SchoolReportV3;
