import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import fs from 'fs';
const API_URL = process.env.REACT_APP_API_URL;


export const downloadXLSFile = async (url) => {
    
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {'Content-Type': 'blob'};
    const config = {method: 'GET', url: url, responseType: 'arraybuffer', headers};
    
    try {
        const response = await axios(config);
        
        const outputFilename = `${Date.now()}.xls`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

        // OR you can save/write file locally.
        fs.writeFileSync(outputFilename, response.data);

        return true;
    } catch (error) {
        throw Error(error);
    }
}




export default   {

  isAuthenticated() {
    const token = localStorage.getItem('userTicket')
      if (token) {
        return true
      } else {
        return false
      }
  },
  isProfileSelected() {
    const token = localStorage.getItem('profileSelected')
      if (token) {
        return true
      } else {
        return false
      }
  },
  login(res) {
    let data = {
      username: res.username,
      password : res.password
    }

    return axios
      .post(API_URL + "auth/login", data)
      .then(response => {
        // console.log(response.data); 
        if (response.data.access_token) {
          localStorage.setItem("userTicket", JSON.stringify(response.data.access_token));          
        }
        return response.data;
      });
  },
  createUser(data) {
    return axios
      .post(API_URL + "auth/register", data)
      .then(response => {
        // console.log(response.data);
        console.log('response.data', response.data);
        if (response.data.access_token) {
          localStorage.setItem("userTicket", JSON.stringify(response.data.access_token));          
        }
        return response.data;
      });
  },
  updateUser(userId, data) {
    return axios
      .patch(API_URL + `users/${userId}`, data)
      .then(response => {
        return response.data;
      });
  },
  getRoles() {
    return axios
      .get(API_URL + "rol?filter=canAccess||$eq||1")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getProfile(profileId) {
    return axios
      .get(API_URL + `profiles/${profileId}`, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem("userTicket"))
        }
      })
      .then(response => {
        console.log(response.data);
        localStorage.setItem("profileSelected", JSON.stringify(response.data));          
        return response.data;
      });
  },
  getState(id) {
    return axios
      .get(API_URL + `states/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getStates() {
    return axios
      .get(API_URL + "states")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getMunicipios(stateId) {
    return axios
      .get(API_URL + `municipality?stateId=${stateId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getMunicipiosByZoneId(zoneId) {
    return axios
      .get(API_URL + `municipality?zoneId=${zoneId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getActiveStates() {
    return axios
      .get(API_URL + "states?filter=isActive||$eq||1")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getRegion(id) {
    return axios
      .get(API_URL + `regions/${id}?join=state&join=municipality`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  createRegion(data) {
    return axios
      .post(API_URL + "regions/", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  updateRegion(regionId, data) {
    return axios
      .patch(API_URL + `regions/${regionId}`, data)
      .then(response => {
        return response.data;
      });
  },
  getRegions(idState) {
    return axios
      .get(API_URL + "regions/?idState=" + idState)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  deleteRegion(id) {
    return axios
      .delete(API_URL + `regions/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSector(id) {
    return axios
      .get(API_URL + `sectors/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSectors(idRegion) {
    return axios
      .get(API_URL + "sectors/?idRegion=" + idRegion)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSectorsByState(idState, page, perPage) {
    return axios
      .get(API_URL + `sectors/?idState=${idState}&page=${page}&per_page=${perPage}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getFichaBySector(idSector) {
    return axios
      .get(API_URL + `sectors/${idSector}/fichas/`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getFichaByState(idState) {
    return axios
      .get(API_URL + `states/${idState}/fichas/`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  updateSector(sectorId, data) {
    return axios
      .patch(API_URL + `sectors/${sectorId}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteSector(id) {
    return axios
      .delete(API_URL + `sectors/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getZone(id) {
    return axios
      .get(API_URL + `zones/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getZones(idSector) {
    return axios
      .get(API_URL + "zones/?idSector=" + idSector)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  updateZone(zoneId, data) {
    return axios
      .patch(API_URL + `zones/${zoneId}`, data)
      .then(response => {
        return response.data;
      });
  },
  deleteZone(id) {
    return axios
      .delete(API_URL + `zones/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getLevels() {
    return axios
      .get(API_URL + "levels")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSchoolTypes() {
    return axios
      .get(API_URL + "schools/types")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSchools(idZone, idLevel) {
    return axios
      .get(API_URL + "schools?idZone=" + idZone + "&id_Level=" + idLevel)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSchoolYears() {
    return axios
      .get(API_URL + "school_years")
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getStateSupervisors(idState) {
    return axios
      .get(API_URL + `supervisors/?filter=cctLabel||$eq||state&filter=cctId||$eq||${idState}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getRegionSupervisors(idRegion) {
    return axios
      .get(API_URL + `supervisors/?filter=cctLabel||$eq||region&filter=cctId||$eq||${idRegion}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSectorSupervisors(idSector) {
    return axios
      .get(API_URL + `supervisors/?filter=cctLabel||$eq||sector&filter=cctId||$eq||${idSector}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getZoneSupervisors(idZone) {
    return axios
      .get(API_URL + `supervisors/?filter=cctLabel||$eq||zone&filter=cctId||$eq||${idZone}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getZonePrincipals(idZone) {
    return axios
      .get(API_URL + "principals/?idZone=" + idZone)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  deleteSupervisor(id) {
    return axios
      .delete(API_URL + `supervisors/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSchoolsFullInfoByProfile(profileId, page, perPage) {
    return axios
      .get(API_URL + `schools/full-info-by-profile?profileId=${profileId}&page=${page}&per_page=${perPage}`)
      /*, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem("userTicket"))
        }
      })
      */
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getSchoolSummary(filters) {
    if(filters){
      return axios
      .get(API_URL + `schools/summary?${
        Object.keys(filters).map(key => key + '=' + filters[key]).join('&')
      }`)
      /*, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem("userTicket"))
        }
      })
      */
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
    }else{
      return axios
      .get(API_URL + `schools/summary?`)
      /*, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem("userTicket"))
        }
      })
      */
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
    }
  },
  getSchool(idSchool) {
    return axios
      .get(API_URL + `schools/${idSchool}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  deleteSchool(id) {
    return axios
      .delete(API_URL + `schools/${id}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getSchoolStatistics(idSchool, schoolYear) {
    return axios
      .get(API_URL + `schools/${idSchool}/statistics?schoolYear=${schoolYear}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getStateSummary(stateId, schoolYearId) {
    return axios
      .get(API_URL + `states/summary-all/?stateId=${stateId}&schoolYearId=${schoolYearId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getStateSummary2(stateId, schoolYearId) {
    return axios
      .get(API_URL + `states/students-teachers/?stateId=${stateId}&schoolYearId=${schoolYearId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getStateGoals(stateId, schoolYearId) {
    return axios
      .get(API_URL + `states/goals-idai-ice/?stateId=${stateId}&schoolYearId=${schoolYearId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getSchoolMathStatistics(idSchool, schoolYear) {
    return axios
      .get(API_URL + `schools/${idSchool}/math-statistics?schoolYear=${schoolYear}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getSchoolMathRisk(idSchool, schoolYear) {
    return axios
      .get(API_URL + `schools/${idSchool}/math-risk?schoolYearId=${schoolYear}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },


  getSchoolsFullInfo(idZone, idLevel) {
    return axios
      .get(API_URL + "schools/full-info?idZone=" + idZone + "&id_Level=" + idLevel)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getInstruments(teacherId) {
    return axios
      .get(API_URL + `instruments?teacherId=${teacherId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getGroupsByTeacherId(teacherId, schoolYearId) {
    return axios
      .get(API_URL + `docente/${teacherId}/groups?schoolYearId=${schoolYearId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getActiveFormsByInstrument(id) {
    return axios
      .get(API_URL + `instruments/${id}/?join=forms&filter=forms.status||$eq||active`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getInstrument(id) {
    return axios
      .get(API_URL + "instruments/"+id)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getScoresByGroupAndInstrument(idGroup, idInstrument) {
    return axios
      .get(API_URL + `students/groups/${idGroup}/instruments/${idInstrument}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getScoresBySchoolAndInstrument(idSchool, idInstrument) {
    return axios
      .get(API_URL + `students/schools/${idSchool}/instruments/${idInstrument}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getScoresBySchoolAndGradeAndInstrument(idSchool, grade, idInstrument) {
    return axios
      .get(API_URL + `students/schools/${idSchool}/grade/${grade}/instruments/${idInstrument}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  saveScore(idStudent, formId, newScore) {
    return axios
      .post(API_URL + `students/${idStudent}/save-score?formId=${formId}&newScore=${newScore}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  updateScore(idStudent, responseId, newScore) {
    return axios
      .put(API_URL + `students/${idStudent}/update-score?responseId=${responseId}&newScore=${newScore}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  toggleStudent(id, status) {
    return axios
      .put(API_URL + `students/${id}/change-status?status=${status}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getStudentsWithoutGroup(idSchool) {
    return axios
      .get(API_URL + `students/without-groups/${idSchool}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getStudentsByGroup(groupId) {
    return axios
      .get(API_URL + `students/groups/${groupId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  addStudentsToMyGroup(groupId, ids) {
    return axios
      .put(API_URL + `groups/${groupId}/add-students?ids=${ids}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  removeStudentsFromMyGroup(groupId, ids) {
    return axios
      .delete(API_URL + `groups/${groupId}/remove-students?ids=${ids}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  validateGroup(schoolId, label, grade) {
    return axios
      .get(API_URL + `groups/validate?schoolId=${schoolId}&label=${label}&grade=${grade}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  createStudent(data) {
    // console.log('data', data);
    return axios
      .post(API_URL + "students/", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  getStudent(studentId) {
    return axios
      .get(API_URL + `students/${studentId}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  editStudent(studentId, data) {
    // console.log('data', data);
    return axios
      .put(API_URL + `students/${studentId}`, data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  // imports
  createSchool(data) {
    return axios
      .post(API_URL + "schools/", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createSupervisorZone(data) {
    return axios
      .post(API_URL + "auth/create-supervisor?role=6", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createSupervisorSector(data) {
    return axios
      .post(API_URL + "auth/create-supervisor?role=5", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createSupervisorRegion(data) {
    return axios
      .post(API_URL + "auth/create-supervisor?role=4", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createSupervisorState(data) {
    return axios
      .post(API_URL + "auth/create-supervisor?role=3", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createPrincipal(data) {
    return axios
      .post(API_URL + "auth/create-principal", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createTeacher(data) {
    return axios
      .post(API_URL + "auth/create-teacher", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  createUserStudent(data) {
    // console.log('data', data);
    return axios
      .post(API_URL + "students/create-student", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  getImplementationInstrumentByUser(userId, schoolId) {
    return axios
      .get(API_URL + `instruments/implementation-index?userId=${userId}&schoolId=${schoolId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getImplementationIndexDetails(schoolId) {
    return axios
      .get(API_URL + `schools/${schoolId}/indice-detalle`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getInstrumentByShortName(shortName) {
    return axios
      .get(API_URL + `instruments/by-shortname?shortName=${shortName}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getInstrumentByFormId(userId, formId, extraData) {
    return axios
      .get(API_URL + `instruments/by-formid?userId=${userId}&formId=${formId}&extraData=${extraData}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getQuestionsByFormId(formId) {
    return axios
      .get(API_URL + `forms/${formId}?join=questions&filter=questions.active||$eq||1&join=questions.question_options`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getInstrumentByFormIdAndStudent(userId, formId, studentId) {
    return axios
      .get(API_URL + `instruments/by-formid-student?userId=${userId}&formId=${formId}&studentId=${studentId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  saveImplementationInstrumentForm(data) {
    return axios
      .post(API_URL + "instruments/save-implementation-index", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  saveInstrumentForm(data) {
    return axios
      .post(API_URL + "instruments/save-implementation", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  registerMoodle(data) {
    return axios
      .post(API_URL + "instruments/register-moodle", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  getTrayectoMoodleOld(userId) {
    return axios
      .get(API_URL + `instruments/trayecto-moodle?userId=${userId}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  getTrayectoMoodle(stateId, schoolYearId, phaseId) {
    return axios
      .get(API_URL + `moodle/progress?stateId=${stateId}&phaseId=${phaseId}&schoolYearId=${schoolYearId}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  getMoodleModules() {
    return axios
      .get(API_URL + `moodle/?filter=isVisible||$eq||1`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },
  registerProgressMoodle(data) {
    return axios
      .post(API_URL + "moodle/", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  getTeachersCohort(stateId, onlyCerts, uniques){
    return axios
      .get(API_URL + `docente/cohort?stateId=${stateId}&onlyCerts=${onlyCerts}&uniques=${uniques}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  getSchoolsCohort(stateId){
    return axios
      .get(API_URL + `schools/cohort?stateId=${stateId}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },


  saveCaptureIDAI(data) {
    // console.log('data', data);
    return axios
      .post(API_URL + "students/save-capture-idai", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  saveCaptureICE(data) {
    // console.log('data', data);
    return axios
      .post(API_URL + "students/save-capture-ice", data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  saveFda(formId, data) {
    // console.log('data', data);
    return axios
      .post(API_URL + `instruments/save-fda/${formId}`, data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  saveFormacionDocente(formId, data) {
    // console.log('data', data);
    return axios
      .post(API_URL + `instruments/save-formacion-docente/${formId}`, data)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },







  getTeachersBySchoolId(schoolId) {
    /*
    const conditions = [];
    if (config.filter.id){
      conditions.push({id: {$eq: config.filter.id}});
    }
    if (config.filter.status){
      conditions.push({status: {$eq: config.filter.status}});
    }
    if (config.filter.firstName){
      conditions.push({name: {$cont: config.filter.firstName}});
    }
    if (config.filter.capacity){
      conditions.push({capacity: {$cont: config.filter.capacity}});
    }
    if (config.filter["school"]){
      conditions.push({"school": {$eq: config.filter["school.id"]}});
    }

    const search = JSON.stringify({$and: conditions});

    const sort = '&sort=firstName,DESC';
    */

    return axios
      //.get(API_URL + `docente?page=${config.currentPage}&limit=${config.itemsPerPage}&join=school&filter=school.id||$eq||${schoolId}`)
      .get(API_URL + `docente?join=school&filter=school.id||$eq||${schoolId}&join=lastSchoolYear&filter=lastSchoolYear.id||$eq||2`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getGroupsBySchoolId(schoolId) {
    return axios
      .get(API_URL + `groups?join=school&filter=school.id||$eq||${schoolId}&join=schoolYear&filter=schoolYear.id||$eq||2`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  getGroup(groupId) {
    return axios
      .get(API_URL + `groups/${groupId}?join=school`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  deleteGroup(groupId) {
    return axios
      .delete(API_URL + `groups/${groupId}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  changeGroupTeacher(idGroup, idTeacher) {
    return axios
      .put(API_URL + `groups/${idGroup}/change-teacher?idTeacher=${idTeacher}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },

  getUserProfile(userId) {
    return axios
      .get(API_URL + `auth/profile2?userId=${userId}`)
      .then(response => {
        // console.log(response.data);
        return response.data;
      });
  },

  async downloadTeachersDetailsCohortReport(stateId) {
    return await downloadXLSFile(API_URL + `docente/cohort-details?stateId=${stateId}`);
  },

  async downloadTeachersDetailsCohortPorCicloReport(stateId) {
    return await downloadXLSFile(API_URL + `docente/cohort-details-2?stateId=${stateId}`);
  },

  async downloadSchoolsDetailsCohortReport(stateId) {
    return await downloadXLSFile(API_URL + `schools/cohort-details/download?stateId=${stateId}`);
  },

  async downloadSchoolsDetailsPorCicloCohortReport(stateId) {
    return await downloadXLSFile(API_URL + `schools/cohort-details-2/download?stateId=${stateId}`);
  },

  async downloadStudentsAndAnswers(stateId, schoolYearId) {
    return await downloadXLSFile(API_URL + `students/answers/?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },
  async downloadTeachersAndAnswers(stateId, schoolYearId) {
   return await downloadXLSFile(API_URL + `docente/answers/all?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },
  async downloadSchools(stateId, schoolYearId) {
   return await downloadXLSFile(API_URL + `schools/list/all?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },
  async downloadAnswersEmei(stateId, formId) {
    return await downloadXLSFile(API_URL + `principals/answers/${formId}/?stateId=${stateId}`);
  },
  async downloadAnswersSurvey(stateId, formId) {
    return await downloadXLSFile(API_URL + `docente/answers/survey/${formId}/?stateId=${stateId}`);
  },
  async downloadTrayectoResumen(profileId) {
    return await downloadXLSFile(API_URL + `profiles/${profileId}/trayecto-resumen`);
  },
  async downloadFiguras(stateId, schoolYearId) {
    return await downloadXLSFile(API_URL + `users/figuras/?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },
  async downloadFigurasWithTrainingAnio1(stateId, schoolYearId) {
    return await downloadXLSFile(API_URL + `users/formacion-anio1/?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },
  async downloadFigurasWithTrainingAnio2(stateId, schoolYearId) {
    return await downloadXLSFile(API_URL + `users/formacion-anio2/?stateId=${stateId}&schoolYearId=${schoolYearId}`);
  },





  async downloadMathReport() {
    return await downloadXLSFile(API_URL + `schools/math-statistics/download`);
  },


  

  

  


  /*
  saveScore(idStudent, formId, newScore) {
    return axios
      .post(API_URL + `students/${idStudent}/save-score?formId=${formId}&newScore=${newScore}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  updateScore(idStudent, responseId, newScore) {
    return axios
      .put(API_URL + `students/${idStudent}/update-score?responseId=${responseId}&newScore=${newScore}`)
      .then(response => {
        // console.log(response.data); 
        return response.data;
      });
  },
  */

  








  

  getGuestUser(){
      return {name: "Guest 123", userId: "guest123", email: "coolboy69@gg.com"}
  },

  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },

  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },


  loginWithGoogle(res) {
    var data = {
      name: res.profileObj.name,
      email : res.profileObj.email,
      image: res.profileObj.imageUrl
    }

    return axios
      .post(API_URL + "login", data)
      .then(response => {
        // console.log(response.data); 
        if (response.data.access_token) {
          localStorage.setItem("userTicket", JSON.stringify(response.data.access_token));          
        }
        return response.data;
      });
  },

  loginAsGuest(){
    var userData = {
      name: "Cool Guest", 
      id: "y2jsdqakq9rqyvtd4gf6g", 
      email: "coolboy69@gg.com"
    }

    const accessToken = jwt.sign(userData, "thisisaguesttokenwithsomeshittystring8", {expiresIn: '24h'});
    localStorage.setItem("userTicket", JSON.stringify(accessToken));

    return accessToken;
  },

  logout() {
    localStorage.removeItem("userTicket");
    localStorage.removeItem("profileSelected");
    window.location.href = '/';
  },

  clearProfile() {
    localStorage.removeItem("profileSelected");
    window.location.href = '/profiles';
  },

  getCurrentUser() {
     return jwtDecode(localStorage.getItem('userTicket'));
   },
};
