import React, { useEffect, useState, createRef } from 'react';
import Loading from '../../Loading';
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CardBasic from '../../Cards/Basic';
import reports from '../../../services/reports/ReportsService';

Chart.defaults.global.defaultFontFamily = 'Nunito';
Chart.defaults.global.defaultFontColor = '#858796';
Chart.plugins.register(ChartDataLabels);

// Change default options for ALL charts
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  color: '#FFF'
});

const StackBar2 = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const chartRef = createRef();

    useEffect(()=>{init()},[]);

    const init = async() => {
        if(isInit){
            return;
        }
        const myChartRef = chartRef.current.getContext("2d");

        setIsInit(true);
        setIsLoading(true);

        reports.getData(props.requestObj).then(result => {
            if (props.requestObj.breakdownDimension) {
                renderGraphBreakdown(myChartRef, result);
            }else{
                renderGraph(myChartRef, result);                
            }
            setIsLoading(false);
        }).catch(error => {
          console.log('err', error);
          setIsLoading(false);
        });

    }

    const renderGraphBreakdown = (ref, data) => {
        const uniques = [...new Set(data.map(item => item[props.requestObj.dimension.name]))];

        const total = data.reduce((accumulator, row) => {
          return accumulator + Number(row.recordCount);
        }, 0);

        new Chart(ref, {
            type: props.horizontal? 'horizontalBar': 'bar',
            options: props.porcent? optionsPorcent: options,
            plugins: [ChartDataLabels],
            data: {
                labels: uniques,
                datasets: data.map(row => {
                    return {
                        label: props.requestObj.breakdownDimension.label + ' ' + row[props.requestObj.breakdownDimension.name],
                        data:  [ toFormat(row.recordCount, total, props.porcent) ],
                        backgroundColor: props.requestObj.colorLabels[row[props.requestObj.breakdownDimension.name]],
                        type: props.type? props.type : 'bar',
                        barPercentage: props.porcent? 0.2 : 1,
                    }
                }).sort((a,b) => a.label.localeCompare(b.label))
            },
            
        });
    }

    const renderGraph = (ref, data) => {
        new Chart(ref, {
            type: 'bar',
            options: props.porcent? optionsPorcent: options,
            plugins: [ChartDataLabels],
            data: {
                labels: data.sort((a,b) => a.grado - b.grado).map(item => props.requestObj.dimensions[0].label + ' ' + item[props.requestObj.dimensions[0].name]),
                datasets: [...props.requestObj.metrics, ...props.requestObj.customMetrics].map(metric => ({
                    label: metric.label,
                    data: data.map(item => item[metric.name]),
                    backgroundColor: metric.color,
                    borderColor: metric.color,
                    borderWidth: 1,
                    barPercentage: 1,
                    type: props.type? props.type : 'bar',
                }))
            },
            
        });
    }


    return (<>

        <Loading loading={isLoading} background="#00000029" loaderColor="#3498dbAA" />

        <br/>

        <span style={{color: 'black', textAlign: 'center'}}>
            <p><b>{props.title}</b></p>
        </span>

        <div className="chart-area">
            <canvas id="myAreaChart" ref={chartRef}></canvas>
        </div>
        {/*
            <hr />
            Styling for the area chart can be found in the <code>/Components/Charts/Line/Index.js</code> file.
        */}

    </>)
}

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const toFormat = (value, total, porcent) => {
    if(porcent){
        return (Number(value/total)*100).toFixed(2);
    }else{
        return value;
    }
}

const randomColor = () => {
    return "hsla(" + ~~(360 * Math.random()) + ",90%,80%,1)";
}

const adjust = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const options = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.2,
        }],
    },
    plugins: {
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return value;
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

const optionsPorcent = {
    responsive: true,
    scales: {
        y: {
            beginAtZero: true,
        },
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
            barPercentage: 0.2,
            ticks: {
                callback: function(value, index, values) {
                    return String(value) + ' %';
                }
            }
        }],
    },
    plugins: {
        legend: {
            position: 'right',
        },
        datalabels: {
            formatter: function(value, index, values) {
                if(value >0 ){
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return ''.concat(String(value), '%');
                }else{
                    value = "";
                    return value;
                }
            },
            /*
            backgroundColor: function(context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 3,
            color: 'white',
            font: {
                weight: 'bold'
            },
            padding: 6,
            */
        }
    }
}

export default StackBar2;
